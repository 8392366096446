<template>
    <button class="botao-criar-setor" v-on:click="$emit('click')">
        <slot></slot>
    </button>    
</template>

<script>

export default {
    name: 'BotaoCriarTabela',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

button.botao-criar-setor {
  border: none;
  background: transparent;
  color: var(--laranja-omni) ; 
  font-weight: bolder;
  outline: none;
  height: 100%;
}

button.botao-criar-setor:disabled {
    color: var(--cinza-omni);
}

button.botao-criar-setor:hover {
    opacity: 0.5;
}

</style>
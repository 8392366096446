<template>
    <div class="formulario-banner">

        <div class="column">
            <div class="form-group">
                <label for="input">Nome (não visível no site)</label>
                <input-text id="input" v-model="form.titulo"></input-text>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        <label>Localização</label>
                        <input-select :optionsList="localizacaoList" :preSelected="form.localizacao" @selected="form.localizacao = $event"></input-select>
                    </div>
                    <div class="col-6">
                        <label for="ordenacao">Ordenação</label>
                        <input type="number" id="ordenacao" class="input-numerico-ordenacao py-1" v-model="form.ordem" />
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>Texto do Título</label>
                <textarea class="texto" v-model="form.textoTitulo"></textarea>
            </div>
            <div class="form-group">
                <label>Texto do Subtítulo</label>
                <textarea class="texto" v-model="form.textoSubtitulo"></textarea>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        <label>Cor do Titulo</label>
                        <input-select :optionsList="colorsList" :preSelected="form.corTitulo" @selected="form.corTitulo = $event"></input-select>
                    </div>
                    <div class="col-6">
                        <label>Cor do Subtitulo</label>
                        <input-select :optionsList="colorsList" :preSelected="form.corSubtitulo" @selected="form.corSubtitulo = $event"></input-select>
                    </div>
                </div>
            </div>
            <b-form-group label="Tipo de clique (redirecionar)">
                <b-form-radio-group id="radio-group-edit-clique" v-model="form.tipoClique">
                    <b-form-radio value="botao">Botão</b-form-radio>
                    <b-form-radio value="imagem">Imagem</b-form-radio>
                    <b-form-radio value="">Não redirecionar</b-form-radio>
                </b-form-radio-group>
            </b-form-group>
            <div v-if="form.tipoClique === 'botao'" class="form-group">
                <label>Texto do botão de ação</label>
                <input-text v-model="form.textoBotao"></input-text>
            </div>
            <div v-if="form.tipoClique === 'botao'" class="form-group">
                <label>Cor do botão de ação</label>
                <input-select :optionsList="colorsList" :preSelected="form.corBotao" @selected="form.corBotao = $event"></input-select>
            </div>
            <b-form-group v-if="form.tipoClique !== ''" label="Redirecionamento do clique">
                <b-form-radio-group id="radio-group-edit-url" v-model="form.tipoRedirecionamento">
                    <b-form-radio value="pagina">Página</b-form-radio>
                    <b-form-radio value="arquivo">Arquivo</b-form-radio>
                </b-form-radio-group>
                <div class="mt-1" style="height: 30px;">
                    <input-text v-if="form.tipoRedirecionamento === 'pagina'" v-model="form.urlPagina" placeholder="www.omni.com.br/pagina/redirecionar"></input-text>
                    <input-text v-if="form.tipoRedirecionamento === 'arquivo'" v-model="form.urlArquivo" placeholder="OBS: Copiar link do disco virtual"></input-text>
                </div>
            </b-form-group>
        </div>

        <div class="column mb-3">
            <div class="form-group">
                <label>Texto conteúdo</label>
                <vue-editor-custom-c-k ref="editorConteudo" :toolbarConfig="toolbarArray" />
            </div>
            <div class="form-group">
                <label>Posição</label>
                <input-select :optionsList="alinhamento" :preSelected="form.posicaoHorizontal" @selected="form.posicaoHorizontal = $event"></input-select>
            </div>
            <div>
                <span>Imagem</span>
                <input-image ref="inputImagem" @upload="uploadImagem"></input-image>
                <img v-if="form.urlImagem" :src="'/storage/banners/imagens/' + form.urlImagem" class="mt-2 demonstrativo-imagem" />
            </div>
        </div>

        <div class="pb-4 text-right">
            <button-fechar-branco class="btn-sm" @click="fecharForm" style="margin-right:15px;">Cancelar</button-fechar-branco>
            <button-verde class="btn-sm btn_salvar" @click="salvarBanner()">Salvar</button-verde>
        </div>

    </div>
</template>

<script>
import axios from 'axios'
import { LISTA_CORES } from '@/assets/js/dicionarioPaginas'
import VueEditorCustomCK from '@/components/blog-components/VueEditorCustomCK.vue'
import InputImage from '@/components/inputs/InputImagens.vue'
import InputText from '@/components/inputs/InputText.vue'
import InputSelect from '@/components/inputs/InputSelect.vue'
import ButtonVerde from '@/components/botoes/BotaoVerde.vue'
import ButtonFecharBranco from '@/components/botoes/ButtonFecharBranco.vue'

export default {
    name: 'ModalBlocoBanner',
    components: {
        InputImage,
        InputText,
        InputSelect,
        ButtonVerde,
        ButtonFecharBranco,
        VueEditorCustomCK
    },
    props: {
        toolbarArray: {
            type: Array,
            default: () => ['bold', 'italic', 'heading', '|', 'link', 'alignment', 'bulletedList', 'numberedList', 'fontSize', 'fontColor'],
        },
        banner: {
            type: Object,
            default: () => {}
        },
    },
    data: function () {
        return {
            form: {
                id: 0,
                titulo: '',
                localizacao: 'Web',
                ordem: 0,
                textoTitulo: '',
                corTitulo: '',
                textoSubtitulo: '',
                corSubtitulo: '',
                textoConteudo: '',
                corConteudo: '',
                textoBotao: '',
                corBotao: '',
                posicaoHorizontal: '',
                alinhamentoInterno: 'left',
                tipoClique: '',
                tipoRedirecionamento: '',
                urlPagina: '',
                urlImagem: '',
                urlArquivo: '',
            },

            arquivoImagem: null,

            bannerId: null,
            localizacaoList: { Web: 'Web', Mobile: 'Mobile' },
            colorsList: Object.assign(...LISTA_CORES.map(x => ({ [x.valor]: x.valor }))),
            alinhamento: { Esquerdo: 'left', Centro: 'center', Direito: 'right' },
        }
    },
    methods: {
        editBanner() {
            if (this.banner) {
                let ban = this.banner;
                this.form.id = ban.id;
                this.form.titulo = ban.titulo;
                this.form.localizacao = ban.localizacao;
                this.form.ordem = ban.ordem;
                this.form.textoTitulo = ban.textoTitulo;
                this.form.corTitulo = ban.corTitulo;
                this.form.textoSubtitulo = ban.textoSubtitulo;
                this.form.corSubtitulo = ban.corSubtitulo;
                this.form.textoConteudo = ban.textoConteudo;
                this.form.textoBotao = ban.textoBotao;
                this.form.corBotao = ban.corBotao;
                this.form.posicaoHorizontal = ban.posicaoHorizontal;
                this.form.alinhamentoInterno = ban.alinhamentoInterno;
                this.form.tipoClique = ban.tipoClique;
                this.form.tipoRedirecionamento = ban.tipoRedirecionamento;
                this.form.urlPagina = ban.urlPagina;
                this.form.urlImagem = ban.urlImagem;
                this.form.urlArquivo = ban.urlArquivo;
            }
        },
        salvarBanner: function () {
            if (this.arquivoImagem) {
                this.salvarImagem().then(res => {
                    this.form.urlImagem = res.data.dados.bannerImagem
                    this.salvarDados();
                })
            } else {
                this.salvarDados();
            }
            this.getContentEditor();
        },
        salvarImagem: function () {
            if (this.arquivoImagem) {
                const formData = new FormData();
                formData.append('imagem', this.arquivoImagem)

                let requisicao = null;
                if (this.form.id === 0) {
                    requisicao = axios.post(`/api/banners/upload`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                } else {
                    requisicao = axios.put(`/api/banners/upload/${this.form.id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                }

                return requisicao
            }
            else
            {
                alert('É necessario selecionar uma imagem.');
                return null;
            }
        },
        salvarDados: function () {
            this.form.ordem = parseInt(this.form.ordem) || 0;

            let requisicao = null;
            if (this.form.id === 0) {
                requisicao = axios.post('/api/banners/dados', this.form)
            } else {
                requisicao = axios.put('/api/banners/dados', this.form)
            }

            requisicao.then(() => {
                this.limparForm();
                this.fecharForm();
            })
            .catch((err) => {
                console.log(err.response);
            })
        },
        uploadImagem: function (imagem) {
            console.log(imagem);
            this.arquivoImagem = imagem;
        },
        limparForm: function () {
            this.form.id = 0,
            this.form.titulo = '',
            this.form.localizacao = 'Web',
            this.form.ordem = 0,
            this.form.textoTitulo = '',
            this.form.corTitulo = '',
            this.form.textoSubtitulo = '',
            this.form.corSubtitulo = '',
            this.form.textoConteudo = '',
            this.form.textoBotao = '',
            this.form.corBotao = '',
            this.form.posicaoHorizontal = '',
            this.form.alinhamentoInterno = 'left',
            this.form.tipoClique = '',
            this.form.tipoRedirecionamento = '',
            this.form.urlPagina = '',
            this.form.urlImagem = '',
            this.form.urlArquivo = ''

        },
        fecharForm: function () {
            this.$emit('fecharForm');
        },
        optionPreselected: function (value) {
            this.$emit('preSelected', value)
        },
        getContentEditor: function () {
            if (this.$refs.editorConteudo) {
                let conteudo = this.$refs.editorConteudo.getContent();
                this.form.textoConteudo = conteudo;
                return conteudo;
            }
            return this.form.textoConteudo;
        },
        setContentEditor: function (txt) {
            if (this.$refs.editorConteudo) {
                this.$refs.editorConteudo.setContent(txt);
                this.$refs.editorConteudo.prefill();
            }
            this.form.textoConteudo = txt;
        },
    },
    mounted() {
        this.editBanner();
        this.optionPreselected();
    },
    updated() {
        this.$refs.editorConteudo.setContent(this.form.textoConteudo ? this.form.textoConteudo : '');
    }
}
</script>

<style scoped>
    .formulario-banner {

    }

    .column {
        float: left;
        width: 50%;
        padding: 0 2%;
    }

    .row:after {
        content: "";
        display: table;
        clear: both;
    }

    .caixa_modal {
        padding: 7px 14px;
        border: none !important;
        border-radius: unset !important;
    }

    .caixa_body {
        text-align: left;
        margin: 0 5%;
    }

    #logo, #favicon, #fechar {
        margin: 5% 0;
    }

    #fechar {
        text-align: right;
    }

    .btn_salvar {
        margin-left: 10px;
    }

    button.close:focus {
        outline: none;
    }

    div.container-fluid, .col {
        padding: 0px;
    }

    .banner_edit select {
        padding: 5px 0;
    }

    tbody {
        font-size: 100%;
    }

    th {
        text-align: left !important;
    }

    textarea {
        width: 100%;
        resize: none;
        padding: 1%;
        height: 50px
    }

    label {
        margin: 0;
    }

    .btn_salvar {
        padding: .25rem 1rem;
    }

    #text_conteudo, #text_conteudo:focus {
        resize: none;
        width: 100%;
        height: 10rem;
        border-color: var(--cinza-omni);
        border-width: 1px;
        padding: 5px;
        outline: none;
    }

    .linha-banner:hover {
        background-color: var(--cinza-claro-omni);
    }

    .input-numerico-ordenacao {
        width: 100%;
        border: none !important;
        border-bottom: 1px solid black !important;
        outline: none;
    }

    .demonstrativo-imagem {
        max-width: 130px;
        max-height: 50px;
    }

    @media (min-width: 576px) {
        .modal_banner {
            max-width: 900px !important;
        }
    }
</style>
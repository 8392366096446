<template>
  <div class="container">
    <div class="row ">
      <div class="col-12 ">
        <tabela-paginas />
      </div>
    </div>
  </div>
</template>

<script>
import TabelaPaginas from '@/components/admin/tabelas/TabelaPaginas.vue';
export default {
  name: 'AgentesAdmPage',
  components: {
      TabelaPaginas,
  },
  data: function() {
    return {
      
    }
  },
  methods: {

  },
}

</script>

<style scoped>
    h1 {
        width: 100%;
        height: 700px;
        background-color: var(--cinza-claro-omni);
    }
</style>
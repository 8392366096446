<template>
  <div >
    <h1>Usuários</h1>
  </div>
</template>

<script>

export default {
  name: 'UsuariosPage'
}
</script>
<template>
    <div id="painel_geral">
        <div id="painel" class="container-fluid">

            <div class="row">
                <div id="painel_header" class="col">
                    <div class="col-1 float-left">
                        <img class="float-left" src="../../assets/images/logo_Omni_Banco_Financeira_RGB-02.png" />
                    </div>

                    <div class="col-2 float-right">
                        <div class="container">
                            <div class="row pt-3 float-right">

                                <div class="img-redonda-admin-logado">
                                
                                </div>
                                <div class="dropdown-usuario-admin-logado">
                                    
                                    <b-dropdown toggle-class="classe-toggle-button-admin-page">
                                        <template v-slot:button-content>
                                            {{ getUsuarioLogado() }}
                                        </template>
                                        <b-dropdown-item-button @click="clickDados">Dados</b-dropdown-item-button>
                                        <b-dropdown-item-button @click="clickSair">Sair</b-dropdown-item-button>
                                    </b-dropdown>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div id="painel_menu" class="col-1">
                    <div class="wrapper-menu-lateral-fixed">
                        <div class="nav flex-column nav-pills" aria-orientation="vertical">

                            <button href="#" type="button"
                                    class="painel_menu_button"
                                    @click="navegaPara('/')"
                                    v-bind:disabled="currentRoute === '/'">
                                <img src="../../assets/images/svg/icone-menu.svg" />
                                <br>
                                Menu
                            </button>

                            <button href="#" type="button"
                                    class="painel_menu_button"
                                    @click="navegaPara('/paginas')"
                                    v-bind:disabled="currentRoute === '/paginas' ">
                                <img src="../../assets/images/svg/icone-paginas.svg" />
                                <br>
                                Páginas
                            </button>

                            <button href="#" type="button"
                                    class="painel_menu_button"
                                    @click="navegaPara('/agentes')"
                                    v-bind:disabled="currentRoute === '/agentes'">
                                <img src="../../assets/images/svg/icone-agentes.svg" />
                                <br>
                                Agentes
                            </button>

                            <button href="#" type="button"
                                    class="painel_menu_button"
                                    @click="navegaPara('/blog')"
                                    v-bind:disabled="currentRoute === '/blog'">
                                <img src="../../assets/images/svg/icone-blog.svg" />
                                <br>
                                Blog
                            </button>

                            <button href="#" type="button"
                                    class="painel_menu_button"
                                    @click="navegaPara('/rodape')"
                                    v-bind:disabled="currentRoute === '/rodape'">
                                <img src="../../assets/images/svg/icone-rodape.svg" />
                                <br />
                                Rodape
                            </button>

                            <button href="#" type="button"
                                    class="painel_menu_button"
                                    @click="navegaPara('/ferramentas')"
                                    v-bind:disabled="currentRoute === '/ferramentas'">
                                <img src="../../assets/images/svg/icone-ferramentas.svg" />
                                <br />
                                Ferramentas
                            </button>
                        </div>
                    </div>
                </div>
                <div id="painel_components" class="col-11">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
    

    export default {
        name: "PainelAdmin",
        data() {
            return {
                currentRoute: ''
            }
        },
        computed: {
            ...mapGetters({
                admUser: 'authAdmin/admUser',
                admAuth: 'authAdmin/admAuth',
            })
        },
        methods: {
            ...mapActions({
                signOut: 'authAdmin/signOut',
            }),
            navegaPara(rota) {
                // this.currentRoute = comp;
                this.currentRoute = rota;
                this.$router.push(`/admin${rota}`).catch(()=>{});
            },
            getUsuarioLogado() {
                if(this.admUser && this.admUser.nome)
                    return this.admUser.nome
                else
                    return 'Usuário';
            },
            clickDados() {
                console.log('dados');
            },
            clickSair() {
                this.signOut();
                this.$router.push(`/admin/acesso`).catch(()=>{});
            },
        },
        components: {
        }
    }
</script>

<style>
    #painel_geral {
        width: 100%;
        font-family: MuseoSans;
        text-align: center;
    }

    #painel {
        background-color: var(--cinza-claro-omni);
    }

    #painel_header {
        background-color: #0f1450;
    }
    #painel_header img {
        width: 70px;
        padding: 5px 0;
    }

    #painel_menu {
        padding: 0px;
        background-color: var(--cinza-omni);
        font-size: 16px;
        max-width: 110px;
    }

    #painel_menu button {
        border: none;
        border-color:transparent;
    }

    .painel_menu_button{
        width: auto;
        height: 7rem;
        background-color: var(--cinza-omni);
        outline: none;
        color: var(--chumbo-omni);
            
    }
        .painel_menu_button:disabled {
            background-color: var(--cinza-claro-omni);
        }
        .painel_menu_button:hover {
            background-color: var(--cinza-claro-omni);
            opacity: 0.5;
        }

    div.wrapper-menu-lateral-fixed {
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 0;
        min-height: 94vh;
    }    

    .caixa-usuario-admin-logado {
        padding-top: 15px;
    }
    .img-redonda-admin-logado {
        background-color: var(--cinza-claro-omni);
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
    .dropdown-usuario-admin-logado {
        color: var(--cinza-claro-omni);
    }

    .classe-toggle-button-admin-page,
    .classe-toggle-button-admin-page:hover,
    .classe-toggle-button-admin-page:active,
    .classe-toggle-button-admin-page:focus {
        color: var(--cinza-claro-omni) ;
        background: none !important;
        border: none !important;
        outline: none !important;
        padding-left: 6px !important;
        padding-top: 3px !important;
        box-shadow: none !important;
    }


/* Estilização das tabelas */
.b-table .row-sem-borda td {
  border-top: none !important;
  padding-bottom: 10px !important;
}
.b-table td {
  margin-bottom: 5px;
}
.b-table thead {
  text-align: start;
}
.b-table td {
  text-align: start;
}
table.b-table  {
  margin-top: 5px;
  border-left: 12px solid white;
  border-right: 12px solid white;
  border-top: 2px solid white;
  border-bottom: 11px solid white;
}

#painel_components {
    padding: 0px;
    padding-right: 20px;
    padding-top: 1px;
}

    @media only screen and (min-width : 1200px) {
        #painel_components > .container { max-width: 1100px; } 
    }

    @media only screen and (min-width : 1400px) {
        #painel_components > .container { max-width: 1300px; } 
    }

    @media only screen and (min-width : 1600px) {
        #painel_components > .container { max-width: 1500px; } 
    }
</style>
<template>
    <button class="btn button_laranja" v-on:click="$emit('click')">
        <slot></slot>
    </button>
</template>

<script>
    export default {
        name: 'ButtonFecharBranco',
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    button.button_laranja {
        background-color: transparent;
        color: var(--laranja-claro-omni);
        border-radius: 0px;
        padding: 2% 0;
    }

    .button_laranja:focus {
        box-shadow: none;
    }
</style>
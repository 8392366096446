<template>
  <b-container fluid>
    <!-- User Interface controls -->
    <b-row>

      <b-col lg="2" class="mt-1 mb-0">
        <h5 class="mt-2 mb-0 text-left"><b>Agentes</b></h5>
      </b-col>

      <b-col lg="4" class="my-1">
      </b-col>

      <b-col lg="3" class="my-1">
        <input-buscar :value="filter" @change="changeFilter"/>
      </b-col>

      <b-col lg="3" class="my-1 pr-1">
        <botao-criar class="float-right" @click="exibeModal()" >+ Criar novo agente</botao-criar>
      </b-col>

    </b-row>
    
    <b-row>
      <!-- Main table element -->
      <b-table
        class="bg-white "
        :tbody-tr-class="classTd"
        hover
        show-empty
        small
        stacked="md"
        :items="listaAgentes"
        :fields="fields"
        :filter="filter"
        :filterIncludedFields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      >

          <template v-slot:empty>
              <label>Sem agentes cadastrados</label>
          </template>

          <template v-slot:emptyfiltered>
              <label>Sem resultados</label>
          </template>
        
        <template v-slot:cell(actions)="row">

          <botao-delete-tabela class="mx-1 float-right" @click="excluir(row.item.id)" />
          <botao-edit-tabela class="mx-1 float-right" @click="editar(row.item.id)" />

        </template>

        <template v-slot:row-details="row">
          <b-card>
            <ul>
              <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
            </ul>
          </b-card>
        </template>
      </b-table>
    </b-row>

    <modal-agentes ref="modal_agentes" v-model="modalAgentesShow"/>
  </b-container>
</template>

<script>
import BotaoDeleteTabela from '@/components/botoes/BotaoDeleteTabela.vue';
import BotaoEditTabela from '@/components/botoes/BotaoEditTabela.vue';
import BotaoCriar from '@/components/botoes/BotaoCriar.vue';
import InputBuscar from '@/components/inputs/InputBuscar.vue';
import ModalAgentes from '@/components/admin/modals/ModalAgentes.vue';
import { mapActions, mapGetters  } from 'vuex';
import axios from 'axios';
import { abreCaixaConfirmacao } from '@/assets/js/CaixaConfirmacao'


  export default {
    name: 'TabelaAgentes',
    components: {
        BotaoDeleteTabela,
        BotaoEditTabela,
        InputBuscar,
        ModalAgentes,
        BotaoCriar
    },
    data() {
      return {
        classTd: 'row-sem-borda',
        modalAgentesShow: false,
        fields: [
          { key: 'nome', label: 'Nome', sortable: true, sortDirection: 'asc' },
          { key: 'actions', label: '' }
        ],
        totalRows: 1,
        currentPage: 1,
        sortBy: 'nome',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: ['nome'],
      }
    },
    computed: {
      ...mapGetters({
          listaAgentes: 'agentes/agentes',
      }),
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    mounted() {
      // Set the initial number of items
      this.getAgentes().then(()=> this.totalRows = this.listaAgentes?this.listaAgentes.length: 1);
    },
    methods: {
      ...mapActions({
        getAgentes: 'agentes/getAgentes'
      }),
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      exibeModal: function() {
        this.$refs.modal_agentes.limpa();
        this.$refs.modal_agentes.show();
        this.modalAgentesShow = true;
      },
      editar: function(id) {
        this.$refs.modal_agentes.limpa();
        this.$refs.modal_agentes.idAgente = id;
        this.$refs.modal_agentes.show();
      },
      excluir: function(id) {
        abreCaixaConfirmacao(this.$bvModal, "Deseja realmente excluir esse agente?")
          .then( resposta => {
            if(resposta === true) {
              axios.delete(`/api/agentes/${id}`)
                .then( (response) => {
                  console.log(response);
                  this.getAgentes().then(()=> this.totalRows = this.listaAgentes?this.listaAgentes.length: 1);
                })
                .catch( err => {
                  console.log("erro exclusao agente", err.response.data)
                });
            }
          })
          .catch(()=> {
            console.log("erro na exclusão");
          });
      },
      changeFilter: function(valor) {
        this.filter = valor;
      },
    },
    
  }
</script>

<style>


</style>
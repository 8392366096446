<template>
    <b-button v-on:click="$emit('click')">
        <b-icon icon="trash-fill" ></b-icon>
        <slot></slot>
    </b-button>    
</template>

<script>
export default {
    name: 'DeleteButton',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>


</style>
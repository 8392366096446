<template>
    <b-modal 
        v-model="exibe" 
        body-class="body-modal-blocos-rodape" 
        header-class="header-modal-blocos-rodape" 
        footer-class="footer-modal-blocos-rodape" 
        size="md"
    >
        
        <template v-slot:default="{ cancel, ok }">
            <div class="container">

                <div class="row pb-2">
                    <h3> {{ idBloco > 0? 'Editar bloco': 'Novo bloco'}} </h3>
                </div>

                <div class="row py-1 input-linhas-rodape">
                    <label for="titulo-input-blocos-rodape">Rede Social</label>
                    <input type="text" v-model="titulo" name="titulo" id="titulo-input-blocos-rodape" class="form-input-blocos-rodape">
                </div>

                <div class="row py-1 input-linhas-rodape">
                    <label for="url-input-blocos-rodape">Url do perfil</label>
                    <input type="text" v-model="url" name="url" id="url-input-blocos-rodape" class="form-input-blocos-rodape">
                </div>

                <div class="row py-1 input-linhas-rodape">
                    <label>Icone</label>
                </div>

                <div v-if="url_icone.length > 0 || url_temp.length > 0" class="row py-1 input-linhas-rodape row-manter-imagem" >
                    <div class="col-4 pl-0" v-if="url_icone? url_icone.length > 0: false">
                        <div class="container-icone-rede-social">
                            <img :src="url_icone" />
                        </div>
                    </div>

                    <div class="col-4 pl-0" v-else-if="url_temp? url_temp.length > 0: false">
                        <div class="container-icone-rede-social">
                            <img :src="url_temp" />
                        </div>
                    </div>

                    <div class="col-6 pl-0" v-if="idBloco > 0">
                        <input  type="checkbox" id="manter-imagem-blocos-rodape" v-model="manterImagem" >
                        <label for="manter-imagem-blocos-rodape">Manter imagem atual</label>
                    </div>
                </div>

                <div class="row py-2">                               
                    <div class="col-12 px-0">
                        <input-imagens ref="inputImagensRef" @upload="uploadIcone" />
                    </div>
                </div>


                <div class="row py-2">
                    <div class="msr-botoes-float-right-container">
                        <botao-verde class="botoes-float-right" @click="salvar(ok)" > Salvar </botao-verde>
                        <botao-branco class="botoes-float-right" @click="cancel()" > Cancelar </botao-branco>
                    </div>
                </div>
                
            </div>
        </template>

        <template v-slot:modal-footer>
            <div class="mr-5">
                
            </div>
        </template>
    </b-modal>
</template>

<script>
import BotaoVerde from '@/components/botoes/BotaoVerde.vue';
import BotaoBranco from '@/components/botoes/BotaoBranco.vue';
import InputImagens from '@/components/inputs/InputImagens.vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';


export default {
    name: 'ModalBlocosRodape',
    components: {
        BotaoVerde,
        BotaoBranco,
        InputImagens
    },
    props: {
       
    },
    data: function() {
        return {
            exibe: false,
            idBloco: {
                type: Number,
                default: 0
            },
            titulo: '',
            texto: '',
            tipo: '',
            url: '',
            url_icone: '',

            imagem: null,
            manterImagem: false,
            url_temp: '',
        }
    },
    computed: {
        ...mapGetters({
            dadosSociaisRodape: 'dadosRodape/dadosSociaisRodape',
        }),
    },
    methods: {
        ...mapActions({
            getDadosSociaisRodape: 'dadosRodape/getDadosSociaisRodape'
        }),
        show: function(id = 0) {
            this.idBloco = id;

            if(this.dadosSociaisRodape && this.idBloco > 0) {
                let bloco = this.dadosSociaisRodape.filter(item => item.id == this.idBloco);
                if(bloco) {
                    if(bloco.length > 0) {
                        this.titulo = bloco[0].titulo;
                        this.texto = bloco[0].texto;
                        this.tipo = bloco[0].tipo;
                        this.url = bloco[0].url;
                        this.url_icone = bloco[0].url_icone;
                        console.log('this.url_icone');
                        console.log(this.url_icone);
                        this.manterImagem = this.url_icone? true: false;
                        console.log(this.manterImagem );
                    }
                }
            } 
            this.exibe = true;
        },
        hide: function() {
            this.limpa();
            this.exibe = false;
        },
        salvar: function(ok) {
            // Metodo Ok que fecha o modal
            ok();
            // Primeiro salva a imagem
            this.salvarImagem()
                .then( response => {
                    console.log(response);
                    if(response) {
                        this.url_icone = response.data.url;
                    }
                    // Depois salva os dados na tabela
                    this.salvarDados();
                })
                .catch(err => {
                    if(err.response.status == 401 || err.response.status == 403) {
                        this.$router.push('/admin').catch(()=>{});
                    }
                    console.log('erro no envio da imagem');
                    console.log(err.response);
                });

        },
        limpa: function() {
            this.idBloco = 0;
            this.titulo = '';
            this.texto = '';
            this.tipo = '';
            this.url = '';
            this.url_icone = '';
            this.url_temp = '';
            this.imagem = null;
            this.manterImagem = false;
            this.$refs.inputImagensRef? this.$refs.inputImagensRef.clear() : null;
        },
        uploadIcone: function(imagem, url_temp) {
            console.log('uploadIcone');
            this.imagem = imagem;
            this.url_temp = url_temp;
            this.url_icone = '';
        },
        salvarDados: function() {
            let registro = {
                "titulo": this.titulo,
                "texto": this.texto,
                "tipo": this.tipo,
                "url": this.url,
                "url_icone": this.url_icone,
            };
            let requisicao = null;
            if(this.idBloco > 0) {
                registro.Id = this.idBloco;
                requisicao = axios.put("/api/dados-sociais", registro);
            } else {
                requisicao = axios.post("/api/dados-sociais", registro);
            }
			requisicao
				.then( (response) => {
					console.log(response);

                    // atualiza a lista
                    this.getDadosSociaisRodape();
                    // Limpa form
                    this.limpa();
				})
				.catch( err => {
                    if(err.response.status == 401 || err.response.status == 403) {
                        this.$router.push('/admin').catch(()=>{});
                    }
					console.log("erro cadastro do bloco rodapé", err.response)
				});
        },
        salvarImagem: async function() {
            const formData = new FormData();
            if (this.imagem && !this.manterImagem) {
                formData.append('arquivo', this.imagem, this.imagem.name)
                return axios.post('/api/imagens/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            } else {
                return;
            }
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.header-modal-blocos-rodape {
    border: white !important;
    padding-bottom: 0 !important;
}

.body-modal-blocos-rodape {
    padding-right: 50px !important;
    padding-left: 50px !important;
}

.footer-modal-blocos-rodape {
    border: white !important;
}

.form-input-blocos-rodape {
    width: 100%;
    border: none !important;
    border-bottom: 1px solid black !important;
    outline: none;
}

.botoes-float-right {
    float: right;
    margin-left: 20px;
}

.msr-botoes-float-right-container {
    width: 100%;
    margin-top: 10px;
}

.row-manter-imagem input {
    margin: 5px 5px 0px 0px;
}

.input-linhas-rodape label {
    margin-bottom: 0;
}

.container-icone-rede-social {
    height: 40px;
    width: auto;
}

    .container-icone-rede-social img{
        max-width: 100%;
        max-height: 100%;
        display: block;
        border: 1px solid rgba(0,0,0,0.25);
        padding: 3px;
    }

</style>
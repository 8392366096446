<template>
  <div class="container">
    <div class="row ">
      <div class="col-12 ">
        <tabela-agentes />
      </div>
    </div>
  </div>
</template>

<script>
import TabelaAgentes from '@/components/admin/tabelas/TabelaAgentes.vue';

export default {
  name: 'AgentesAdmPage',
  components: {
      TabelaAgentes,
  },
  data: function() {
    return {
      
    }
  },
  methods: {

  },
}

</script>

<style scoped>
    h1 {
        width: 100%;
        height: 700px;
        background-color: var(--cinza-claro-omni);
    }
</style>
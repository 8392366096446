<template>
    <b-modal 
        v-model="exibe" 
        body-class="body-modal-lista-icones" 
        header-class="header-modal-lista-icones" 
        footer-class="footer-modal-lista-icones"
        size="lg" 
        no-enforce-focus
    >
        
        <template v-slot:default="{ cancel, ok }">
            <div class="container">


                <div class="row pb-2">
                    <h3> {{ idComponente > 0? 'Editar bloco': 'Novo bloco'}} - Página {{ titulo_pagina }}</h3>
                </div>

                <div class="row pb-2">
                    <div class="col-6">
                        <div class="container">
                        
                            <div class="row py-2">
                                <div class="col-9 pl-0">
                                    <label for="select-input-lista-icones">Modelo de Bloco</label>
                                    <select disabled v-model="tipo" name="select" id="select-input-lista-icones" class="select-input-modal-lista-icones">
                                        <option disabled :value="''">Modelo</option>
                                        <option disabled v-for="(tipo, i) in tiposComponentes" v-bind:key="i" :value="tipo.chave">{{ tipo.valor }}</option> 
                                    </select>
                                </div>

                                <div class="col-3 pr-0">
                                    <label for="ordem-input-lista-icones">Posição</label>
                                    <input type="number" v-model="posicao" name="ordem1" id="ordem-input-lista-icones" class="form-input-lista-icones" >
                                </div>
                                
                            </div>

                            <div class="row py-2">
                                <label for="titulo-input-lista-icones">Título</label>
                                <input type="text" v-model="titulo" name="titulo" id="titulo-input-lista-icones" class="form-input-lista-icones" >
                            </div>

                            <div class="row py-2">
                                <label for="sub-titulo-input-lista-icones-icone">Subtítulo</label>
                                <input type="text" v-model="sub_titulo" name="input-sub-titulo" id="sub-titulo-input-lista-icones-icone" class="form-input-lista-icones" >
                            </div>

                            <div class="row py-2">
                                <div class="col-6 pl-0">
                                    <label for="select-cor-titulo-input-lista-icones">Cor do Título</label>
                                    <select v-model="cor_titulo" name="select-cor-titulo" id="select-cor-titulo-input-lista-icones" class="select-input-modal-lista-icones">
                                        <option disabled :value="''">Escolha...</option>
                                        <option v-for="(cor, i) in listaCores" v-bind:key="i" :value="cor.chave">{{ cor.valor }}</option> 
                                    </select>
                                </div>
                                <div class="col-6 px-0">
                                    <label for="select-cor-sub-titulo-input-lista-icones">Cor do Subtítulo </label>
                                    <select v-model="cor_sub_titulo" name="select-cor-sub-titulo" id="select-cor-sub-titulo-input-lista-icones" class="select-input-modal-lista-icones">
                                        <option disabled :value="''">Escolha...</option>
                                        <option v-for="(cor, i) in listaCores" v-bind:key="i" :value="cor.chave">{{ cor.valor }}</option> 
                                    </select>
                                </div>
                            </div>

                            <div class="row py-2">
                                <label for="select-fundo-lista-icones">Cor do Fundo </label>
                                <select v-model="tema" name="select-cor-fundo-lista" id="select-fundo-lista-icones" class="select-input-modal-lista-icones">
                                    <option disabled :value="''">Escolha...</option>
                                    <option v-for="(cor, i) in listaCores" v-bind:key="i" :value="cor.chave">{{ cor.valor }}</option> 
                                </select>
                            </div>

                            <div class="row pt-1">
                                <label for="select-fundo-input-lista-icones">Conteúdo das colunas</label>
                            </div>
                            <div class="row" v-for="(item, i) in itens" v-bind:key="i">

                                <div class="col-9">
                                    <p>{{item.titulo? item.titulo: item.sub_titulo? item.sub_titulo: 'Coluna '+i}}</p>
                                </div>
                                <div class="col-3">
                                    <botao-delete-tabela class="mx-1" @click="excluirItem(item, i)" />
                                    <botao-edit-tabela class="mx-1" @click="editarItem(item, i)" />
                                </div>

                            </div>
                            <div class="row pt-1">
                                <botao-criar @click="criarItem" >+ Criar nova coluna</botao-criar>
                            </div>

                        </div>
                    </div>


                    <div class="col-6 px-0">
                        <div class="container container-form-item-icone px-4 py-2" v-show="itemSelecionado.posicao">

                            <div class="row">
                                
                                <div class="col-3 px-0">
                                    <label for="ordem-input-lista-icones-icone">Posição</label>
                                    <input type="number" v-model.number="itemSelecionado.posicao" name="ordem2" id="ordem-input-lista-icones-icone" class="form-input-lista-icones" >
                                </div>

                                <div class="col-3 input-linhas-rodape">
                                </div>

                                <div class="col-3 pr-0">
                                    <p class="pr-0">{{ tipoComponente!=='COLUNAS_BOTAO_IMG' && tipoComponente!=='LINHA_PRODUTOS'? 'Icone': 'Imagem' }}</p>
                                </div>

                                <div class="col-3 px-0" v-if="itemSelecionado.url_icone? itemSelecionado.url_icone.length > 0: false">
                                    <div class="container-img-lista-icones">
                                        <img :src="itemSelecionado.url_icone" />
                                    </div>
                                </div>

                                <div class="col-3 px-0" v-else-if="url_temp? url_temp.length > 0: false">
                                    <div class="container-img-lista-icones">
                                        <img :src="url_temp" />
                                    </div>
                                </div>

                                <div class="col-3 px-0" v-else>
                                    <div class="container-img-lista-icones">
                                    </div>
                                </div>
                                
                                
                            </div>

                            <div class="row py-2 input-linhas-rodape">
                                <input-imagens ref="inputImagensRef" @upload="uploadImagem" />
                            </div>

                            <div class="row py-2">
                                <div class="col-9 pl-0">
                                    <label for="titulo-input-item-lista"> {{ tipoComponente!=='COLUNAS_BOTAO_IMG'? 'Título': 'Texto Botão' }} </label>
                                    <input type="text" v-model="itemSelecionado.titulo" name="titulo-icone" id="titulo-input-item-lista" class="form-input-lista-icones" >
                                </div>

                                <div class="col-3 px-0">
                                    <label for="select-cor-titulo-item-icones"> {{ tipoComponente!=='COLUNAS_BOTAO_IMG'? 'Cor Título': 'Cor Botão' }} </label>
                                    <select v-model.trim="itemSelecionado.cor_titulo" name="select-cor-titulo-item" id="select-cor-titulo-item-icones" class="select-input-modal-lista-icones">
                                        <option disabled :value="undefined">Escolha...</option>
                                        <option v-for="(cor, i) in listaCores" v-bind:key="i" :value="cor.chave">{{ cor.valor }}</option> 
                                    </select>
                                </div>
                            </div>

                            <div class="row py-2" v-if="tipoComponente!=='COLUNAS_BOTAO_IMG' && tipoComponente!=='LINHA_PRODUTOS'">
                                <div class="col-9 pl-0">
                                    <label for="sub-titulo-input-item-lista">Sub Título</label>
                                    <input type="text" v-model="itemSelecionado.sub_titulo" name="sub-titulo-icone" id="sub-titulo-input-item-lista" class="form-input-lista-icones" >
                                </div>

                                <div class="col-3 px-0">
                                    <label for="select-cor-sub-titulo-item-icones">Cor Sub </label>
                                    <select v-model.trim="itemSelecionado.cor_sub_titulo" name="select-cor-titulo-item" id="select-cor-sub-titulo-item-icones" class="select-input-modal-lista-icones">
                                        <option disabled :value="undefined">Escolha...</option>
                                        <option v-for="(cor, i) in listaCores" v-bind:key="i" :value="cor.chave">{{ cor.valor }}</option> 
                                    </select>
                                </div>
                            </div>

                            <div class="row py-2" >
                                <label for="url-redir-input-item-lista">Url</label>
                                <input type="text" v-model="itemSelecionado.url_redir" name="url-redir" id="url-redir-input-item-lista" class="form-input-lista-icones" >
                            </div>

                            <div class="row" v-if="tipoComponente!=='COLUNAS_BOTAO_IMG' && tipoComponente!=='LINHA_PRODUTOS'">
                                <div class="col-12 px-0">
                                    <label>Texto</label>
                                    <vue-editor-custom-c-k ref="editorConteudo" :toolbarConfig="toolbarArray"/>
                                </div>
                            </div>

                            <div class="row py-2">
                                
                            </div>

                            <div class="row py-2">
                                <div class="botoes-float-right-container">
                                    <botao-verde class="botoes-float-right" @click="salvarItem()" > Salvar Coluna</botao-verde>
                                    <botao-branco class="botoes-float-right" @click="cancelarItem()" > Cancelar </botao-branco>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>


                <div class="row py-2">
                    <div class="botoes-float-right-container">
                        <botao-verde class="botoes-float-right" @click="salvar(ok)" > Salvar </botao-verde>
                        <botao-branco class="botoes-float-right" @click="cancel()" > Cancelar </botao-branco>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:modal-footer>
            <div class="mr-5">
                
            </div>
        </template>
    </b-modal>
</template>

<script>
import BotaoVerde from '@/components/botoes/BotaoVerde.vue';
import BotaoBranco from '@/components/botoes/BotaoBranco.vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import { TIPOS_COMPONENTES, LISTA_CORES } from '@/assets/js/dicionarioPaginas';
import InputImagens from '@/components/inputs/InputImagens.vue';
import VueEditorCustomCK from '@/components/blog-components/VueEditorCustomCK.vue'
import BotaoDeleteTabela from '@/components/botoes/BotaoDeleteTabela.vue';
import BotaoEditTabela from '@/components/botoes/BotaoEditTabela.vue';
import BotaoCriar from '@/components/botoes/BotaoCriar.vue';


export default {
    name: 'ModalBlocoListaIcones',
    components: {
        BotaoVerde,
        BotaoBranco,
        InputImagens,
        VueEditorCustomCK,
        BotaoDeleteTabela,
        BotaoEditTabela,
        BotaoCriar,
    },
    props: {
       toolbarArray: {
            type: Array,
            default: () => ['bold', 'heading', '|', 'link', 'alignment', 'bulletedList', 'numberedList', 'fontSize', 'fontColor' ],
        }
    },
    data: function() {
        return {
            exibe: false,
            tiposComponentes: TIPOS_COMPONENTES,
            listaCores: LISTA_CORES,
            idComponente: {
                type: Number,
                default: 0
            },
            id_pagina: 0,
            titulo_pagina: 0,
            tipo: '',
            titulo: '',
            cor_botao_1: '',
            cor_botao_2: '',
            texto: '',
            url_img: '',
            imagem: null,

            posicao: 0,
            txt_botao_1: '',
            url_botao_1: '',
            txt_botao_2: '',
            url_botao_2: '',
            tema: '',
            cor_titulo: '',
            sub_titulo: '',
            cor_sub_titulo: '',

            itens: [],
            itemSelecionado: {},
            indiceSelecionado: -1,
            url_temp: '',
        }
    },
    computed: {
        ...mapGetters({
            paginas: 'paginas/paginas',
        }),
        tipoComponente() {
            if(!this.tipo) {
                return '';
            }

            return this.tipo;
        },
    },
    methods: {
        ...mapActions({
            getPaginas: 'paginas/getPaginas'
        }),
        show: function(pIdPagina, pIdComponente = 0) {
            this.idComponente = pIdComponente;
            this.id_pagina = pIdPagina;

            if(this.id_pagina) {
                let pagina = this.paginas.find(item => item.id == this.id_pagina);
                this.titulo_pagina = pagina.titulo;
                if(this.idComponente) {
                    let compSelecionado = pagina.componentes.find(comp => comp.id == this.idComponente);
                    this.tipo = compSelecionado.tipo;
                    this.titulo = compSelecionado.titulo;
                    this.url_img = compSelecionado.url_img;
                    this.posicao =compSelecionado.posicao;
                    this.txt_botao_1 = compSelecionado.txt_botao_1;
                    this.url_botao_1 = compSelecionado.url_botao_1;
                    this.txt_botao_2 = compSelecionado.txt_botao_2;
                    this.url_botao_2 = compSelecionado.url_botao_2;
                    this.texto = compSelecionado.texto;
                    this.cor_botao_1 = compSelecionado.cor_botao_1;
                    this.cor_botao_2 = compSelecionado.cor_botao_2;
                    this.tema = compSelecionado.tema?? '';
                    this.cor_titulo = compSelecionado.cor_titulo?? '';
                    this.sub_titulo = compSelecionado.sub_titulo;
                    this.cor_sub_titulo = compSelecionado.cor_sub_titulo?? '';
                    this.itens = compSelecionado.itens.slice();
                } else {
                    let ultimaPosicao = Math.max(...pagina.componentes.map(comp => comp.posicao));
                    this.posicao = ultimaPosicao + 1;
                }

            }
            this.exibe = true;
        },
        hide: function() {
            this.limpa();
            this.exibe = false;
        },
        salvarDados: function() {
            let registro = {
                "id_pagina": parseInt(this.id_pagina),
                "tipo": this.tipo,
                "posicao" : parseInt(this.posicao),
                "txt_botao_1" : this.txt_botao_1,
                "url_botao_1" : this.url_botao_1,
                "cor_botao_1" : this.cor_botao_1,
                "txt_botao_2" : this.txt_botao_2,
                "url_botao_2" : this.url_botao_2,
                "cor_botao_2" : this.cor_botao_2,
                "url_img": this.url_img,
                "tema": this.tema,
                "texto": this.texto,
                "titulo": this.titulo,
                "itens": this.itens, 
                "cor_titulo" : this.cor_titulo,
                "sub_titulo" : this.sub_titulo,
                "cor_sub_titulo" : this.cor_sub_titulo,
            };
            console.log('salva dados', registro);
            let requisicao = null;
            if(this.idComponente > 0) {
                registro.Id = this.idComponente;
                requisicao = axios.put("/api/componentes", registro);
            } else {
                requisicao = axios.post("/api/componentes", registro);
            }
			requisicao
				.then( () => {
                    // atualiza a lista
                    this.getPaginas();
                    // Limpa form
                    this.limpa();
				})
				.catch( err => {
                    if(err.response.status == 401 || err.response.status == 403) {
                        this.$router.push('/admin').catch(()=>{});
                    }
					console.log("erro no cadastro do bloco", err.response.data)
				});
        },
        limpa: function() {
            this.idComponente = 0;
            this.id_pagina = 0;
            this.tipo = '';
            this.titulo = '';
            this.cor_botao_1 = '';
            this.cor_botao_2 = '';
            this.texto = '';
            this.url_img = '';
            this.url_temp = '';
            this.posicao = 0;
            this.txt_botao_1 = '';
            this.url_botao_1 = '';
            this.txt_botao_2 = '';
            this.url_botao_2 = '';
            this.imagem = null;
            this.tema = '';
            this.itens = [];
            this.itemSelecionado = {};
            this.indiceSelecionado = -1;
            this.cor_titulo = '';
            this.sub_titulo = '';
            this.cor_sub_titulo = '';
        },
        uploadImagem: function(imagem, url_temp) {
            this.imagem = imagem;
            this.url_temp = url_temp;
            this.itemSelecionado.url_icone = '';
        },
        salvarImagem: async function(imagem) {
            const formData = new FormData();
            if (imagem) {
                formData.append('arquivo', imagem, imagem.name)
                return axios.post('/api/imagens/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            } else {
                return;
            }
        },
        salvar: function(ok) {
            this.itemSelecionado.texto = this.getContentEditor();
            // Metodo Ok que fecha o modal
            ok();
            // Primeiro salva a imagem
            this.salvarDados();
        },
        criaItemVazio: function(pos=0) {
            return {
                id_componente: this.idComponente,
                url_icone: '',
                titulo: '',
                texto: '',
                posicao: pos,
                cor_titulo: '',
                sub_titulo: '',
                cor_sub_titulo: '',
                url_redir: '',
            };
        },
        criarItem: function() {
            let ultimaPosicao = 0;
            if(this.itens.length > 0)
                ultimaPosicao = Math.max(...this.itens.map(item => item.posicao));
            this.itemSelecionado = this.criaItemVazio(ultimaPosicao + 1);
            this.setContentEditor('');
            this.indiceSelecionado = -1;
        },
        editarItem: function(pItem, pIndex) {
            if(pItem) {
                this.itemSelecionado = {...pItem};
                this.indiceSelecionado = pIndex;
                this.setContentEditor(this.itemSelecionado.texto)
            }
        },
        excluirItem: function(pItem, pIndex) {
            this.itens.splice(pIndex, 1);
            // Se o item sendo excluido estava sendo editado ao mesmo tempo, limpa o form lateral
            if(this.indiceSelecionado === pIndex) {
                this.limpaForm();
            }
        },
        salvarItem: function() {
            // salva item selecionado no banco
            this.salvarImagem(this.imagem)
                .then((response)=> {
                    if(response) {
                        this.itemSelecionado.url_icone = response.data.url;
                    }
                    this.getContentEditor();
                    if(this.indiceSelecionado >= 0) {
                        this.itens[this.indiceSelecionado] = {...this.itemSelecionado};
                    } else {
                        this.itens.push({...this.itemSelecionado});
                    }
                    this.indiceSelecionado = -1;
                    this.itens.sort((it1, it2) => it1.posicao - it2.posicao);
                    this.limpaForm();
                })
                .catch(err => console.log(JSON.stringify(err)));
            
        },
        cancelarItem: function() {
            this.limpaForm();
        },
        limpaForm: function() {
            this.itemSelecionado = {};
            this.setContentEditor('');
            this.indiceSelecionado = -1;
            this.limpaInputImg();
            this.url_img = '';
            this.url_temp = '';
            this.imagem = null;
        },
        getContentEditor: function() {
            if(this.$refs.editorConteudo) {
                let conteudo = this.$refs.editorConteudo.getContent();
                if(this.itemSelecionado) {
                    this.itemSelecionado.texto = conteudo;
                }
                return conteudo;
            }
            if(this.itemSelecionado) {
                return this.itemSelecionado.texto;
            }
            return '';
        },
        setContentEditor: function(txt) {
            if(this.$refs.editorConteudo) {
                this.$refs.editorConteudo.setContent(txt);
                this.$refs.editorConteudo.prefill();
            }
            if(this.itemSelecionado) {
                this.itemSelecionado.texto = txt;
            }
        },
        limpaInputImg: function() {
            if(this.$refs.inputImagensRef) {
                this.$refs.inputImagensRef.clear();
            }
        },
    },
    updated() {
        if(this.$refs.editorConteudo) {
            this.$refs.editorConteudo.setContent(this.texto? this.texto: '');
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.header-modal-lista-icones {
    border: white !important;
    padding-bottom: 0 !important;
}

.body-modal-lista-icones {
    padding-right: 50px !important;
    padding-left: 50px !important;
}

.footer-modal-lista-icones {
    border: white !important;
}

.form-input-lista-icones {
    width: 100%;
    border: none !important;
    border-bottom: 1px solid black !important;
    outline: none;
}
.select-input-modal-lista-icones {
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 1px solid black !important;
}

.botoes-float-right {
    float: right;
    margin-left: 20px;
}

.botoes-float-right-container {
    width: 100%;
    margin-top: 30px;

}

.container-img-lista-icones {
    height: 50px;
    width: auto;
    border: 1px solid var(--cinza-omni);
    padding: 4px;
}

    .container-img-lista-icones img{
        max-width: 100%;
        max-height: 100%;
        display: block;
    }

.container-form-item-icone{
    border: 1px solid var(--cinza-omni);
}

</style>
<template>
    <b-modal 
        v-model="exibe" 
        body-class="body-modal-componentes" 
        header-class="header-modal-componentes" 
        footer-class="footer-modal-componentes"
        size="md" 
    >
        
        <template v-slot:default="{ cancel, ok }">
            <div class="container">


                <div class="row pb-2">
                    <h3> {{ idComponente > 0? 'Editar bloco': 'Novo bloco'}} - Página {{ titulo_pagina }}</h3>
                </div>

                <div class="row py-2">
                    <label for="select-input-componentes-agentes">Modelo de Bloco</label>
                    <select disabled v-model="tipo" name="select" id="select-input-componentes-agentes" class="select-input-modal-componentes-agentes">
                        <option disabled :value="''">Modelo</option>
                        <option disabled v-for="(tipo, i) in tiposComponentes" v-bind:key="i" :value="tipo.chave">{{ tipo.valor }}</option> 
                    </select>
                </div>

                <div class="row py-2">
                    <label for="ordem-input-componentes-agentes">Posição</label>
                    <input type="number" v-model="posicao" name="ordem1" id="ordem-input-componentes-agentes" class="form-input-componentes-agentes" >
                </div>

                <div class="row py-2">
                    <label for="titulo-input-componentes-agentes">Título</label>
                    <input type="text" v-model="titulo" name="titulo" id="titulo-input-componentes-agentes" class="form-input-componentes-agentes" >
                </div>
                        
                <div class="row py-2">
                    <label for="titulo_interno-input-componentes-agentes">Título formulário</label>
                    <input type="text" v-model="titulo_interno" name="titulo_interno" id="titulo_interno-input-componentes-agentes" class="form-input-componentes-agentes" >
                </div>

                <div class="row py-2">
                    <label >Produto relacionado</label>
                    <select v-model.number="produto" class="select-input-modal-componentes-agentes">
                        <option  :value="0">Escolha um produto Omni</option>
                        <option
                            v-for="(servico, i) in servicosDisponiveis"
                            v-bind:key="i"
                            :value="servico.id"
                        >
                            {{ servico.nome }}
                        </option>
                    </select>
                </div>


                <div class="row py-2">
                    <div class="botoes-float-right-container">
                        <botao-verde class="botoes-float-right" @click="salvar(ok)" > Salvar </botao-verde>
                        <botao-branco class="botoes-float-right" @click="cancel()" > Cancelar </botao-branco>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:modal-footer>
            <div class="mr-5">
                
            </div>
        </template>
    </b-modal>
</template>

<script>
import BotaoVerde from '@/components/botoes/BotaoVerde.vue';
import BotaoBranco from '@/components/botoes/BotaoBranco.vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import { TIPOS_COMPONENTES, LISTA_CORES } from '@/assets/js/dicionarioPaginas';

export default {
    name: 'ModalBlocoPadrao',
    components: {
        BotaoVerde,
        BotaoBranco,
    },
    props: {
       toolbarArray: {
            type: Array,
            default: () => [ 'bold', 'italic', 'heading', '|', 'link', 'alignment', 'bulletedList', 'numberedList' ],
        }
    },
    data: function() {
        return {
            exibe: false,
            tiposComponentes: TIPOS_COMPONENTES,
            listaCores: LISTA_CORES,
            idComponente: {
                type: Number,
                default: 0
            },
            id_pagina: 0,
            titulo_pagina: 0,
            tipo: '',
            titulo: '',
            cor_botao_1: '',
            cor_botao_2: '',
            url_img: '',

            posicao: 0,
            txt_botao_1: '',
            url_botao_1: '',
            txt_botao_2: '',
            url_botao_2: '',
            tema: '',
            titulo_interno: '',
            produto: 0,
        }
    },
    computed: {
        ...mapGetters({
            servicosDisponiveis: 'servicos/servicos',
            paginas: 'paginas/paginas',
        })
    },
    methods: {
        ...mapActions({
            getPaginas: 'paginas/getPaginas'
        }),
        show: function(pIdPagina, pIdComponente = 0) {
            this.idComponente = pIdComponente;
            this.id_pagina = pIdPagina;

            if(this.id_pagina) {
                let pagina = this.paginas.find(item => item.id == this.id_pagina);
                this.titulo_pagina = pagina.titulo;
                if(this.idComponente) {
                    let compSelecionado = pagina.componentes.find(comp => comp.id == this.idComponente);
                    this.tipo = compSelecionado.tipo;
                    this.titulo = compSelecionado.titulo;
                    this.url_img = compSelecionado.url_img;
                    this.posicao =compSelecionado.posicao;
                    this.txt_botao_1 = compSelecionado.txt_botao_1;
                    this.url_botao_1 = compSelecionado.url_botao_1;
                    this.txt_botao_2 = compSelecionado.txt_botao_2;
                    this.url_botao_2 = compSelecionado.url_botao_2;
                    this.cor_botao_1 = compSelecionado.cor_botao_1;
                    this.cor_botao_2 = compSelecionado.cor_botao_2;
                    this.tema = compSelecionado.tema;
                    // Campos especificos para o bloco Agente
                    this.titulo_interno = compSelecionado.texto;
                    this.produto = compSelecionado.int_geral;
                } else {
                    let ultimaPosicao = Math.max(...pagina.componentes.map(comp => comp.posicao));
                    this.posicao = ultimaPosicao + 1;
                }

            }
            this.exibe = true;
        },
        hide: function() {
            this.limpa();
            this.exibe = false;
        },
        salvar: function(ok) {
            ok();
            let registro = {
                "id_pagina": parseInt(this.id_pagina),
                "tipo": this.tipo,
                "posicao" : parseInt(this.posicao),
                "txt_botao_1" : this.txt_botao_1,
                "url_botao_1" : this.url_botao_1,
                "cor_botao_1" : this.cor_botao_1,
                "txt_botao_2" : this.txt_botao_2,
                "url_botao_2" : this.url_botao_2,
                "cor_botao_2" : this.cor_botao_2,
                "url_img": this.url_img,
                "tema": this.tema,
                "texto": this.titulo_interno,
                "titulo": this.titulo, 
                "int_geral": parseInt(this.produto),
            };
            
            let requisicao = null;
            if(this.idComponente > 0) {
                registro.Id = this.idComponente;
                requisicao = axios.put("/api/componentes", registro);
            } else {
                requisicao = axios.post("/api/componentes", registro);
            }
			requisicao
				.then( () => {
                    // atualiza a lista
                    this.getPaginas();
                    // Limpa form
                    this.limpa();
				})
				.catch( err => {
                    if(err.response.status == 401 || err.response.status == 403) {
                        this.$router.push('/admin').catch(()=>{});
                    }
					console.log("erro no cadastro do bloco", err.response.data)
                    this.getPaginas();
				});
        },
        limpa: function() {
            this.idComponente = 0;
            this.id_pagina = 0;
            this.tipo = '';
            this.titulo = '';
            this.cor_botao_1 = '';
            this.cor_botao_2 = '';
            this.url_img = '';
            this.posicao = 0;
            this.txt_botao_1 = '';
            this.url_botao_1 = '';
            this.txt_botao_2 = '';
            this.url_botao_2 = '';
            this.tema = '';
            this.titulo_interno = '';
            this.produto = 0;
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .header-modal-componentes {
        border: white !important;
        padding-bottom: 0 !important;
    }

    .body-modal-componentes {
        padding-right: 50px !important;
        padding-left: 50px !important;
    }

    .footer-modal-componentes {
        border: white !important;
    }

    .form-input-componentes-agentes {
        width: 100%;
        border: none !important;
        border-bottom: 1px solid black !important;
        outline: none;
    }
    .select-input-modal-componentes-agentes {
        width: 100%;
        outline: none;
        border: none;
        border-bottom: 1px solid black !important;
    }

    .botoes-float-right {
        float: right;
        margin-left: 20px;
    }

    .botoes-float-right-container {
        width: 100%;
        margin-top: 30px;

    }

    .container-img-banner-padrao {
        height: 40px;
        width: auto;
    }

    .container-img-banner-padrao img{
        max-width: 100%;
        max-height: 100%;
        margin: auto;
        display: block;
    }

</style>
<template>
    <b-modal 
        v-model="exibe" 
        body-class="body-modal-componentes" 
        header-class="header-modal-componentes" 
        footer-class="footer-modal-componentes"
        size="lg" 
        no-enforce-focus
    >
        
        <template v-slot:default="{ cancel, ok }">
            <div class="container">


                <div class="row pb-2">
                    <h3> {{ idComponente > 0? 'Editar bloco': 'Novo bloco'}} - Página {{ titulo_pagina }}</h3>
                </div>

                <div class="row pb-2">
                    <div class="col-6">
                        <div class="container">
                        
                            <div class="row py-2">
                                <label for="select-input-componentes">Modelo de Bloco</label>
                                <select disabled v-model="tipo" name="select" id="select-input-componentes" class="select-input-modal-componentes">
                                    <option disabled :value="''">Modelo</option>
                                    <option disabled v-for="(tipo, i) in tiposComponentes" v-bind:key="i" :value="tipo.chave">{{ tipo.valor }}</option> 
                                </select>
                            </div>

                            <div class="row py-2">
                                <label for="ordem-input-componentes">Posição</label>
                                <input type="number" v-model="posicao" name="ordem1" id="ordem-input-componentes" class="form-input-componentes" >
                            </div>

                            <div class="row py-2">
                                <div class="col-6 px-0">
                                    <label for="texto-botao-1-input-componentes">Botão de ação 1</label>
                                    <input type="text" v-model="txt_botao_1" name="texto-botao-1" id="texto-botao-1-input-componentes" class="form-input-componentes" >
                                </div>
                                <div class="col-6 pr-0">
                                    <label for="select-botao-1-input-componentes">Cor do Botão 1</label>
                                    <select v-model="cor_botao_1" name="select-btn-1" id="select-botao-1-input-componentes" class="select-input-modal-componentes">
                                        <option :value="''">Escolha...</option>
                                        <option v-for="(cor, i) in listaCores" v-bind:key="i" :value="cor.chave">{{ cor.valor }}</option> 
                                    </select>
                                </div>
                            </div>

                            <div class="row py-2">
                                <label for="url-botao-1-input-componentes">Direcionamento do Botão 1</label>
                                <input type="text" v-model="url_botao_1" name="url-botao-1" id="url-botao-1-input-componentes" class="form-input-componentes" >
                            </div>

                            <div class="row py-2">
                                <div class="col-6 px-0">
                                    <label for="texto-botao-2-input-componentes">Botão de ação 2</label>
                                    <input type="text" v-model="txt_botao_2" name="texto-botao-2" id="texto-botao-2-input-componentes" class="form-input-componentes" >
                                </div>
                                <div class="col-6 pr-0">
                                    <label for="select-botao-2-input-componentes">Cor do Botão 2</label>
                                    <select v-model="cor_botao_2" name="select-btn-2" id="select-botao-2-input-componentes" class="select-input-modal-componentes">
                                        <option :value="''">Escolha...</option>
                                        <option v-for="(cor, i) in listaCores" v-bind:key="i" :value="cor.chave">{{ cor.valor }}</option> 
                                    </select>
                                </div>
                            </div>
                            
                            <div class="row py-2">
                                <label for="url-botao-2-input-componentes">Direcionamento do Botão 2</label>
                                <input type="text" v-model="url_botao_2" name="url-botao-2" id="url-botao-2-input-componentes" class="form-input-componentes" >
                            </div>

                            <div class="row py-1">
                                <label for="select-fundo-input-componentes">Cor de fundo</label>
                                <select v-model="tema" name="select-fundo" id="select-fundo-input-componentes" class="select-input-modal-componentes">
                                    <option :value="''">Escolha...</option>
                                    <option v-for="(cor, i) in listaCores" v-bind:key="i" :value="cor.chave">{{ cor.valor }}</option> 
                                </select>
                            </div>

                        </div>
                    </div>


                    <div class="col-6">
                        <div class="container">

                            <div class="row py-2">
                                <label for="titulo-input-componentes">Título</label>
                                <input type="text" v-model="titulo" name="titulo" id="titulo-input-componentes" class="form-input-componentes" >
                            </div>

                            <div class="row py-2">
                                <label for="sub-titulo-input-lista-icones-icone">Subtítulo</label>
                                <input type="text" v-model="sub_titulo" name="input-sub-titulo" id="sub-titulo-input-lista-icones-icone" class="form-input-lista-icones" >
                            </div>

                            <div class="row py-2">
                                <div class="col-6 pl-0">
                                    <label for="select-cor-titulo-input-lista-icones">Cor do Título</label>
                                    <select v-model="cor_titulo" name="select-cor-titulo" id="select-cor-titulo-input-lista-icones" class="select-input-modal-lista-icones">
                                        <option disabled :value="''">Escolha...</option>
                                        <option v-for="(cor, i) in listaCores" v-bind:key="i" :value="cor.chave">{{ cor.valor }}</option> 
                                    </select>
                                </div>
                                <div class="col-6 px-0">
                                    <label for="select-cor-sub-titulo-input-lista-icones">Cor do Subtítulo </label>
                                    <select v-model="cor_sub_titulo" name="select-cor-sub-titulo" id="select-cor-sub-titulo-input-lista-icones" class="select-input-modal-lista-icones">
                                        <option disabled :value="''">Escolha...</option>
                                        <option v-for="(cor, i) in listaCores" v-bind:key="i" :value="cor.chave">{{ cor.valor }}</option> 
                                    </select>
                                </div>
                            </div>
                        
                            <div class="row">
                                <div class="col-12 px-0">
                                    <label>Texto</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 px-0 coluna-editor-ck-modal-bloco-padrao">
                                    <vue-editor-custom-c-k ref="editorConteudo" :toolbarConfig="toolbarArray"/>
                                </div>
                            </div>

                            <div class="row pt-2 input-linhas-rodape">
                                <label>Imagem Principal </label>
                            </div>
                            <div class="row pb-2 input-linhas-rodape">
                                <input-imagens ref="inputImagensRef" @upload="uploadImagem" />
                            </div>
                            <div class="row" v-if="url_img? url_img.length > 0: false">
                                <div class="container-img-banner-padrao">
                                    <img :src="url_img" />
                                </div>
                            </div>
                            <div class="row" v-else-if="url_temp? url_temp.length > 0: false">
                                <div class="container-img-banner-padrao">
                                    <img :src="url_temp" />
                                </div>
                            </div>

                            <div class="row pt-2 input-linhas-rodape">
                                <label>Imagem Mobile </label>
                            </div>
                            <div class="row pb-2 input-linhas-rodape">
                                <input-imagens ref="inputImagensRefMb" @upload="uploadImagemMb" />
                            </div>
                            <div class="row" v-if="url_img_mb? url_img_mb.length > 0: false">
                                <div class="container-img-banner-padrao">
                                    <img :src="url_img_mb" />
                                </div>
                            </div>
                            <div class="row" v-else-if="url_temp_mb? url_temp_mb.length > 0: false">
                                <div class="container-img-banner-padrao">
                                    <img :src="url_temp_mb" />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


                <div class="row py-2">
                    <div class="botoes-float-right-container">
                        <botao-verde class="botoes-float-right" @click="salvar(ok)" > Salvar </botao-verde>
                        <botao-branco class="botoes-float-right" @click="cancel()" > Cancelar </botao-branco>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:modal-footer>
            <div class="mr-5">
                
            </div>
        </template>
    </b-modal>
</template>

<script>
import BotaoVerde from '@/components/botoes/BotaoVerde.vue';
import BotaoBranco from '@/components/botoes/BotaoBranco.vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import { TIPOS_COMPONENTES, LISTA_CORES } from '@/assets/js/dicionarioPaginas';
import InputImagens from '@/components/inputs/InputImagens.vue';
import VueEditorCustomCK from '@/components/blog-components/VueEditorCustomCK.vue'


export default {
    name: 'ModalBlocoPadrao',
    components: {
        BotaoVerde,
        BotaoBranco,
        InputImagens,
        VueEditorCustomCK,
    },
    props: {
       toolbarArray: {
            type: Array,
            default: () => [ 'bold', 'italic', 'heading', '|', 'link', 'alignment', 'bulletedList', 'numberedList', 'fontSize', 'fontColor' ],
        }
    },
    data: function() {
        return {
            exibe: false,
            tiposComponentes: TIPOS_COMPONENTES,
            listaCores: LISTA_CORES,
            idComponente: {
                type: Number,
                default: 0
            },
            id_pagina: 0,
            titulo_pagina: 0,
            tipo: '',
            titulo: '',
            cor_botao_1: '',
            cor_botao_2: '',
            texto: '',
            url_img: '',
            url_temp: '',
            imagem: null,

            url_img_mb: '',
            url_temp_mb: '',
            imagemMb: null,

            posicao: 0,
            txt_botao_1: '',
            url_botao_1: '',
            txt_botao_2: '',
            url_botao_2: '',
            tema: '',
            cor_titulo: '',
            sub_titulo: '',
            cor_sub_titulo: '',
            conteudo_editor: '',
        }
    },
    computed: {
        ...mapGetters({
            paginas: 'paginas/paginas',
        })
    },
    methods: {
        ...mapActions({
            getPaginas: 'paginas/getPaginas'
        }),
        show: function(pIdPagina, pIdComponente = 0) {
            this.idComponente = pIdComponente;
            this.id_pagina = pIdPagina;

            if(this.id_pagina) {
                let pagina = this.paginas.find(item => item.id == this.id_pagina);
                this.titulo_pagina = pagina.titulo;
                if(this.idComponente) {
                    let compSelecionado = pagina.componentes.find(comp => comp.id == this.idComponente);
                    this.tipo = compSelecionado.tipo;
                    this.titulo = compSelecionado.titulo;
                    this.url_img = compSelecionado.url_img;
                    this.url_img_mb = compSelecionado.url_img_mb;
                    this.posicao =compSelecionado.posicao;
                    this.txt_botao_1 = compSelecionado.txt_botao_1;
                    this.url_botao_1 = compSelecionado.url_botao_1;
                    this.txt_botao_2 = compSelecionado.txt_botao_2;
                    this.url_botao_2 = compSelecionado.url_botao_2;
                    this.texto = compSelecionado.texto;
                    this.cor_botao_1 = compSelecionado.cor_botao_1;
                    this.cor_botao_2 = compSelecionado.cor_botao_2;
                    this.cor_titulo = compSelecionado.cor_titulo?? '';
                    this.sub_titulo = compSelecionado.sub_titulo;
                    this.cor_sub_titulo = compSelecionado.cor_sub_titulo?? '';
                    this.tema = compSelecionado.tema?? '';
                } else {
                    let ultimaPosicao = Math.max(...pagina.componentes.map(comp => comp.posicao));
                    this.posicao = ultimaPosicao + 1;
                }

            }
            this.exibe = true;
        },
        hide: function() {
            this.limpa();
            this.exibe = false;
        },
        salvarDados: function() {
            let registro = {
                "id_pagina": parseInt(this.id_pagina),
                "tipo": this.tipo,
                "posicao" : parseInt(this.posicao),
                "txt_botao_1" : this.txt_botao_1,
                "url_botao_1" : this.url_botao_1,
                "cor_botao_1" : this.cor_botao_1,
                "txt_botao_2" : this.txt_botao_2,
                "url_botao_2" : this.url_botao_2,
                "cor_botao_2" : this.cor_botao_2,
                "url_img": this.url_img,
                "url_img_mb": this.url_img_mb,
                "tema": this.tema,
                "texto": this.texto,
                "titulo": this.titulo, 
                "cor_titulo" : this.cor_titulo,
                "sub_titulo" : this.sub_titulo,
                "cor_sub_titulo" : this.cor_sub_titulo,
            };
            let requisicao = null;
            if(this.idComponente > 0) {
                registro.Id = this.idComponente;
                requisicao = axios.put("/api/componentes", registro);
            } else {
                requisicao = axios.post("/api/componentes", registro);
            }
			requisicao
				.then( () => {
                    // atualiza a lista
                    this.getPaginas();
                    // Limpa form
                    this.limpa();
				})
				.catch( err => {
                    if(err.response.status == 401 || err.response.status == 403) {
                        this.$router.push('/admin').catch(()=>{});
                    }
					console.log("erro no cadastro do bloco", err.response.data)
				});
        },
        limpa: function() {
            this.idComponente = 0;
            this.id_pagina = 0;
            this.tipo = '';
            this.titulo = '';
            this.cor_botao_1 = '';
            this.cor_botao_2 = '';
            this.texto = '';
            this.url_img = '';
            this.url_temp = '';
            this.url_img_mb = '';
            this.url_temp_mb = '';
            this.posicao = 0;
            this.txt_botao_1 = '';
            this.url_botao_1 = '';
            this.txt_botao_2 = '';
            this.url_botao_2 = '';
            this.imagem = null;
            this.imagemMb = null;
            this.tema = '';
            this.cor_titulo = '';
            this.sub_titulo = '';
            this.cor_sub_titulo = '';
        },
        uploadImagem: function(imagem, url_temp) {
            this.imagem = imagem;
            this.url_temp = url_temp;
            this.url_img = '';
        },
        uploadImagemMb: function(imagem, url_temp) {
            this.imagemMb = imagem;
            this.url_temp_mb = url_temp;
            this.url_img_mb = '';
        },
        salvarImagem: async function() {
            const formData = new FormData();
            if (this.imagem) {
                formData.append('arquivo', this.imagem, this.imagem.name)
                return axios.post('/api/imagens/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            } else {
                return;
            }
        },
        salvarImagemMb: async function() {
            const formData = new FormData();
            if (this.imagemMb) {
                formData.append('arquivo', this.imagemMb, this.imagemMb.name)
                return axios.post('/api/imagens/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            } else {
                return;
            }
        },
        salvar: function(ok) {
            // Atualiza variavel do conteudo antes de fechar o modal para nao vir undefined
            this.getContentEditor();
            // Metodo Ok que fecha o modal
            ok();
            // Primeiro salva a imagem
            this.salvarImagem()
                .then( response => {
                    if(response) {
                        this.url_img = response.data.url;
                    }
                    this.salvarImagemMb().then( responseMb => {
                        if(responseMb) {
                            this.url_img_mb = responseMb.data.url;
                        }
                        // Depois salva os dados na tabela
                        this.salvarDados();
                    })
                    
                })
                .catch(err => {
                    console.log('erro no envio da imagem');
                    console.log(err);
                    console.log(err.response);
                });

        },
        getContentEditor: function() {
            if(this.$refs.editorConteudo) {
                let conteudo = this.$refs.editorConteudo.getContent();
                this.texto = conteudo;
                return conteudo;
            }
            return this.texto;
        },
        setContentEditor: function(txt) {
            if(this.$refs.editorConteudo) {
                this.$refs.editorConteudo.setContent(txt);
                this.$refs.editorConteudo.prefill();
            }
            this.texto = txt;
        },
    },

    updated() {
        this.$refs.editorConteudo.setContent(this.texto? this.texto: '');
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.header-modal-componentes {
    border: white !important;
    padding-bottom: 0 !important;
}

.body-modal-componentes {
    padding-right: 50px !important;
    padding-left: 50px !important;
}

.footer-modal-componentes {
    border: white !important;
}

.form-input-componentes {
    width: 100%;
    border: none !important;
    border-bottom: 1px solid black !important;
    outline: none;
}
.select-input-modal-componentes {
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 1px solid black !important;
}

.botoes-float-right {
    float: right;
    margin-left: 20px;
}

.botoes-float-right-container {
    width: 100%;
    margin-top: 30px;

}

.container-img-banner-padrao {
    height: 40px;
    width: auto;
}

    .container-img-banner-padrao img{
        max-width: 100%;
        max-height: 100%;
        margin: auto;
        display: block;
    }

</style>
<template>
<div class="container h-100 px-0 editor-ck-custom">
   <ckeditor :editor="editor" v-model="editorData" :config="editorConfig" @ready="prefill"></ckeditor>
</div>
</template>

<script>
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// O editor esta sendo buildado novamente, adicionando os plugins necessários
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';

import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading';
import ListPlugin from '@ckeditor/ckeditor5-list/src/list';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import FontPlugin from '@ckeditor/ckeditor5-font/src/font';
// Sempre que adicionar um novo plugin, verificar a versão instalada no package.json
// É necessário que seja a mesma versão dos demais plugins.
// Com o novo plugin instalado, na versão correta, pode ser necessário:
// - Excluir o package-lock.json
// - Excluir o node_modules 
// - Rodar 'npm install' no projeto

import FileRepository from '@ckeditor/ckeditor5-upload/src/filerepository';
// Adapter customizado para receber e enviar valores de acordo com a API
import UploadAdapterCustom from '@/assets/js/UploadAdapter';
function CustomUploadAdapterPlugin( editor ) {
    editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        // Configure the URL to the upload script in your back-end here!
        return new UploadAdapterCustom( loader );
    };
}

const fontColorConfig = {
	colors: [
		{
			color: 'hsl(0, 0%, 0%)',
			label: 'Preto'
		},
		{
			color: '#353535',
			label: 'Chumbo'
		},
		{
			color: 'hsl(0, 0%, 60%)',
			label: 'Cinza'
		},
		{
			color: 'hsl(0, 0%, 90%)',
			label: 'Cinza claro'
		},
		{
			color: 'hsl(0, 0%, 100%)',
			label: 'Branco',
			hasBorder: true
		},
		{
			color: 'hsl(0, 75%, 60%)',
			label: 'Vermelho'
		},
		{
            // color: 'hsl(30, 75%, 60%)',
            color: 'var(--laranja-claro-omni)',
			label: 'Laranja'
		},
		{
			color: 'hsl(60, 75%, 60%)',
			label: 'Amarelo'
		},
		{
			color: 'hsl(90, 75%, 60%)',
			label: 'Verde Claro'
		},
		{
			color: 'hsl(120, 75%, 60%)',
			label: 'Verde'
		},
		{
			color: 'hsl(150, 75%, 60%)',
			label: 'Azul Marinho'
		},
		{
			color: 'hsl(180, 75%, 60%)',
			label: 'Azul Turquesa'
		},
		{
			color: 'var(--azul-claro-omni)',
			label: 'Azul Claro'
		},
		{
			color: 'hsl(240, 75%, 60%)',
			label: 'Azul'
		},
		{
			color: 'hsl(270, 75%, 60%)',
			label: 'Roxo'
		}
	]
};

export default {
    name: 'VueEditorCustomCK',
    components: {
    },
    props: {
        toolbarConfig: {
            type: Array,
            default: () => [ 'bold', 'italic', '|', 'link', '|', 'undo', 'redo', 'imageUpload', 'heading', 'alignment', 'bulletedList', 'numberedList', 'fontSize', 'fontColor'],
        }
    },
    computed: {
        editorConfig()  {
            return {
                plugins: [
                    EssentialsPlugin,
                    BoldPlugin,
                    ItalicPlugin,
                    LinkPlugin,
                    ParagraphPlugin,
                    Image,
                    ImageToolbar,
                    ImageCaption,
                    ImageStyle,
                    ImageResize,
                    LinkImage,
                    FileRepository,
                    ImageUpload,
                    HeadingPlugin,
                    ListPlugin,
                    Alignment,
                    FontPlugin,
                ],
                image: {
                    resizeUnit: 'px', // padrao é %
                    toolbar: [
                        'imageStyle:full',
                        'imageStyle:side',
                        '|',
                        'imageTextAlternative',
                        '|',
                        'linkImage'
                    ]
                },
                fontSize: {
                    options: [
                        9,
                        11,
                        13,
                        'default',
                        17,
                        19,
                        21
                    ]
                },
                fontColor: fontColorConfig,
                extraPlugins: [
                    CustomUploadAdapterPlugin,
                ],
                toolbar: this.toolbarConfig,//[ 'bold', 'italic', '|', 'link', '|', 'undo', 'redo', 'imageUpload', 'heading', 'alignment', 'bulletedList', 'numberedList' ],
            }
        }
    },
    data() {
        return {
            editor: ClassicEditor,
            editorData: '<p>Escreva algo legal.</p>',
            
        };
    },
    methods: {
        getContent() {
            return this.editorData;
        },
        setContent(content) {
            this.conteudo = content;
        },
        prefill(  ) {
			this.editorData = this.conteudo;
        },
        configuraToolbar(lista) {
            this.editorConfig.toolbar = lista;
        }
    },
    mounted() {
    }
}
</script>

<style>

.editor-ck-custom .ck-content {
    min-height: 200px !important;
}

    .editor-ck-custom .ck-content ol,
    .editor-ck-custom .ck-content ul {
        padding-left: 15px !important;
    }

:root {
    /* z-index base do editor CK - 100 garante que funcionará direito dentro dos modais do bootstrap */
    --ck-z-default: 100;
    --orange: #dd5f13;
}
</style>
<template>
    <div class="modal fade" id="modalFerramentasContato" tabindex="-1" role="dialog" aria-labelledby="modalFerramentasPopup" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content caixa_modal">
                <div>
                    <button class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body caixa_body">
                    <div>
                        <p id="modal_alterar">Formulário de contato</p>
                    </div>
                    <div>
                        <label>Título</label>
                        <input-text class="w-100" v-model="formTitulo"></input-text>
                    </div>

                    <div>
                        <label>Texto inferior</label>
                        <input-text class="w-100" v-model="formTxtInferior"></input-text>
                    </div>

                    <div>
                        <p>Imagem</p>
                        <img :src="formImagem" style="width: 100px;height:auto;">
                        <input-imagem ref="inputFile" @upload="uploadImagem" class="input-image mt-3"></input-imagem>
                    </div>

                    <div class="botoes">
                        <button-fechar-branco class="btn-md mr-2" data-dismiss="modal" aria-label="Close">Fechar</button-fechar-branco>
                        <botao-verde class="btn-md btn_salvar" @click="updateFormContato">Salvar</botao-verde>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import { mapGetters, mapActions } from 'vuex'
    import inputImagem from '@/components/inputs/InputImagens.vue'
    import inputText from '@/components/inputs/InputText.vue'
    import BotaoVerde from '@/components/botoes/BotaoVerde.vue'
    import ButtonFecharBranco from '@/components/botoes/ButtonFecharBranco.vue'

    export default {
        name: 'ModalFerramentasLogo',
        components: {
            BotaoVerde,
            ButtonFecharBranco,
            inputImagem,
            inputText
        },
        data() {
            return {
                uploadedImagem: '',
                form: {
                    imagem: '',
                    titulo: '',
                    txtInferior: ''
                }
            }
        },
        computed: {
            ...mapGetters({
                formContato: 'formContato/GET_FORM'
            }),
            formTitulo: {
                get: function () {
                    if (this.formContato) {
                        //eslint-disable-next-line
                        this.form.titulo = this.formContato.titulo;
                        return this.formContato.titulo;
                    }
                    return ''
                },
                set: function (value) {
                    this.form.titulo = value;
                }
            },
            formTxtInferior: {
                get: function () {
                    if (this.formContato) {
                        //eslint-disable-next-line
                        this.form.txtInferior = this.formContato.txtInferior;
                        return this.formContato.txtInferior;
                    }
                    return ''
                },
                set: function (value) {
                    this.form.txtInferior = value;
                }
            },
            formImagem() {
                if (this.formContato) {
                    return `/storage/imagens/${this.formContato.imagem}`;
                }
                return ''
            }
        },
        methods: {
            ...mapActions({
                getForm: 'formContato/getFormContato'
            }),
            updateFormContato() {
                if (this.uploadedImagem) {
                    this.salvarImagem();
                }
                this.salvarDados();
            },
            salvarDados() {
                axios.put('/api/formcontato', this.form)
                    .then(() => {
                        alert('Formulario de contato atualizado com sucesso!');
                        this.getForm();
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            },
            uploadImagem(imagem) {
                if (imagem) {
                    this.uploadedImagem = imagem;
                }
            },
            salvarImagem() {
                this.form.imagem = this.uploadedImagem.name;
                const FD = new FormData();
                FD.append('arquivo', this.uploadedImagem);

                axios.post('/api/imagens/upload', FD, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then(() => {
                        this.$root.$emit('resetInputFile');
                        this.form.imagem = '';
                        this.uploadedImagem = '';
                    })
                    .catch((err) => { console.log(err); })
            },
        },
    }
</script>

<style scoped>
    .caixa_modal {
        padding: 7px 14px;
        border: none !important;
        border-radius: unset !important;
    }

    .caixa_body {
        text-align: left;
        margin: 0 5%;
    }

        .caixa_body > div {
            margin: 5% 0;
        }

    .botoes {
        text-align: right;
    }

    .btn_salvar {
        margin-left: 10px;
    }

    button.close:focus {
        outline: none;
    }

    #modal_alterar {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 2rem;
    }

    .input-image {
        width: 100% !important;
    }

    @media only screen and (max-width: 500px) {
        #input {
            width: 80% !important;
        }
    }
</style>
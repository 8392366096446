<template>
    <div id="painel-administrativo">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12 col-md-6 col-xl-4">
                    <img src="@/assets/images/omni-logo.png" width="100" />
                    <h3 class="my-4">Acesso para Administradores</h3>

                    <form class="form-group w-100">
                        <div class="px-5 mb-2 text-left">
                            <label class="m-0" for="email">Login</label>
                            <input v-model="dadosAdm.email" class="form-control" type="text" id="login" />
                        </div>
                        <div class="px-5 text-left">
                            <label class="m-0" for="senha">Senha</label>
                            <input v-model="dadosAdm.senha" class="form-control" type="password" id="senha" />
                        </div>
                        <br />
                        <div>
                            <button type="button" @click="fazerLogin" class="btn button-login">
                                Entrar
                                <span v-show="loading" class="spinner-border spinner-border-sm ml-2"></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

    export default {
        name: 'LoginAdmPage',
        data: () => {
            return {
                dadosAdm: {
                    email: '',
                    senha: ''
                },
                loading: false
            }
        },
        methods: {
            ...mapActions({
                signIn: 'authAdmin/signIn',
            }),
            fazerLogin() {
                this.loading = true;
                this.signIn({ "email": this.dadosAdm.email, "senha": this.dadosAdm.senha })
                .then(() => {
                    this.$router.push(this.$route.query.acessoRestrito ? `/admin` : '/').catch(() => { });
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err)
                    this.loading = false;
                    alert('ACESSO NEGADO');
                })
            },
        },
        beforeRouteEnter(to, from, next) {
            if ('acessoRestrito' in to.query) {
                next();
            } else {
                next({
                    name: 'LoginAdmPage',
                    query: { acessoRestrito: true }
                });
            }
        }
    }

</script>

<style scoped>
    #painel-administrativo {
        padding: 5%;
        font-family: MuseoSans;
        text-align: center;
    }

    #painel-administrativo input {
        border: 1px solid black;
        border-radius: 0;
    }

    .button-login {
        min-width: 80px;
        text-align: center;
        color: white !important;
        font-weight: 500;
        background-color: var(--azul-omni);
        border-radius: 0 !important;
    }

    .button-login:hover {
        opacity: 0.75;
    }
</style>
<template>
    <b-modal 
        v-model="exibe" 
        body-class="body-modal-posts" 
        header-class="header-modal-posts" 
        footer-class="footer-modal-posts"
        size="lg" 
        no-enforce-focus
    >
        
        <template v-slot:default="{ cancel, ok }">
            <div class="container">


                <div class="row pb-2">
                    <h3> {{ idPost > 0? 'Editar Postagem': 'Nova Postagem'}} </h3>
                </div>

                <div class="row pb-2">

                    <div class="col-12">
                        <div class="container">

                            <div class="row py-2">
                                <label for="titulo-input-posts">Título</label>
                                <input type="text" v-model="titulo" name="titulo" id="titulo-input-posts" class="form-input-posts" >
                            </div>

                            <div class="row py-2">
                                <label for="tag-input-blog-post">Tags</label>
                                <input type="text" v-model="tags" name="input-tag-post" id="tag-input-blog-post" class="form-input-posts" >
                            </div>

                            <div class="row py-2">
                                <label for="autor-input-blog-post">Autor</label>
                                <input type="text" v-model="autor" name="input-autor-post" id="autor-input-blog-post" class="form-input-posts" >
                            </div>

                            <div class="row py-2">
                                <div class="pl-0 col-4">
                                    <label for="ano-input-blog-post">Ano</label>
                                    <input type="number" v-model.number="ano" name="input-ano" id="ano-input-blog-post" class="form-input-posts" >
                                </div>
                                
                                <div class="pr-0 col-4">
                                    <label for="edicao-input-blog-post">Edição</label>
                                    <input type="number" v-model.number="edicao" name="input-edicao" id="edicao-input-blog-post" class="form-input-posts" >
                                </div>
                            </div>


                            <div class="row pt-2">
                                <label>Imagem Principal </label>
                            </div>
                            <div v-if="(url_imagem? url_imagem.length > 0: false) || (url_temp? url_temp.length > 0: false)" class="row py-1 input-linhas-rodape row-manter-imagem" >
                                <div class="col-4 pl-0" v-if="url_imagem? url_imagem.length > 0: false">
                                    <div class="container-img-principal-post">
                                        <img :src="url_imagem" />
                                    </div>
                                </div>

                                <div class="col-4 pl-0" v-else-if="url_temp? url_temp.length > 0: false">
                                    <div class="container-img-principal-post">
                                        <img :src="url_temp" />
                                    </div>
                                </div>

                                <div class="col-4 pl-0" v-if="idPost > 0">
                                    <input  type="checkbox" id="manter-imagem-blocos-rodape" v-model="manterImagem" >
                                    <label for="manter-imagem-blocos-rodape">Manter imagem atual</label>
                                </div>
                            </div>

                            <div class="row py-2">                               
                                <div class="col-8 px-0">
                                    <input-imagens ref="inputImagensRef" @upload="uploadImagem" />
                                </div>
                            </div>


                            <div class="row pt-2">
                                <label>Imagem Principal Mobile</label>
                            </div>
                            <div v-if="(url_imagem_mb? url_imagem_mb.length > 0: false) || (url_temp_mb? url_temp_mb.length > 0: false)" class="row py-1 input-linhas-rodape row-manter-imagem" >
                                <div class="col-4 pl-0" v-if="url_imagem_mb? url_imagem_mb.length > 0: false">
                                    <div class="container-img-principal-post">
                                        <img :src="url_imagem_mb" />
                                    </div>
                                </div>

                                <div class="col-4 pl-0" v-else-if="url_temp_mb? url_temp_mb.length > 0: false">
                                    <div class="container-img-principal-post">
                                        <img :src="url_temp_mb" />
                                    </div>
                                </div>

                                <div class="col-4 pl-0" v-if="idPost > 0">
                                    <input  type="checkbox" id="manter-imagem-blocos-rodape" v-model="manterImagemMb" >
                                    <label for="manter-imagem-blocos-rodape">Manter imagem atual</label>
                                </div>
                            </div>

                            <div class="row py-2">                               
                                <div class="col-8 px-0">
                                    <input-imagens ref="inputImagensRefMb" @upload="uploadImagemMb" />
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-12 px-0">
                                    <label>Conteúdo</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 px-0 coluna-editor-ck-modal-posts-blog">
                                    <vue-editor-custom-c-k ref="editorConteudo"/>
                                </div>
                            </div>
                            

                        </div>
                    </div>

                </div>


                <div class="row py-2">
                    <div class="botoes-float-right-container">
                        <botao-verde class="botoes-float-right" @click="salvar(ok)" > Salvar </botao-verde>
                        <botao-branco class="botoes-float-right" @click="cancel()" > Cancelar </botao-branco>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:modal-footer>
            <div class="mr-5">
                
            </div>
        </template>
    </b-modal>
</template>

<script>
import BotaoVerde from '@/components/botoes/BotaoVerde.vue';
import BotaoBranco from '@/components/botoes/BotaoBranco.vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import InputImagens from '@/components/inputs/InputImagens.vue';
import VueEditorCustomCK from '@/components/blog-components/VueEditorCustomCK.vue'


export default {
    name: 'ModalPosts',
    components: {
        BotaoVerde,
        BotaoBranco,
        InputImagens,
        VueEditorCustomCK,
    },
    props: {
    },
    data: function() {
        return {
            exibe: false,
            idPost: {
                type: Number,
                default: 0
            },
            titulo: '',
            conteudo: '',
            tags: '',
            previa: '',
            ano: 0,
            edicao: 0,
            autor: '',

            url_imagem: '',
            url_temp: '',
            imagem: null,
            manterImagem: false,

            url_imagem_mb: '',
            url_temp_mb: '',
            imagem_mb: null,
            manterImagemMb: false,
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            posts: 'posts/posts',
        })
    },
    methods: {
        ...mapActions({
            getPosts: 'posts/getPosts'
        }),
        show: function(pIdPost = 0) {
            this.idPost = pIdPost;

            if(this.idPost) {
                let postSelecionado = this.posts.find(post => post.id == this.idPost);
                this.titulo = postSelecionado.titulo;
                this.conteudo = postSelecionado.conteudo;
                this.tags = postSelecionado.tags;
                this.previa = postSelecionado.previa;
                this.url_imagem = postSelecionado.url_imagem;
                this.manterImagem = this.url_imagem? true: false;

                this.url_imagem_mb = postSelecionado.url_imagem_mb;
                this.manterImagemMb = this.url_imagem_mb? true: false;
                this.ano = postSelecionado.ano;
                this.edicao = postSelecionado.edicao;
                this.autor = postSelecionado.autor;
            } 

            this.exibe = true;
        },
        hide: function() {
            this.limpa();
            this.exibe = false;
        },
        salvarDados: function() {
            let registro = {
                "titulo" : this.titulo,
                "conteudo" : this.conteudo,
                "tags" : this.tags,
                "previa" : this.previa,
                "url_imagem" : this.url_imagem,
                "url_imagem_mb" : this.url_imagem_mb,
                "ano" : this.ano,
                "edicao" : this.edicao,
                "autor": this.autor,
                "data_publicacao": new Date(),
            };
            let requisicao = null;
            if(this.idPost > 0) {
                registro.Id = this.idPost;
                requisicao = axios.put("/api/posts", registro);
            } else {
                requisicao = axios.post("/api/posts", registro);
            }
			requisicao
				.then( () => {
                    // atualiza a lista
                    this.getPosts();
                    // Limpa form
                    this.limpa();
				})
				.catch( err => {
                    if(err.response.status == 401 || err.response.status == 403) {
                        this.$router.push('/admin').catch(()=>{});
                    }
					console.log("erro no cadastro da postagem", err.response.data)
				});
        },
        limpa: function() {
            this.idPost = 0;
            this.titulo = '';
            this.conteudo = '';
            this.tags = '';
            this.previa = '';
            this.ano = 0;
            this.edicao = 0;
            this.autor = '';
            this.url_imagem = '';
            this.url_temp = '';
            this.manterImagem = false;
            this.imagem = null;

            this.url_imagem_mb = '';
            this.url_temp_mb = '';
            this.manterImagemMb = false;
            this.imagem_mb = null;
        },
        uploadImagem: function(imagem, url_temp) {
            this.imagem = imagem;
            this.url_temp = url_temp;
            this.url_imagem = '';
        },
        uploadImagemMb: function(imagem, url_temp) {
            this.imagem_mb = imagem;
            this.url_temp_mb = url_temp;
            this.url_imagem_mb = '';
        },
        salvarImagem: async function() {
            const formData = new FormData();
            if (this.imagem && !this.manterImagem) {
                formData.append('arquivo', this.imagem, this.imagem.name)
                return axios.post('/api/imagens/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            } else {
                return;
            }
        },
        salvarImagemMb: async function() {
            const formData = new FormData();
            if (this.imagem_mb && !this.manterImagemMb) {
                formData.append('arquivo', this.imagem_mb, this.imagem_mb.name)
                return axios.post('/api/imagens/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            } else {
                return;
            }
        },
        salvar: function(ok) {
            // Atualiza variavel do conteudo antes de fechar o modal para nao vir undefined
            this.getContentEditor();
            // Metodo Ok que fecha o modal
            ok();
            // Primeiro salva as imagens
            this.salvarImagem()
                .then( response => {
                    // Se teve resposta, grava url
                    if(response) {
                        this.url_imagem = response.data.url;
                    }
                    // Salva imagem mobile
                    this.salvarImagemMb()
                        .then((responseMb) => {
                            // Se teve resposta, grava url
                            if(responseMb) {
                                this.url_imagem_mb = responseMb.data.url;
                            }
                            // Depois salva os dados na tabela
                            this.salvarDados();
                        })
                })
                .catch(err => {
                    console.log('erro no envio da imagem');
                    console.log(err);
                    console.log(err.response);
                });

        },
        getContentEditor: function() {
            if(this.$refs.editorConteudo) {
                let conteudo = this.$refs.editorConteudo.getContent();
                this.conteudo = conteudo;
                return conteudo;
            }
            return this.conteudo;
        },
        setContentEditor: function(txt) {
            if(this.$refs.editorConteudo) {
                this.$refs.editorConteudo.setContent(txt);
                this.$refs.editorConteudo.prefill();
            }
            this.conteudo = txt;
        },
    },

    updated() {
        if(this.$refs.editorConteudo) {
            this.$refs.editorConteudo.setContent(this.conteudo? this.conteudo: '');
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this postonent only -->
<style>
.header-modal-posts {
    border: white !important;
    padding-bottom: 0 !important;
}

.body-modal-posts {
    padding-right: 50px !important;
    padding-left: 50px !important;
}

.footer-modal-posts {
    border: white !important;
}

.form-input-posts {
    width: 100%;
    border: none !important;
    border-bottom: 1px solid black !important;
    outline: none;
}
.select-input-modal-posts {
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 1px solid black !important;
}

.botoes-float-right {
    float: right;
    margin-left: 20px;
}

.botoes-float-right-container {
    width: 100%;
    margin-top: 30px;

}

.container-img-principal-post {
    height: 40px;
    width: auto;
}

    .container-img-principal-post img{
        max-width: 100%;
        max-height: 100%;
        display: block;
        border: 1px solid rgba(0,0,0,0.25);
        padding: 1px;
    }

</style>
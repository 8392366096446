<template>
    <button class="btn botao-fechar-branco" v-on:click="$emit('click')">
        <slot></slot>
    </button>
</template>

<script>
    export default {
        name: 'ButtonFecharBranco',
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    button.botao-fechar-branco {
        background-color: transparent;
        border-bottom: 1.5px solid var(--cinza-omni);
        color: var(--chumbo-omni);
        border-radius: 0px;

    }

    .botao-fechar-branco:hover {
        border-bottom: 1.5px solid var(--verde-alerta-omni);
    }
    
    .botao-fechar-branco:focus {
        box-shadow: none;
    }
</style>
<template>
    <label for="inputRef" class="painel_label_mod_image">
        <img src="../../assets/images/svg/imageinput-icon.svg" style="margin-left: 8px;"/>
        <input ref="inputRef" type="file" accept="image/*" @change="uploadImagem" class="painel_input_mod_image" formenctype="multipart/form-data" />
    </label>
</template>

<script>

export default {
    name: 'InputImagens',
    components: {
    },
    data() {
        return {
            imagemUpload: null,
            imagemUrl: '',
        }
    },
    mounted() {
        this.$root.$on('resetInputFile', () => {
            this.clear();
        });
    },
    methods: {
        uploadImagem(event) {
            const TIPO_ARQUIVO_IMAGEM = 'image/';
            let image = event.target.files[0];
            if (!image.type.startsWith(TIPO_ARQUIVO_IMAGEM)) {
                this.clear();
                return alert('Selecione um arquvio de imagem!');
            } else {
                this.imagemUpload = image;
                this.imagemUrl = URL.createObjectURL(image);
                this.$emit('upload', this.imagemUpload, this.imagemUrl);
            }
        },
        clear() {
            this.$refs.inputRef.value = null;
            this.imagemUpload = null;
            this.imagemUrl = '';
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.painel_input_mod_image::-webkit-file-upload-button {
    visibility: hidden;
    width: 0px;
    margin: 0px;
    padding: 0px;
}

    .painel_input_mod_image {
        background: none;
        width: 100%;
        position: relative;
        left: -30px;
        padding-left: 30px;
        padding-top: 3px;
        display: inline-block;
        border: none;
        border-radius: unset;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
        font-size: 7pt;
        min-height: 25px;
    }

    .painel_label_mod_image {
        background: linear-gradient(to bottom, #f9f9f9, #e3e3e3);
        display: inline-block;
        border: none;
        border-radius: unset;
        padding: 3px 2px 3px 2px;
        background-color: var(--cinza-omni) !important;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
        font-size: 7pt;
        width: 100%;
    }

    @media only screen and (max-width: 500px) 
    {
        .painel_label_mod_image 
        {
            width: 100%;
            display: inline-block;
            border: none;
            border-radius: unset;
            padding: 1px 2px 1px 2px;
            background: none;
            background-color: var(--cinza-omni) !important;
            outline: none;
            white-space: nowrap;
            -webkit-user-select: none;
            cursor: pointer;
            font-size: 7pt;
        }

        .painel_input_mod_image {
            width: 100%;
            display: inline-block;
            border: none;
            border-radius: unset;
            background: none;
            position: relative;
            left: -25px;
            padding-left: 20px;

            outline: none;
            white-space: nowrap;
            -webkit-user-select: none;
            cursor: pointer;
            font-size: 7pt;
        }
    }

</style>
<template>
    <div class="modal fade" id="modalFerramentasLogo" tabindex="-1" role="dialog" aria-labelledby="modalFerramentasLogo" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content caixa_modal">
                <div>
                    <button class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body caixa_body">
                    <div>
                        <p id="modal_alterar">Alterar logo</p>
                    </div>
                    <div id="logo">
                        Logo do site<br />
                        <div>
                            <input-imagem id="input" @upload="onUploadedLogo"></input-imagem>
                            <botao-verde class="btn-sm btn_salvar" @click="salvarLogo">Salvar</botao-verde>
                        </div>
                    </div>
                    <div id="favicon">
                        Favicon do site
                        <div>
                            <input-imagem id="input" @upload="onUploadedFavicon"></input-imagem>
                            <botao-verde class="btn-sm btn_salvar" @click="salvarFavicon">Salvar</botao-verde>
                        </div>
                    </div>
                    <div id="fechar">
                        <button-fechar-branco class="btn-md" data-dismiss="modal" aria-label="Close">Fechar</button-fechar-branco>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import inputImagem from '@/components/inputs/InputImagens.vue'
    import BotaoVerde from '@/components/botoes/BotaoVerde.vue'
    import ButtonFecharBranco from '@/components/botoes/ButtonFecharBranco.vue'

    export default {
        name: 'ModalFerramentasLogo',
        components: {
            BotaoVerde,
            ButtonFecharBranco,
            inputImagem

        },
        data() {
            return {
                logoAtualizado: null,
                faviconAtualizado: null,
                logoName: "omni-logo.png",
                faviconName: "favicon.ico"
            }
        },
        methods: {
            onUploadedLogo(logo) {
                if (logo) {
                    this.logoAtualizado = logo;
                    console.log(this.logoAtualizado);
                }
            },
            salvarLogo() {
                const formData = new FormData();
                if (this.logoAtualizado) {
                    formData.append('arquivoImg', this.logoAtualizado, this.logoName)
                    console.log(formData);
                    axios.post('/api/imagens/uploadlogo', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                        .then(res => {
                            console.log({ res });
                        })
                        .catch(err => {
                            console.error({ err });
                        })
                } else {
                    return alert('Selecione uma imagem!');
                }
            },
            onUploadedFavicon(favicon) {
                if (favicon) {
                    this.faviconAtualizado = favicon;
                    console.log(this.faviconAtualizado);
                }
            },
            salvarFavicon() {
                const formData = new FormData();
                console.log(this.faviconAtualizado);
                if (this.faviconAtualizado && this.faviconAtualizado.type == "image/x-icon") {
                    formData.append('file', this.faviconAtualizado, this.faviconName)
                    axios.post('/api/imagens/uploadfavicon', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                        .then(res => {
                            console.log({ res });
                            alert('Favicon atualizado com sucesso!');
                        })
                        .catch(err => {
                            console.error({ err });
                        })
                } else {
                    return alert('Selecione um favicon no formato .ICO');
                }
            },
        }
    }
</script>

<style scoped>
    .caixa_modal {
        padding: 7px 14px;
        border: none !important;
        border-radius: unset !important;
    }

    .caixa_body {
        text-align: left;
        margin: 0 5%;
    }

    #logo, #favicon, #fechar {
        margin: 5% 0;
    }

    #fechar {
        text-align: right;
    }

    .btn_salvar {
        margin-left: 10px;
    }

    button.close:focus {
        outline: none;
    }

    #modal_alterar {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 2rem;
    }

    #input {
        width: 83% !important;
    }

    @media only screen and (max-width: 500px) {
        #input {
            width: 80% !important;
        }
    }
</style>
<template>
<div class="container page-container shadow">

    <div class="row">
        <h1 class="mx-auto mt-2">Mostrador dos componentes</h1>
    </div>

    <hr>

    <div class="row ">
        <h3 class="mx-auto mt-2">Botoes</h3>
    </div>
    <hr>
    <div class="row mx-auto">
        <div class="container-mostrador-botoes">
            <p>Botao Edit</p>
            <edit-button />
        </div>
        <div class="container-mostrador-botoes">
            <p>Botao Delete </p>
            <delete-button />
        </div>
        <div class="container-mostrador-botoes">
            <p>Botao Fechar</p>
            <botao-fechar />
        </div>
        <div class="container-mostrador-botoes">
            <p>BotaoM</p>
            <botao-m>Clique aqui!</botao-m>
        </div>
        <div class="container-mostrador-botoes">
            <p>BotaoP</p>
            <botao-p />
        </div>
        <div class="container-mostrador-botoes">
            <p>BotaoVerde</p>
            <botao-verde>Salvar</botao-verde>
        </div>
    </div>

    <hr>

    <div class="row">
        <h3 class="mx-auto mt-2">Inputs</h3>

        <div class="container-mostrador-inputs">
            <p>Input Buscar</p>
            <input-buscar />
        </div>

        <div class="container-mostrador-inputs">
            <p>Input Boletos</p>
            <input-boleto @click="validaBoleto" />
        </div>

    </div>
    <hr>
    <div class="row">
        <h3 class="w-100 mt-2">Icones</h3>

        <div class="container-mostrador-icones">
            <p>Lupa Busca</p>
            <img src="../../assets/images/svg/lupa-busca.svg" />
        </div>

        <div class="container-mostrador-icones">
            <p>Lata de lixo excluir</p>
            <img src="../../assets/images/svg/lata-lixo-excluir.svg" />
        </div>

        <div class="container-mostrador-icones">
            <p>Lapis Editar</p>
            <img src="../../assets/images/svg/lapis-editar.svg" />
        </div>

        <div class="container-mostrador-icones">
            <p>Seta para baixo</p>
            <img src="../../assets/images/svg/seta-baixo.svg" />
        </div>

        <div class="container-mostrador-icones">
            <p>Icone imagem</p>
            <img src="../../assets/images/svg/icone-imagem.svg" />
        </div>

        <div class="container-mostrador-icones">
            <p>Olho de visualização</p>
            <img src="../../assets/images/svg/olho-visualizacao.svg" />
        </div>

        <div class="container-mostrador-icones">
            <p>Icone Menu</p>
            <img src="../../assets/images/svg/icone-menu.svg" />
        </div>

        <div class="container-mostrador-icones">
            <p>Icone Paginas</p>
            <img src="../../assets/images/svg/icone-paginas.svg" />
        </div>

        <div class="container-mostrador-icones">
            <p>Icone Agentes</p>
            <img src="../../assets/images/svg/icone-agentes.svg" />
        </div>

        <div class="container-mostrador-icones">
            <p>Icone Blog</p>
            <img src="../../assets/images/svg/icone-blog.svg" />
        </div>

        <div class="container-mostrador-icones">
            <p>Icone Rodapé</p>
            <img src="../../assets/images/svg/icone-rodape.svg" />
        </div>

        <div class="container-mostrador-icones">
            <p>Icone Ferramentas</p>
            <img src="../../assets/images/svg/icone-ferramentas.svg" />
        </div>

    </div>

  <hr>
  
</div>
</template>


<script>
import EditButton from '@/components/botoes/EditButton.vue';
import DeleteButton from '@/components/botoes/DeleteButton.vue';
import BotaoFechar from '@/components/botoes/BotaoFechar.vue';

import BotaoM from '@/components/botoes/BotaoM.vue';
import BotaoP from '@/components/botoes/BotaoP.vue';
import BotaoVerde from '@/components/botoes/BotaoVerde.vue';

import InputBuscar from '@/components/inputs/InputBuscar.vue';
import InputBoleto from '@/components/inputs/InputBoleto.vue';

export default {
  name: 'BlogAdminPage',
  components: {
    EditButton,
    DeleteButton,
    BotaoFechar,
    BotaoM,
    BotaoP,
    BotaoVerde,
    InputBuscar,
    InputBoleto,
  },
  methods: {
    validaBoleto: function(err, valid) {
        if(err) {
            alert(err);
            console.log('errou?', err.message);
        } else {
            console.log('validou?:', valid);
            if(!valid) {
                alert('Boleto inválido!');
            } else {
                alert('Boleto válido!');
            }
        }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.page-container {
  border: 1px solid gray;
  background-color: #fff;
  /* box-shadow: 9px 11px 9px -9px rgba(0,0,0,1); */
  margin-top: 10px;
  margin-bottom: 20px;
  min-height: 100%;
}

.container-mostrador-botoes {
    margin: auto;
    /* border: 1px solid grey; */
}

.container-mostrador-icones {
    margin: 4px auto;
    border: 1px solid black;
    padding: 5px;

}

.container-mostrador-inputs {
    margin: 4px auto;
    border: 1px solid black;
    padding: 5px;
    background-color: var(--cinza-claro-omni);
}

h3 {
    width: 100%;
    text-align: center;
    margin: 0 auto;
}
</style>
<template>
  <b-container fluid>
    <!-- User Interface controls -->
    <b-row>

      <b-col lg="2" class="mt-1 mb-0">
        <h5 class="mt-2 mb-0"><b>Setores</b></h5>
      </b-col>

      <b-col lg="6" class="my-1">
        <input-buscar :value="filter" @change="changeFilter"/>
      </b-col>

      <b-col lg="4" class="my-1 pr-1">
        <botao-criar class="float-right" @click="exibeModalSetores()" >+ Criar novo setor</botao-criar>
      </b-col>

    </b-row>
    
    <b-row>
    <!-- Main table element -->
      <b-table
        class="bg-white"
        :tbody-tr-class="classTd"
        data-cy="tableSetores"
        hover
        show-empty
        small
        stacked="md"
        :items="listaSetores"
        :fields="fields"
        :filter="filter"
        :filterIncludedFields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      >

        <template v-slot:cell(url)="row">
            <p class="text-wrap text-break" >{{ row.item.url }}</p>
        </template>
        
        <template v-slot:cell(actions)="row">
          <div style="width: 55px;" class="float-right">
            <botao-edit-tabela class="mx-1 float-right" @click="editarSetor(row.item.id)" />
            <botao-delete-tabela class="mx-1 float-right" @click="excluirSetor(row.item.id)" />
          </div>
        </template>

        <template v-slot:row-details="row">
          <b-card>
            <ul>
              <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
            </ul>
          </b-card>
        </template>
      </b-table>

    </b-row>

    <modal-setores ref="modal_setores" v-model="modalSetoresShow"/>
  </b-container>
</template>

<script>
import BotaoDeleteTabela from '@/components/botoes/BotaoDeleteTabela.vue';
import BotaoEditTabela from '@/components/botoes/BotaoEditTabela.vue';
import BotaoCriar from '@/components/botoes/BotaoCriar.vue';
import InputBuscar from '@/components/inputs/InputBuscar.vue';
import ModalSetores from '@/components/admin/modals/ModalSetores.vue';
import { mapActions, mapGetters  } from 'vuex';
import axios from 'axios';
import { abreCaixaConfirmacao } from '@/assets/js/CaixaConfirmacao'


  export default {
    name: 'TabelaSetores',
    components: {
        BotaoDeleteTabela,
        BotaoEditTabela,
        InputBuscar,
        ModalSetores,
        BotaoCriar,
    },
    data() {
      return {
        classTd: 'row-sem-borda',
        modalSetoresShow: false,
        fields: [
          { key: 'nome', label: 'Setor' },
          { key: 'url', label: 'Url' },
          { key: 'ordenacao', label: 'Ordem', sortable: true, sortDirection: 'asc' },
          { key: 'actions', label: ''}
        ],
        totalRows: 1,
        currentPage: 1,
        sortBy: 'ordenacao',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: ['nome'],
      }
    },
    computed: {
      ...mapGetters({
          listaSetores: 'setoresMenu/setores',
      }),
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    mounted() {
      // Set the initial number of items
      this.getSetores().then(()=> this.totalRows = this.listaSetores? this.listaSetores.length: 1);
    },
    methods: {
      ...mapActions({
        getSetores: 'setoresMenu/getSetores'
      }),
      // Antigo botao info
      // info(item, index) {
      //   this.infoModal.title = `Row index: ${index}`
      //   this.infoModal.content = JSON.stringify(item, null, 2)
      // },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      exibeModalSetores: function() {
        console.log('exibeModalSetores')
        this.$refs.modal_setores.limpa();
        this.$refs.modal_setores.show();
        this.modalSetoresShow = true;
      },
      editarSetor: function(id) {
        console.log('id',id);
        this.$refs.modal_setores.limpa();
        this.$refs.modal_setores.idSetor = id;
        this.$refs.modal_setores.show();
      },
      excluirSetor: function(id) {
        abreCaixaConfirmacao(this.$bvModal, "Deseja realmente excluir esse setor?")
          .then( resposta => {
            if(resposta === true) {
              axios.delete(`/api/setores/${id}`)
                .then( (response) => {
                  console.log(response);
                  this.getSetores().then(()=> this.totalRows = this.listaSetores? this.listaSetores.length: 1);
                })
                .catch( err => {
                  console.log("erro postagem rapida", err.response.data)
                });
            }
          })
          .catch(()=> {
            console.log("erro na exclusão");
          });
      },
      changeFilter: function(valor) {
        this.filter = valor;
      },
    },
    
  }
</script>

<style>

</style>
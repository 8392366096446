<template>
    <div class="row my-4">

        <b-container fluid class="p-0">
            <b-row>
                <b-col md="6" class="pr-4">
                    <div class="caixa-input mb-3">
                        <input type="file" class="input py-2" 
                               accept="image/*,.pdf,.csv,.xls,.xlsm,.xlsx,.xlsv" 
                               multiple @change="uploadArquivos"
                               />
                    </div>
                </b-col>

                <b-col md="6" class="pl-4">
                    <b-input-group class="caixa-input mb-3">
                        <b-form-input type="text" v-model="nomeArquivoBusca" @change="reiniciarQtdArquivos" placeholder="Buscar por arquivo" class="input-busca p-0"></b-form-input>

                        <b-input-group-append style="margin-left:-40px !important;">
                            <b-input-group-text style="background-color: transparent;border:none;border-radius:unset;">
                                <b-icon icon="search" />
                            </b-input-group-text>
                        </b-input-group-append>

                    </b-input-group>
                </b-col>
            </b-row>
        </b-container>

        <b-container fluid class="bv-example-row px-1">

            <b-row><b-col><p class="m-0" v-if="listOfFiles.length > 1">{{listArquivos.length}} arquivos encontrados</p></b-col></b-row>

            <b-row cols="5">
                <b-col v-if="listOfFiles.length < 1" cols="12" class="pt-5"><b>Nenhum arquivo encontrado</b></b-col>
                <b-col v-else v-for="arquivo in listOfFiles" :key="arquivo" class="my-3">

                    <b-card style="max-width: 45rem;height: 21.5rem;">

                        <b-col class="mb-1 p-0 d-flex justify-content-end">
                            <a :href="path + arquivo" target="_blank" class="mr-2">Acessar</a>
                            <ButtonRemoveSvg @click="showModalConfirm(arquivo)" />
                        </b-col>

                        <iframe v-if="arquivo" :src="path + arquivo" loading="lazy" frameborder="0" allow="accelerometer" />

                        <b-input-group size="sm">
                            <b-form-input readonly
                                          :id="arquivo"
                                          :value="path + arquivo">
                            </b-form-input>

                            <b-input-group-append>
                                <b-button size="sm"
                                          v-b-tooltip.click.blur.bottomleft="'URL Copiado!'"
                                          variant="secondary"
                                          @click="copy(arquivo)">
                                    <b-icon icon="files" size="sm" aria-hidden="true"></b-icon>
                                </b-button>
                            </b-input-group-append>

                        </b-input-group>

                    </b-card>
                </b-col>
            </b-row>

            <div v-if="mostrarBtnVerMais" class="w-100 text-center py-4">
                <botao-verde @click="verMaisArquivos">Ver mais +</botao-verde>
            </div>

        </b-container>

        <b-modal ref="modalConfirm" static centered hide-backdrop hide-footer hide-header>
            <div class="d-block text-center">
                <p>Excluir este arquivo?</p>
            </div>
            <div class="text-center">
                <b-button @click="hideModalConfirm" size="sm" class="mx-2">Cancelar</b-button>
                <b-button @click="removerArquivo" size="sm" variant="success" class="mx-2">Confirmar</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import axios from "axios";
    import ButtonRemoveSvg from '@/components/botoes/ButtonRemoveSvg.vue'
    import BotaoVerde from '@/components/botoes/BotaoVerde.vue'
    import { validarArquivoExtensao } from '@/assets/js/SharkUtils.js'

    export default {
        components: {
            ButtonRemoveSvg,
            BotaoVerde
        },
        data: function () {
            return {
                path: '/storage/arquivos/',
                arquivoNome: null,
                nomeArquivoBusca: '',
                listArquivos: [],
                mostrarArquivos: 50
            }
        },
        created() {
            this.getArquivos();
        },
        computed: {
            listOfFiles: function () {
                if (this.nomeArquivoBusca) {
                    return this.listArquivos
                        .filter(x => x.toLowerCase().includes(this.nomeArquivoBusca.toLowerCase()))
                        .slice(0, this.mostrarArquivos);
                }
                return this.listArquivos.slice(0, this.mostrarArquivos);
            },
            mostrarBtnVerMais: function () {
                if ((this.listOfFiles.length > 0 && this.diferencaQtdeArquivos) || (this.diferencaQtdeArquivos))
                {
                    if (this.nomeArquivoBusca && !this.diferencaQtdeArquivos) {
                        return true;
                    } else {
                        return false;
                    }
                }
                return true;
            },
            diferencaQtdeArquivos: function () {
                let diferenca = (this.mostrarArquivos - this.listOfFiles.length);
                if (diferenca > 0) {
                    return true;
                }
                return false;
            }
            
        },
        methods: {
            uploadArquivos(event) {
                let listaArquivos = event.target.files;
                let formData = new FormData();

                listaArquivos.forEach((arquivo, index) => {
                    if (validarArquivoExtensao(arquivo.name)) {
                        formData.append('arquivos', listaArquivos[index]);
                    }
                });

                axios.post('/api/discovirtual/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(res => {
                    this.getArquivos();
                    alert('Todos os arquivos foram carregados com sucesso!');
                    console.log(res);
                }).catch(err => { console.log(err); })

            },
            getArquivos() {
                axios.get('/api/discovirtual')
                    .then(res => {
                        this.listArquivos = res.data;
                    })
                    .catch(err => { console.log(err.data); })
            },
            copy(arquivo) {
                let url = document.getElementById(arquivo);
                url.select();
                url.setSelectionRange(0, 99999);
                document.execCommand('copy');
            },
            removerArquivo() {
                this.hideModalConfirm();
                axios.delete('/api/discovirtual/' + this.arquivoNome)
                    .then(res => {
                        this.getArquivos();
                        console.log(res);
                    }).catch(err => { console.log(err); })
            },
            showModalConfirm: function (name) {
                this.arquivoNome = name;
                this.$refs['modalConfirm'].show();
            },
            hideModalConfirm: function () {
                this.$refs['modalConfirm'].hide();
            },
            verMaisArquivos: function () {
                this.mostrarArquivos += 50;
            },
            reiniciarQtdArquivos: function () {
                this.mostrarArquivos = 50;
            }
        }
    }
</script>

<style scoped>
    .caixa-input {
        background-color: white;
        padding: 15px 30%;
    }
    .caixa-input > .form-control {
        background-color:transparent !important;
    }

    .input {
        width: 100%;
        height:40px;
        background-color: var(--verde-alerta-omni);
        color: white;
    }

        .input::-webkit-file-upload-button {
            visibility: hidden;
            width: 0px;
            margin: 0px;
            padding: 0px;
        }

    .input-busca, .input-busca:focus {
        width: 30%;
        height: 40px;
        font-weight: 500;
        background-color: transparent !important;
        color: var(--laranja-omni);
        outline: 0;
        border-width: 0 0 2px;
        border-color: var(--laranja-omni);
        box-shadow: none !important;
    }

    ::placeholder {
        color: var(--laranja-omni) !important;
    }

    :-webkit-input-placeholder, :-moz-placeholder, ::-moz-placeholder, :-ms-input-placeholder, ::-ms-input-placeholder {
        color: var(--laranja-omni) !important;
    }

    .card {
        border: none !important;
        border-radius: unset !important;
    }

    .card-body {
        padding: 0.75rem !important;
    }

    .form-control, .form-control:disabled {
        background-clip: unset !important;
        border-radius: unset !important;
        background-color: var(--cinza-omni) !important;
    }

    iframe {
        width: 100%;
        height: 260px;
        border: none !important;
    }

    .botao-fechar {
        margin-left: 70% !important;
    }
</style>

<template>
    <b-modal 
        v-model="exibe" 
        body-class="body-modal-agentes" 
        header-class="header-modal-agentes" 
        footer-class="footer-modal-agentes" 
        size="lg"
    >
        
        <template v-slot:default="{ cancel, ok }">
            <div class="container">

                <div class="row pb-2">
                    <h3> {{ idAgente > 0? 'Editar agente': 'Novo agente'}} </h3>
                </div>

                <div class="row">

                    <div class="col-6">
                        <div class="container">

                            <div class="row py-2">
                                <label for="nome-input-agentes">Nome</label>
                                <input type="text" v-model="nome" name="nome" id="nome-input-agentes" class="form-input-agentes" >
                            </div>

                            <div class="row py-2">
                                <label for="razao-social-input-agentes"> Razão Social </label>
                                <input type="text" v-model="razao_social" name="razao_social" id="razao-social-input-agentes" class="form-input-agentes" >
                            </div>

                            <div class="row py-2">
                                <label for="cnpj-input-agentes">CNPJ</label>
                                <input type="text" v-model="cnpj" name="cnpj" id="cnpj-input-agentes" class="form-input-agentes" >
                            </div>

                            <div class="row pt-2 input-linhas-rodape">
                                <label>Imagem Principal </label>
                            </div>
                            <div class="row pb-2 input-linhas-rodape">
                                <input-imagens ref="inputImagensRef" @upload="uploadImagem" />
                            </div>
                            <div class="row" v-if="url_imagem? url_imagem.length > 0: false">
                                <div class="container-img-banner-padrao">
                                    <img :src="url_imagem" />
                                </div>
                            </div>
                            <div class="row" v-else-if="url_temp? url_temp.length > 0: false">
                                <div class="container-img-banner-padrao">
                                    <img :src="url_temp" />
                                </div>
                            </div>

                            <div class="row pt-2">
                                <p>Serviços</p>
                            </div>
                            <div class="row row-modal-servicos" v-for="(servico, i) in servicosDisponiveis" v-bind:key="i">
                                <input type="checkbox" :id="i" :value="servico.id" v-model="servicos" v-bind:key="i">
                                <label v-bind:key="i+'l'" :for="i">{{servico.nome}}</label>
                            </div>

                            <div class="row pt-2">
                                <p>Área de atuação</p>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    
                                    <div class="container-cidades-atuacao-scroll">
                                        <div v-for="(cidade, i) in cidades_atuacao" v-bind:key="i">
                                            <span> {{ cidade.sigla_estado + '-' + cidade.name }} </span>
                                            <botao-delete-tabela @click="excluiCidadeAtuacao(i)" class="float-right"/>
                                        </div>
                                        <p v-if="cidades_atuacao? cidades_atuacao.length == 0: true"> Nenhuma cidade na área de atuação</p>
                                    </div>

                                </div>
                            </div>
                            <div class="row py-2">
                                <div class="col-3 px-0">

                                    <label for="select-estados-agentes">Estado</label>
                                    <select v-model="cod_estado_ibge_atuacao" name="selectEstado" @change="alterouEstadoAtuacao" id="select-estados-agentes" class="select-input-modal">
                                        <option disabled :value="0">Estados</option>
                                        <option v-for="(estado, i) in estadosDisponiveis" v-bind:key="i" :value="estado.id">{{ estado.sigla }}</option> 
                                    </select>

                                </div>
                                <div class="col-9 pr-0">

                                    <label for="select-cidades-agentes">Cidade</label>
                                    <select v-model="cod_cidade_ibge_atuacao" name="selectCidade" id="select-cidades-agentes" class="select-input-modal">
                                        <option disabled :value="0">Cidades</option>
                                        <option v-for="(cidadeDisp, i) in cidadesDisponiveisAtuacao" v-bind:key="i" :value="cidadeDisp.id">{{ cidadeDisp.name }}</option> 
                                    </select>

                                </div>
                            </div>
                            <div class="row">
                                <botao-criar 
                                    class="float-right"
                                    :disabled="!(cod_cidade_ibge_atuacao > 0)"
                                    @click="inserirCidadeAtuacao(cod_cidade_ibge_atuacao, cod_estado_ibge_atuacao)" 
                                >
                                    + Adicionar cidade
                                </botao-criar>
                            </div>

                        </div>
                    </div>


                    <div class="col-6">
                        <div class="container">

                            <div class="row py-2">
                                <label for="cep-input-agentes">CEP</label>
                                <input type="text" v-model="cep" name="cep" id="cep-input-agentes" class="form-input-agentes" >
                            </div>

                            <div class="row py-2">
                                <label for="endereco-input-agentes">Endereço</label>
                                <input type="text" v-model="endereco" name="endereco" id="endereco-input-agentes" class="form-input-agentes" >
                            </div>
                            
                            <div class="row py-2">
                                <label for="endereco-input-agentes">Bairro</label>
                                <input type="text" v-model="bairro" name="bairro" id="bairro-input-agentes" class="form-input-agentes" >
                            </div>

                            <div class="row py-2">
                                <label for="complemento-input-agentes">Complemento</label>
                                <input type="text" v-model="complemento" name="complemento" id="complemento-input-agentes" class="form-input-agentes" >
                            </div>

                            <div class="row py-2">
                                <div class="col-3 px-0">
                                    <label for="select-estados-agentes">Estado</label>
                                    <select v-model="cod_estado_ibge" name="selectEstado" id="select-estados-agentes" class="select-input-modal">
                                        <option v-if="!cod_estado_ibge" disabled :value="0">Estados</option>
                                        <option hidden selected :value="estadoSelecionado.id">{{estadoSelecionado.sigla}}</option>
                                        <option v-for="(estado, i) in estadosDisponiveis" v-bind:key="i" :value="estado.id">{{ estado.sigla }}</option>
                                    </select>

                                </div>
                                <div class="col-9 pr-0">
                                    <label for="select-cidades-agentes">Cidade</label>
                                    <select v-model="cod_cidade_ibge" name="selectCidade" id="select-cidades-agentes" class="select-input-modal">
                                        <option v-if="!cod_cidade_ibge" disabled :value="''">Cidades</option>
                                        <option hidden selected :value="cidadeSelecionaId">{{estadoSelecionado.cidade}}</option>
                                        <option v-for="(cidadeDisp, i) in cidadesDisponiveis" v-bind:key="i" :value="cidadeDisp.id">{{ cidadeDisp.name }}</option>
                                    </select>

                                </div>
                            </div>

                            <div class="row py-2">
                                <label for="telefone-input-agentes">Telefone</label>
                                <input type="text" v-model="telefone" name="telefone" id="telefone-input-agentes" class="form-input-agentes" >
                            </div>

                            <div class="row py-2">
                                <label for="whatsapp-input-agentes">WhatsApp</label>
                                <input type="text" v-model="whatsapp" name="whatsapp" id="whatsapp-input-agentes" class="form-input-agentes" >
                            </div>
                            
                            <div class="row py-2">
                                <label for="email-input-agentes">Email</label>
                                <input type="email" v-model="email" name="email" id="email-input-agentes" class="form-input-agentes" >
                            </div>

                            <div class="row">
                                <div class="col-12 px-0">
                                    <label>Conteúdo</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 px-0 coluna-editor-ck-modal-bloco-padrao">
                                    <vue-editor-custom-c-k ref="editorConteudo" :toolbarConfig="toolbarArray"/>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div class="row py-2">
                    <div class="botoes-float-right-container">
                        <botao-verde class="botoes-float-right" @click="salvar(ok)" > Salvar </botao-verde>
                        <botao-branco class="botoes-float-right" @click="cancel()" > Cancelar </botao-branco>
                    </div>
                </div>
                
            </div>
        </template>

        <template v-slot:modal-footer>
            <div class="mr-5">
                
            </div>
        </template>
    </b-modal>
</template>

<script>
import BotaoVerde from '@/components/botoes/BotaoVerde.vue';
import BotaoBranco from '@/components/botoes/BotaoBranco.vue';
import BotaoDeleteTabela from '@/components/botoes/BotaoDeleteTabela.vue';
import BotaoCriar from '@/components/botoes/BotaoCriar.vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';

import EstadosCidadesServices from '@/assets/js/EstadosCidadesServices'
import InputImagens from '@/components/inputs/InputImagens.vue';
import VueEditorCustomCK from '@/components/blog-components/VueEditorCustomCK.vue'

import GeocodeUtils from '@/assets/js/geocode/GeocodeUtils';
import { chaveGeocoding } from '@/config/googleConfig';
import { loadGmapApi, gmapApi } from "vue2-google-maps";

export default {
    name: 'ModalAgentes',
    components: {
        BotaoVerde,
        BotaoBranco,
        InputImagens,
        VueEditorCustomCK,
        BotaoDeleteTabela,
        BotaoCriar,
    },
    props: {
        toolbarArray: {
            type: Array,
            default: () => [ 'bold', 'italic', 'heading', '|', 'link', 'alignment', 'bulletedList', 'numberedList', 'fontSize', 'fontColor' ],
        }
    },
    data: function() {
        return {
            idAgente: {
                type: Number,
                default: 0
            },
            nome: '',
            razao_social: '',
            cnpj: '',
            servicos: [],
            cep: '',
            endereco: '',
            bairro: '',
            complemento: '',
            telefone: '',
            email: '',
            cod_cidade_ibge: 0,
            cod_estado_ibge: 0,
            whatsapp: '',
            conteudo: '',
            url_imagem: '',
            cidades_atuacao: [],

            estadoSelecionado: {
                cidade: '',
                sigla: '',
                id: 0,
            },

            cod_estado_ibge_atuacao: 0,
            cod_cidade_ibge_atuacao: 0,
            url_temp: '',
            imagem: null,
            exibe: false,
            cepAntigo: '',
            geocoder: null,
        }
    },
    computed: {
        ...mapGetters({
            agentes: 'agentes/agentes',
            servicosDisponiveis: 'servicos/servicos',
        }),
        estadosDisponiveis() {
            return EstadosCidadesServices.estadosDisponiveis();
        },
        cidadesDisponiveis() {
            return EstadosCidadesServices.cidadesDisponiveis(this.cod_estado_ibge);
        },
        cidadesDisponiveisAtuacao() {
            let cidadesJaCadastradas = this.cidades_atuacao.map(cidade => cidade.id);
            return EstadosCidadesServices.cidadesDisponiveis(this.cod_estado_ibge_atuacao).filter(cidade => !cidadesJaCadastradas.includes(cidade.id));
        },
        estado() {
            const lEstado = this.estadosDisponiveis.find(estado => estado.id == this.cod_estado_ibge);
            if(lEstado) {
                return lEstado.sigla;
            } else {
                return '';
            }
        },
        cidade() {
            const lCidade = this.cidadesDisponiveis.find(cidade => cidade.id == this.cod_cidade_ibge);
            if(lCidade) {
                return lCidade.name;
            } else {
                return '';
            }
        },
        cidadeSelecionaId() {
            const cidadeSelecionada = this.cidadesDisponiveis.find(cidade => cidade.name.toUpperCase() == this.estadoSelecionado.cidade.toUpperCase());
            if (cidadeSelecionada) {
                // this.cod_cidade_ibge = cidadeSelecionada.id;
                return cidadeSelecionada.id;
            } else {
                return '';
            }
        },
        google: gmapApi,
    },
    watch: {
        // whenever question changes, this function will run
        google: function () {
            this.salvaDadosGoogleCep(this.cep)
        }
    },
    methods: {
        ...mapActions({
            getAgentes: 'agentes/getAgentes'
        }),
        uploadImagem: function(imagem, url_temp) {
            this.imagem = imagem;
            this.url_temp = url_temp;
            this.url_imagem = '';
        },
        salvarImagem: async function() {
            const formData = new FormData();
            if (this.imagem) {
                formData.append('arquivo', this.imagem, this.imagem.name)
                return axios.post('/api/imagens/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            } else {
                return;
            }
        },
        show: function() {
            if(this.agentes && this.idAgente > 0) {
                let agente = this.agentes.filter(item => item.id == this.idAgente);
                if(agente) {
                    if(agente.length > 0) {
                        this.nome = agente[0].nome;
                        this.razao_social = agente[0].razao_social;
                        this.cnpj = agente[0].cnpj;
                        this.servicos = agente[0].servicos;
                        this.cep = agente[0].cep;
                        // Guarda o cep antigo para saber se foi alterado.
                        this.cepAntigo = this.cep;
                        this.endereco = agente[0].endereco;
                        this.bairro = agente[0].bairro;
                        this.complemento = agente[0].complemento;
                        this.telefone = agente[0].telefone;
                        this.email = agente[0].email;
                        this.cod_cidade_ibge = agente[0].cod_cidade_ibge;
                        this.cod_estado_ibge = agente[0].cod_estado_ibge;
                        this.whatsapp = agente[0].whatsapp;
                        this.conteudo = agente[0].conteudo;
                        this.setContentEditor(this.conteudo);
                        this.url_imagem = agente[0].url_imagem;

                        this.estadoSelecionado.sigla = agente[0].estado;
                        this.estadoSelecionado.cidade = agente[0].cidade;

                        this.cidades_atuacao = agente[0].cidades_atuacao
                            .map(cidad => EstadosCidadesServices.dadosCidade(cidad.cod_cidade_ibge))
                            .filter(cidade => cidade != null);
                    }
                }
            }
            this.exibe = true;
        },
        hide: function() {
            this.limpa();
            this.exibe = false;
        },
        salvar: function(ok) {
            // Atualiza variavel do conteudo antes de fechar o modal para nao vir undefined
            this.getContentEditor();
            // Metodo Ok que fecha o modal
            ok();
            // Primeiro salva a imagem
            this.salvarImagem()
                .then( response => {
                    if(response) {
                        this.url_imagem = response.data.url;
                    }
                    // Depois salva os dados na tabela
                    this.salvarDados();
                })
                .catch(err => {
                    console.log('erro no envio da imagem');
                    console.log(err);
                    console.log(err.response);
                });

        },
        salvarDados: function() {
            if(this.cep && this.cep != this.cepAntigo) {
                this.salvaDadosGoogleCep(this.cep);
            }

            let registro = {
                "nome": this.nome,
                "razao_social": this.razao_social,
                "cnpj": this.cnpj,
                "servicos": this.servicos,
                "cep": this.cep,
                "endereco": this.endereco,
                "bairro": this.bairro,
                "complemento": this.complemento,
                "estado": this.estado,
                "cidade": this.cidade.toUpperCase(),
                "telefone": this.telefone,
                "email": this.email,
                "cod_cidade_ibge": parseInt(this.cod_cidade_ibge),
                "cod_estado_ibge": parseInt(this.cod_estado_ibge),
                "whatsapp": this.whatsapp,
                "conteudo": this.conteudo,
                "url_imagem": this.url_imagem,
                // Converte para o formato do banco
                "cidades_atuacao": this.cidades_atuacao.map(cidad => ({
                    id_agente: this.idAgente? this.idAgente: 0,
                    cod_cidade_ibge: cidad.id,
                    cod_estado_ibge: cidad.state_id,
                })),
            };
            let requisicao = null;
            if(this.idAgente > 0) {
                registro.Id = this.idAgente;
                requisicao = axios.put("/api/agentes", registro);
            } else {
                requisicao = axios.post("/api/agentes", registro);
            }
			requisicao
				.then( (response) => {
					console.log('response salvar dados', response);
                    // Limpa form
                    this.limpa();
                    // atualiza a lista
                    this.getAgentes();
				})
				.catch( (err) => {
                    console.log("erro cadastro agentes", err.message)
                    if(err.response.status == 401 || err.response.status == 403) {
                        this.$router.push('/admin').catch(()=>{});
                    }
                    this.getAgentes();
                });
            
        },
        salvaDadosGoogleCep: function(pCep) {
            // nova versao
            if(!this.google) {
                loadGmapApi({
                    key: chaveGeocoding,
                    libraries: "places geocoding ",
                    language: "pt"
                });
                return
            }
            this.geocoder = new this.google.maps.Geocoder()

            this.geocoder.geocode({
                address: pCep,
                region: 'br'
            },
            (resposta, status)=> {
                if(status != 'OK') {
                    console.log('resposta', resposta, status);
                    return;
                }
                const dadosCep = GeocodeUtils.handleRespostaApiGoogleJs(resposta, pCep)
                console.log('dadosCep', dadosCep)
                if(!dadosCep) {
                    return;
                }
                axios.post("/api/dados-cep", dadosCep)
                    .then(()=> console.log('enviando dados do CEP do agente'))
                    .catch((err)=> console.log('erro no envio dos dados do CEP do agente', err))
            })

        },
        limpa: function() {
            this.idAgente = 0;
            this.nome = '';
            this.razao_social = '';
            this.cnpj = '';
            this.servicos = [];
            this.cep = '';
            this.endereco = '';
            this.bairro = '';
            this.complemento = '';
            this.telefone = '';
            this.email = '';
            this.cod_cidade_ibge = 0;
            this.cod_estado_ibge = 0;
            this.whatsapp = '';
            this.conteudo = '';
            this.url_imagem = '';
            this.url_temp = '';
            this.cidades_atuacao = [];
            this.cod_estado_ibge_atuacao = 0;
            this.cod_cidade_ibge_atuacao = 0;
            this.imagem = null;
        },
        getContentEditor: function() {
            if(this.$refs.editorConteudo) {
                let conteudo = this.$refs.editorConteudo.getContent();
                this.conteudo = conteudo;
                return conteudo;
            }
            return this.conteudo;
        },
        setContentEditor: function(txt) {
            if(this.$refs.editorConteudo) {
                this.$refs.editorConteudo.setContent(txt);
                this.$refs.editorConteudo.prefill();
            }
            this.conteudo = txt;
        },
        inserirCidadeAtuacao: function(cod_cidade) {
            // Zera o codigo da cidade do input mas mantem o do estado por facilidade de inserção de cidades no mesmo estado
            this.cod_cidade_ibge_atuacao = 0;
            this.cidades_atuacao.push(EstadosCidadesServices.dadosCidade(cod_cidade));
        },
        excluiCidadeAtuacao: function(pIndex) {
            this.cidades_atuacao.splice(pIndex, 1);
        },
        alterouEstadoAtuacao: function() {
            this.cod_cidade_ibge_atuacao = 0;
        },
    },
    updated() {
        if(this.$refs.editorConteudo) {
            this.$refs.editorConteudo.setContent(this.conteudo? this.conteudo: '');
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.header-modal-agentes {
    border: white !important;
    padding-bottom: 0 !important;
}

.body-modal-agentes {
    padding-right: 50px !important;
    padding-left: 50px !important;
}

.footer-modal-agentes {
    border: white !important;
}

.form-input-agentes {
    width: 100%;
    border: none !important;
    border-bottom: 1px solid black !important;
    outline: none;
}

.botoes-float-right {
    float: right;
    margin-left: 20px;
}

.botoes-float-right-container {
    width: 100%;
    margin-top: 30px;
}

.row-modal-servicos input {
    margin: 5px 5px 0px 0px;
}

.select-input-modal {
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 1px solid black !important;
}

.container-cidades-atuacao-scroll {
    overflow-y: auto;
    min-height: 7px;
    max-height: 150px;
    padding-right: 4px;
}

</style>
export const abreCaixaConfirmacao = (modalBS, msg) => {
    return modalBS.msgBoxConfirm(msg, {
        title: 'Confirmando',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Sim',
        cancelTitle: 'Não',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
    });
}
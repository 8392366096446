<template>
    <button class="btn botao-verde-omni" v-on:click="$emit('click')">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'BotaoVerde',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    button.botao-verde-omni {
        background-color: var(--verde-alerta-omni);
        color: #fff;
        border-radius: 0px;
    }
</style>
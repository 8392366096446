<template>
  <div class="container">
    <div class="row ">
      <div class="col-6 ">
        <tabela-setores />
      </div>
      <div class="col-6 ">
        <tabela-sub-setores />
      </div>
    </div>
  </div>
</template>

<script>
import TabelaSubSetores from '@/components/admin/tabelas/TabelaSubSetores.vue';
import TabelaSetores from '@/components/admin/tabelas/TabelaSetores.vue';
import { mapGetters } from 'vuex';


export default {
  name: 'SetoresMenuPage',
  components: {
      TabelaSetores,
      TabelaSubSetores,
  },
  data: function() {
    return {
      
    }
  },
  computed: {
    ...mapGetters({
      setores: 'setoresMenu/setores',
      subSetores: 'setoresMenu/subSetores',
    })
  },
  methods: {

  },
  mounted() {
  }
}
</script>

<style scoped>


</style>

<template>
  <b-container fluid>
    <!-- User Interface controls -->
    <b-row>

      <b-col lg="3" class="mt-1 mb-0 px-0">
        <h5 class="mt-2 mb-0 h5-subsetores"><b>Sub Setores</b></h5>
      </b-col>

      <b-col lg="4" class="my-1 px-0">
        <input-buscar :value="filter" @change="changeFilter"/>
      </b-col>

      <b-col lg="5" class="my-1 pr-1">
        <botao-criar class="float-right" @click="exibeModal()">+ Criar novo subsetor</botao-criar>
      </b-col>

    </b-row>

    <b-row>
      <!-- Main table element -->
      <b-table
        class="bg-white"
        :tbody-tr-class="classTd"
        hover
        show-empty
        small
        stacked="md"
        :items="listaSubSetores"
        :fields="fields"
        :filter="filter"
        :filterIncludedFields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      >

        <template v-slot:cell(url)="row">
            <p class="text-wrap text-break" >{{ row.item.url }}</p>
        </template>

        <template v-slot:cell(setor)="row">
            {{ row.item.sub_setor_mestre > 0? getNomeSubSetorMestre(row.item.sub_setor_mestre): getNomeSetorMestre(row.item.setor_mestre) }}
        </template>
        
        <template v-slot:cell(actions)="row" class="col-4">

            <div style="width: 55px;" class="float-right">
              <botao-edit-tabela class="mx-1 float-right" @click="editar(row.item.id)" />
              <botao-delete-tabela class="mx-1 float-right" @click="excluir(row.item.id)" />
            </div>
        </template>

        <template v-slot:row-details="row">
          <b-card>
            <ul>
              <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
            </ul>
          </b-card>
        </template>
      </b-table>
    </b-row>

    <modal-sub-setores ref="modal_sub_setores" v-model="modalSetoresShow"/>
  </b-container>
</template>

<script>
import BotaoDeleteTabela from '@/components/botoes/BotaoDeleteTabela.vue';
import BotaoEditTabela from '@/components/botoes/BotaoEditTabela.vue';
import BotaoCriar from '@/components/botoes/BotaoCriar.vue';
import InputBuscar from '@/components/inputs/InputBuscar.vue';
import ModalSubSetores from '@/components/admin/modals/ModalSubSetores.vue';
import { mapActions, mapGetters  } from 'vuex';
import axios from 'axios';
import { abreCaixaConfirmacao } from '@/assets/js/CaixaConfirmacao'

  export default {
    name: 'TabelaSubSetores',
    components: {
        BotaoDeleteTabela,
        BotaoEditTabela,
        InputBuscar,
        ModalSubSetores,
        BotaoCriar,
    },
    data() {
      return {
        classTd: 'row-sem-borda',
        modalSetoresShow: false,
        fields: [
          { key: 'nome', label: 'SubSetor' },
          { key: 'setor', label: 'Setor Mestre' },
          { key: 'url', label: 'Url' },
          { key: 'ordenacao', label: 'Ordem', sortable: true, sortDirection: 'asc' },
          { key: 'actions', label: '' }
        ],
        totalRows: 1,
        currentPage: 1,
        sortBy: 'ordenacao',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: ['nome'],
      }
    },
    computed: {
      ...mapGetters({
          listaSubSetores: 'setoresMenu/subSetores',
          listaSetores: 'setoresMenu/setores',
      }),
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    mounted() {
      // Set the initial number of items
      this.getSubSetores();
    },
    methods: {
      ...mapActions({
        getSubSetores: 'setoresMenu/getSubSetores'
      }),
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      exibeModal: function() {
        this.$refs.modal_sub_setores.limpa();
        this.$refs.modal_sub_setores.show();
        this.modalSetoresShow = true;
      },
      editar: function(id) {
        this.$refs.modal_sub_setores.limpa();
        this.$refs.modal_sub_setores.idSubSetor = id;
        this.$refs.modal_sub_setores.show();
      },
      excluir: function(id) {
        abreCaixaConfirmacao(this.$bvModal, "Deseja realmente excluir esse subsetor?")
          .then( resposta => {
            if(resposta === true) {
              axios.delete(`/api/subsetores/${id}`)
                .then( () => {
                  this.getSubSetores();
                })
                .catch( err => {
                  console.log("erro postagem rapida", err.response.data)
                });
            }
          })
          .catch(()=> {
            console.log("erro na exclusão");
          });
      },
      changeFilter: function(valor) {
        this.filter = valor;
      },
      getNomeSetorMestre: function(idSetor) {
          let setorMestre = this.listaSetores? this.listaSetores.filter(setor => setor.id == idSetor): null;
          if(setorMestre) {
              if(setorMestre.length > 0) {
                  return setorMestre[0].nome;
              }
          }
          return '';
      },
      getNomeSubSetorMestre: function(pIdSubSetor) {
        let subSetorMestre = this.listaSubSetores? this.listaSubSetores.filter(subsetor => subsetor.id == pIdSubSetor): null;
        if(subSetorMestre) {
          if(subSetorMestre.length > 0) {
              return subSetorMestre[0].nome;
          }
        }
        return '';
      },
    },
    
  }
</script>

<style>

.h5-subsetores {
    font-size: 1.1rem;
}

</style>
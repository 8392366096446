<template>
    <b-modal 
        v-model="exibe" 
        body-class="body-modal-paginas" 
        header-class="header-modal-paginas" 
        footer-class="footer-modal-paginas"
        size="md" 
    >
        
        <template v-slot:default="{ cancel, ok }">
            <div class="container">
                <div class="row pb-2">
                    <h3> {{ idPagina > 0? 'Editar página': 'Nova página'}} </h3>
                </div>
                <div class="row py-2">
                    <label for="titulo-input-paginas">Título</label>
                    <input type="text" v-model="titulo" name="titulo" id="titulo-input-paginas" class="form-input-paginas">
                </div>

                <div class="row py-2">
                    <label for="select-input-paginas">Modelo de template</label>
                    <select :disabled="idPagina > 0" v-model="template" name="select" id="select-input-paginas" class="select-input-modal-paginas">
                        <option :value="''">Escolha um modelo</option>
                        <option v-for="(tipo, i) in tiposTemplate" v-bind:key="i" :value="tipo.chave">{{ tipo.valor }}</option>
                    </select>
                </div>

                <div class="row py-2" v-if="template=='PRODUTOS'">
                    <label>Produto relacionado</label>
                    <select v-model.number="id_produto" class="select-input-modal-paginas">
                        <option :value="0">Escolha um produto Omni</option>
                        <option v-for="(servico, i) in servicosDisponiveis"
                                v-bind:key="i"
                                :value="servico.id">
                            {{ servico.nome }}
                        </option>
                    </select>
                </div>

                <div class="row pt-2" v-if="template!=='HOME'">
                    <label for="url-input-link-menu"> Url da página </label>
                </div>

                <div class="row py-2" v-if="template!=='HOME'">
                    <span class="modal-paginas-base-url">{{ 'pagina/' }}</span>
                    <input type="text" v-model="url_setor" name="url" id="url-input-link-menu" class="form-input-url-paginas" placeholder="url-desejada">
                </div>

                <div class="row py-2" v-if="ehTemplateProduto">
                    <label for="email-input-paginas">E-mail do produto</label>
                    <input type="text" v-model="email_setor" name="email" id="email-input-paginas" class="form-input-paginas">
                </div>

                <div class="row py-2">
                    <div class="botoes-float-right-container">
                        <botao-verde class="botoes-float-right" @click="salvar(ok)"> Salvar </botao-verde>
                        <botao-branco class="botoes-float-right" @click="cancel()"> Cancelar </botao-branco>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:modal-footer>
            <div class="mr-5">
                
            </div>
        </template>
    </b-modal>
</template>

<script>
import BotaoVerde from '@/components/botoes/BotaoVerde.vue';
import BotaoBranco from '@/components/botoes/BotaoBranco.vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import { TIPOS_TEMPLATES } from '@/assets/js/dicionarioPaginas'

export default {
    name: 'ModalPaginas',
    components: {
        BotaoVerde,
        BotaoBranco,
    },
    props: {
       
    },
    data: function() {
        return {
            exibe: false,
            tiposTemplate: TIPOS_TEMPLATES,
            idPagina: {
                type: Number,
                default: 0
            },
            titulo: '',
            url_setor: '',
            template: '',
            email_setor: '',
            id_produto: 0,
        }
    },
    computed: {
        ...mapGetters({
            servicosDisponiveis: 'servicos/servicos',
            paginas: 'paginas/paginas',
        }),
        ehTemplateProduto() {
            if(this.template=='PRODUTOS')
                return true;
            else
                return false;
        },
    },
    methods: {
        ...mapActions({
            getPaginas: 'paginas/getPaginas'
        }),
        show: function(id = 0) {
            this.idPagina = id;
            if(this.paginas && this.idPagina > 0) {
                let pagina = this.paginas.filter(item => item.id == this.idPagina);
                if(pagina) {
                    if(pagina.length > 0) {
                        this.titulo = pagina[0].titulo;
                        this.url_setor = pagina[0].url_setor;
                        this.template = pagina[0].template;
                        this.email_setor = pagina[0].email_setor;
                        this.id_produto = pagina[0].id_produto;

                    }
                }
            }
            this.exibe = true;
        },
        hide: function() {
            this.limpa();
            this.exibe = false;
        },
        salvar: function(ok) {
            // Metodo Ok que fecha o modal
            ok();
            let registro = {
				"titulo": this.titulo,
				"url_setor": this.trataUrl(this.url_setor),
                "template": this.template,
                "email_setor": this.email_setor,
                "id_produto": this.id_produto,
            };
            let requisicao = null;
            if(this.idPagina > 0) {
                registro.Id = this.idPagina;
                requisicao = axios.put("/api/paginas", registro);
            } else {
                requisicao = axios.post("/api/paginas", registro);
            }
			requisicao
				.then( () => {
                    // atualiza a lista
                    this.getPaginas();
                    // Limpa form
                    this.limpa();
				})
				.catch( err => {
                    if(err.response.status == 401 || err.response.status == 403) {
                        this.$router.push('/admin').catch(()=>{});
                    }
					console.log("erro no cadastro da página", err.response.data)
				});
        },
        limpa: function() {
            this.titulo = '';
            this.url_setor = '';
            this.template = '';
            this.email_setor = '';
            this.idPagina = 0;
            this.id_produto = 0;
        },
        trataUrl: function(pUrl) {
            // Nao permite barra, no meio substitui por hifen
            // /produto/123/123 => produto-123-123
            let urlTratada = pUrl.replace(/\//g, "-");
            if(urlTratada.substring(0,1) === "-") {
                urlTratada = urlTratada.substring(1);
            }
            return urlTratada;
        },
        
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.header-modal-paginas {
    border: white !important;
    padding-bottom: 0 !important;
}

.body-modal-paginas {
    padding-right: 50px !important;
    padding-left: 50px !important;
}

.footer-modal-paginas {
    border: white !important;
}

.form-input-paginas {
    width: 100%;
    border: none !important;
    border-bottom: 1px solid black !important;
    outline: none;
}
.form-input-url-paginas {
    width: calc(100% - 60px);
    border: none !important;
    border-bottom: 1px solid black !important;
    outline: none;
}
.modal-paginas-base-url {
    width: 57px;
    display: inline-block;
}

.select-input-modal-paginas {
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 1px solid black !important;
}

.botoes-float-right {
    float: right;
    margin-left: 20px;
}

.botoes-float-right-container {
    width: 100%;
    margin-top: 30px;

}

</style>
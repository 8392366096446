<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-6"> 
                <tabela-blocos-rodape />
            </div>

            <div class="col-lg-6"> 
                <tabela-sociais-rodape />
            </div>

        </div>

    </div>
</template>

<script>
import TabelaBlocosRodape from '@/components/admin/tabelas/TabelaBlocosRodape.vue';
import TabelaSociaisRodape from '@/components/admin/tabelas/TabelaSociaisRodape.vue';


    export default {
        name: 'PainelRodape',
        components: {
            TabelaBlocosRodape,
            TabelaSociaisRodape,
        }
    }
</script>

<style scoped>


</style>
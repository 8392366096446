<template>
    <div class="modal fade" id="modalFerramentasPopup" tabindex="-1" role="dialog" aria-labelledby="modalFerramentasPopup" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content caixa_modal">
                <div>
                    <button class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body caixa_body">
                    <div>
                        <p id="modal_alterar">Alterar Popup</p>
                    </div>

                    <div>
                        Imagem Popup<br />
                        <div>
                            <input-imagem ref="inputRef" @upload="OnUploadedImage" class="input-popup"></input-imagem>
                        </div>
                        <p>Imagem atual:</p>
                        <img v-if="popupAtual.imagem" :src="'/storage/popup/' + popupAtual.imagem" style="height:200px;width:auto;">
                    </div>

                    <div>
                        Url de redirecionamento (link)
                        <div>
                            <input-text v-model="popup.url" class="input-popup"></input-text>
                        </div>
                        <p>Url atual: <a :href="'https://' + popupAtual.url" target="_blank">{{popupAtual.url}}</a></p>
                    </div>

                    <div>
                        <input class="position-static mr-2" type="checkbox" id="popupCheckbox" v-model="popup.ativo">
                        <label for="popupCheckbox">POPUP ATIVO</label>

                        <p v-if="popupAtual.ativo">Atualmente: <b style="color:green;">ATIVO</b></p>
                        <p v-if="!popupAtual.ativo">Atualmente: <b style="color:red;">DESATIVADO</b></p>
                    </div>

                    <div class="popup-botoes">
                        <botao-verde class="btn-md mx-3 btn_salvar" @click="salvarPopup">Salvar</botao-verde>
                        <button-fechar-branco class="btn-md" data-dismiss="modal" aria-label="Close">Fechar</button-fechar-branco>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import inputImagem from '@/components/inputs/InputImagens.vue'
    import inputText from '@/components/inputs/InputText.vue'
    import BotaoVerde from '@/components/botoes/BotaoVerde.vue'
    import ButtonFecharBranco from '@/components/botoes/ButtonFecharBranco.vue'

    export default {
        name: 'ModalFerramentasLogo',
        components: {
            BotaoVerde,
            ButtonFecharBranco,
            inputImagem,
            inputText
        },
        data() {
            return {
                popup: {
                    imagem: '',
                    url: '',
                    ativo: false,
                },
                popupAtual: {
                    imagem: '',
                    url: '',
                    ativo: '',
                }
            }
        },
        created() {
            this.getPopup();
        },
        methods: {
            OnUploadedImage(image) {
                if (image) {
                    this.popup.imagem = image;
                    console.log(this.popup.imagem);
                }
            },
            getPopup() {
                axios.get('/api/popup')
                    .then(res => {
                        console.log(res);
                        const RET_POPUP = res.data.dados;
                        this.popupAtual.imagem = RET_POPUP.imagem;
                        this.popupAtual.url = RET_POPUP.url !== 'null' ? RET_POPUP.url : '';
                        this.popupAtual.ativo = RET_POPUP.ativo;

                        this.popup.url = RET_POPUP.url !== 'null' ? RET_POPUP.url : '';
                        this.popup.ativo = RET_POPUP.ativo;
                    })
                    .catch(err => {
                        console.log(err.data);
                    })
            },
            salvarPopup() {
                if (this.popup.imagem !== '' || (this.popup.url !== this.popupAtual.url) || (this.popupAtual.ativo !== this.popup.ativo)) {
                    const FD = new FormData();
                    FD.append('imagem', this.popup.imagem);
                    FD.append('pUrl', this.popup.url);
                    FD.append('pPopupAtivo', this.popup.ativo);
                    console.log(FD);

                    axios.put('/api/popup/upload', FD, { headers: { 'Content-Type': 'multipart/form-data' } })
                        .then(res => {
                            if (res.status === 200) {
                                alert('Popup atualizado com sucesso!');
                                this.limparForm();
                                this.getPopup();
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                } else {
                    alert('Nenhum campo foi alterado.');
                }
                
            },
            limparForm: function () {
                this.$root.$emit('resetInputFile');
                this.popup.imagem = '';
                this.popup.url = this.popupAtual.url;
                this.popup.ativo = this.popupAtual.ativo;
            }
        }
    }
</script>

<style scoped>
    .caixa_modal {
        padding: 7px 14px;
        border: none !important;
        border-radius: unset !important;
    }

    .caixa_body {
        text-align: left;
        margin: 0 5%;
    }

    .caixa_body > div {
        margin: 5% 0;
    }

    .popup-botoes {
        text-align: right;
    }

    .btn_salvar {
        margin-left: 10px;
    }

    button.close:focus {
        outline: none;
    }

    #modal_alterar {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 2rem;
    }

    .input-popup {
        width: 100% !important;
    }

    #popupCheckbox {
        vertical-align: middle;
    }

    @media only screen and (max-width: 500px) {
        #input {
            width: 80% !important;
        }
    }
</style>
<template>
  <b-container fluid>
    <!-- User Interface controls -->
    <b-row>

      <b-col lg="4" class="mt-1 mb-0 pr-0">
        <h5 class="mt-2 mb-0 text-left"><b>Redes sociais</b></h5>
      </b-col>

      <b-col lg="4" class="my-1 px-0">
        <input-buscar :value="filter" @change="changeFilter"/>
      </b-col>

      <b-col lg="4" class="my-1 pr-1">
        <botao-criar class="float-right" @click="exibeModal()" >+ Criar novo bloco</botao-criar>
      </b-col>

    </b-row>

    <b-row>
      <!-- Main table element -->
      <b-table
        class="bg-white "
        :tbody-tr-class="classTd"
        hover
        show-empty
        small
        stacked="md"
        :items="dadosSociaisRodape"
        :fields="fields"
        :filter="filter"
        :filterIncludedFields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      >

        <template v-slot:empty>
          <h4>Sem conteúdo cadastrado</h4>
        </template>

        <template v-slot:emptyfiltered>
          <h4>Sem resultados</h4>
        </template>

        <template v-slot:cell(titulo)="row">
          <botao-detalhes-tabela class="mx-1 float-left" @click="row.toggleDetails" />
          <span class="float-left">{{ row.item.titulo }}</span>
        </template>
        
        <template v-slot:cell(actions)="row">
          <div style="width: 55px;" class="float-right">
            <botao-delete-tabela class="mx-1 float-right" @click="excluir(row.item.id)" />
            <botao-edit-tabela class="mx-1 float-right" @click="editar(row.item.id)" />
          </div>
        </template>

        <template v-slot:row-details="row">
          <b-card>
            
            <ul>
              <li>
                <a :href="row.item.url" target="__blank">{{ row.item.url}} </a> 
              </li>

              <li v-if="row.item.url_icone">
                <div class="container-icone-rede-social-tabela">
                  <img :src="row.item.url_icone" />
                </div>
              </li>

            </ul>

          </b-card>
        </template>
      </b-table>
    </b-row>

    <modal-sociais-rodape ref="modal_sociais_rodape" v-model="modalBlocosShow"/>
  </b-container>
</template>

<script>
import BotaoDeleteTabela from '@/components/botoes/BotaoDeleteTabela.vue';
import BotaoEditTabela from '@/components/botoes/BotaoEditTabela.vue';
import BotaoDetalhesTabela from '@/components/botoes/BotaoDetalhesTabela.vue';
import BotaoCriar from '@/components/botoes/BotaoCriar.vue';
import InputBuscar from '@/components/inputs/InputBuscar.vue';
import ModalSociaisRodape from '@/components/admin/modals/ModalSociaisRodape.vue';
import { mapActions, mapGetters  } from 'vuex';
import axios from 'axios';
import { abreCaixaConfirmacao } from '@/assets/js/CaixaConfirmacao'


  export default {
    name: 'TabelaSociaisRodape',
    components: {
        BotaoDeleteTabela,
        BotaoEditTabela,
        InputBuscar,
        ModalSociaisRodape,
        BotaoCriar,
        BotaoDetalhesTabela
    },
    data() {
      return {
        classTd: 'row-sem-borda',
        modalBlocosShow: false,
        fields: [
          { key: 'titulo', label: 'Título', sortable: true, sortDirection: 'asc' },
          { key: 'actions', label: '' },
        ],
        totalRows: 1,
        currentPage: 1,
        sortBy: 'titulo',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: ['titulo'],
      }
    },
    computed: {
      ...mapGetters({
          dadosSociaisRodape: 'dadosRodape/dadosSociaisRodape',
      }),
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    mounted() {
      // Set the initial number of items
      this.getDadosSociaisRodape().then(()=> this.totalRows = this.dadosSociaisRodape?this.dadosSociaisRodape.length: 1);
    },
    methods: {
      ...mapActions({
        getDadosSociaisRodape: 'dadosRodape/getDadosSociaisRodape'
      }),
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      exibeModal: function() {
        this.$refs.modal_sociais_rodape.limpa();
        this.$refs.modal_sociais_rodape.show();
        this.modalBlocosShow = true;
      },
      editar: function(id) {
        this.$refs.modal_sociais_rodape.limpa(id);
        this.$refs.modal_sociais_rodape.show(id);
      },
      excluir: function(id) {
        abreCaixaConfirmacao(this.$bvModal, "Deseja realmente excluir essa rede social?")
          .then( resposta => {
            if(resposta === true) {
              axios.delete(`/api/dados-sociais/${id}`)
                .then( (response) => {
                  console.log(response);
                  this.getDadosSociaisRodape().then(()=> this.totalRows = this.dadosSociaisRodape?this.dadosSociaisRodape.length: 1);
                })
                .catch( err => {
                  console.log("erro exclusao bloco rodapé", err.response.data)
                });
            }
          })
          .catch(()=> {
            console.log("erro na exclusão");
          });
      },
      changeFilter: function(valor) {
        this.filter = valor;
      },
    },
    
  }
</script>

<style>

.container-icone-rede-social-tabela {
    height: 40px;
    width: 40px;
}

    .container-icone-rede-social-tabela img{
        max-width: 100%;
        max-height: 100%;
        margin: auto;
        display: block;
    }

</style>
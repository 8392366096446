<template>
    <input :value="value" @input="$emit('input', $event.target.value)" class="input-text" />
</template>

<script>
    export default {
        name: 'InputText',
        props: ['value'],
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .container-input-text {
        color: transparent;
        position: relative;
    }

    .input-text {
/*        color: var(--cinza-omni);*/
        border: none;
        background: transparent;
        border-bottom: 1px solid black;
        margin-bottom: 3%;
        outline: none;
        height: 1.5rem;
        width: 100%;
    }
</style>
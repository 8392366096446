<template>
    <b-modal 
        v-model="exibe" 
        body-class="body-modal-blocos-rodape" 
        header-class="header-modal-blocos-rodape" 
        footer-class="footer-modal-blocos-rodape" 
        size="md"
        no-enforce-focus
    >
        
        <template v-slot:default="{ cancel, ok }">
            <div class="container">

                <div class="row pb-2">
                    <h3> {{ idBloco > 0? 'Editar bloco': 'Novo bloco de rodapé'}} </h3>
                </div>

                <div class="row py-1 input-linhas-rodape">
                    <label for="ordem-input-blocos-rodape">Ordem</label>
                    <input type="number" v-model="ordem" name="ordem" id="ordem-input-blocos-rodape" class="form-input-blocos-rodape">
                </div>

                <div class="row py-1 input-linhas-rodape">
                    <label for="titulo-input-blocos-rodape">Título</label>
                    <input type="text" v-model="titulo" name="titulo" id="titulo-input-blocos-rodape" class="form-input-blocos-rodape">
                </div>

                <div class="row py-1 input-linhas-rodape">
                    <vue-editor-custom-c-k ref="editorConteudo" :toolbarConfig="toolbarArray"/>
                </div>

                <div class="row py-2">
                    <div class="botoes-float-right-container">
                        <botao-verde class="botoes-float-right" @click="salvar(ok)" > Salvar </botao-verde>
                        <botao-branco class="botoes-float-right" @click="cancel()" > Cancelar </botao-branco>
                    </div>
                </div>
                
            </div>
        </template>

        <template v-slot:modal-footer>
            <div class="mr-5">
                
            </div>
        </template>
    </b-modal>
</template>

<script>
import BotaoVerde from '@/components/botoes/BotaoVerde.vue';
import BotaoBranco from '@/components/botoes/BotaoBranco.vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import VueEditorCustomCK from '@/components/blog-components/VueEditorCustomCK.vue'


export default {
    name: 'ModalBlocosRodape',
    components: {
        BotaoVerde,
        BotaoBranco,
        VueEditorCustomCK,
    },
    props: {
       toolbarArray: {
            type: Array,
            default: () => [ 'bold', 'italic', 'heading', '|', 'link', 'alignment', 'bulletedList', 'numberedList', 'fontSize', 'fontColor' ],
        }
    },
    data: function() {
        return {
            idBloco: {
                type: Number,
                default: 0
            },
            titulo: '',
            ordem: 0,
            conteudo: '',

            exibe: false,
        }
    },
    computed: {
        ...mapGetters({
            dadosRodape: 'dadosRodape/dadosRodape',
        }),
    },
    methods: {
        ...mapActions({
            getDadosRodape: 'dadosRodape/getDadosRodape'
        }),
        show: function(id = 0) {
            this.idBloco = id;
            // Se inicia vazio, adiciona um linha em branco pra começar
            if(id == 0){ 
                // Iniciar com o maior valor de ordem, por facilidade
                if(this.dadosRodape) {
                    let maiorOrdem = Math.max(...this.dadosRodape.map(dado => dado.ordem));
                    this.ordem = maiorOrdem + 1;
                }
            }

            if(this.dadosRodape && this.idBloco > 0) {
                let bloco = this.dadosRodape.filter(item => item.id == this.idBloco);
                if(bloco) {
                    if(bloco.length > 0) {
                        this.titulo = bloco[0].titulo;
                        this.ordem = bloco[0].ordem;
                        this.conteudo = bloco[0].conteudo;
                        this.setContentEditor(this.conteudo);
                    }
                }
            } 
            this.exibe = true;
        },
        hide: function() {
            this.limpa();
            this.exibe = false;
        },
        salvar: function(ok) {
            // Atualiza variavel do conteudo antes de fechar o modal para nao vir undefined
            this.getContentEditor();
            // Metodo Ok que fecha o modal
            ok();
            let registro = {
                "ordem": parseInt(this.ordem),
                "titulo": this.titulo,
                "conteudo": this.conteudo,
                "versao": 0,
            };
            let requisicao = null;
            if(this.idBloco > 0) {
                registro.Id = this.idBloco;
                requisicao = axios.put("/api/dados-rodape", registro);
            } else {
                requisicao = axios.post("/api/dados-rodape", registro);
            }
			requisicao
				.then( (response) => {
					console.log(response);

                    // atualiza a lista
                    this.getDadosRodape();
                    // Limpa form
                    this.limpa();
				})
				.catch( err => {
                    if(err.response.status == 401 || err.response.status == 403) {
                        this.$router.push('/admin').catch(()=>{});
                    }
                    console.log("erro cadastro do bloco rodapé", err.response.data)
				});
        },
        limpa: function() {
            this.idBloco = 0;
            this.titulo = '';
            this.conteudo = '';
            this.ordem = 0;
        },
        getContentEditor: function() {
            if(this.$refs.editorConteudo) {
                let conteudo = this.$refs.editorConteudo.getContent();
                this.conteudo = conteudo;
                return conteudo;
            }
            return this.conteudo;
        },
        setContentEditor: function(txt) {
            if(this.$refs.editorConteudo) {
                this.$refs.editorConteudo.setContent(txt);
                this.$refs.editorConteudo.prefill();
            }
            this.conteudo = txt;
        },
    },
    updated() {
        if(this.$refs.editorConteudo) {
            this.$refs.editorConteudo.setContent(this.conteudo? this.conteudo: '');
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.header-modal-blocos-rodape {
    border: white !important;
    padding-bottom: 0 !important;
}

.body-modal-blocos-rodape {
    padding-right: 50px !important;
    padding-left: 50px !important;
}

.footer-modal-blocos-rodape {
    border: white !important;
}

.form-input-blocos-rodape {
    width: 100%;
    border: none !important;
    border-bottom: 1px solid black !important;
    outline: none;
}

.botoes-float-right {
    float: right;
    margin-left: 20px;
}

.botoes-float-right-container {
    width: 100%;
    margin-top: 30px;
}

.row-modal-servicos input {
    margin: 5px 5px 0px 0px;
}

.select-input-modal {
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 1px solid black !important;
}

.input-linhas-rodape label {
    margin-bottom: 0;
}

</style>
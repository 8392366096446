<template>
    <button class="botao-visualizar-tabela" v-on:click="$emit('click')">
        <div class="svg-container">
            <inline-svg 
                :src="require('../../assets/images/svg/olho-visualizacao.svg')"        
                :color="false"
            ></inline-svg>
        </div>
        <slot></slot>
    </button>    
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
    name: 'BotaoVisualizarTabela',
    components: {
        InlineSvg 
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.svg-container {
    display: inline-flex;
}
    .svg-container svg {
        width: 100%;
        height: 100%;
    }

.botao-visualizar-tabela {
    border: none;
    background: none;
    outline: none;
    width: 16px;
    height: 16px;
}


</style>
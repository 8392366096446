<template>
    <div class="modal fade" id="modalFerramentasBanner" role="dialog" aria-labelledby="modalFerramentasBanner" aria-hidden="true">
        <div class="modal-dialog modal_banner" role="document">
            <div class="modal-content caixa_modal">
                <div class="modal-body caixa_body">

                    <div>
                        <p class="modal-lista-banners">Gerenciar banners</p>
                    </div>

                    <div class="container-fluid">
                        <div class="row">
                            <div class="col">
                                <table class="table table-borderless">

                                    <thead>
                                        <tr>
                                            <th class="banner_titulo">Título</th>
                                            <th class="pr-5">Ordem</th>
                                            <th>Localização</th>
                                            <th class="d-inline-block"></th>
                                        </tr>
                                    </thead>

                                    <tbody v-for="(banner, index) in banners" :key="index">

                                        <tr class="linha-banner">
                                            <td class="banner_titulo">{{ banner.titulo.toUpperCase() }}</td>
                                            <td>{{ banner.ordem }}</td>
                                            <td style="text-transform:capitalize;">{{ banner.localizacao }}</td>
                                            <td class="d-inline-block w-100 pr-0">
                                                <button-edit-svg @click="showForm(banner.id)"></button-edit-svg>
                                                <button-remove-svg @click="showModalConfirm(banner.id)"></button-remove-svg>
                                            </td>
                                        </tr>

                                        <tr v-if="editForm.includes(banner.id)">
                                            <td colspan="12">
                                                <form-bloco-banner :banner="banner" @fecharForm="fecharForms" />
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                    <div>
                        <button-txt-laranja style="font-weight: 700;" @click="showFormNovoBanner">{{ buttonNewBanner ? 'Novo banner' : '+ Adicionar banner' }}</button-txt-laranja>
                        
                        <div v-if="buttonNewBanner">
                            <form-bloco-banner @fecharForm="fecharForms" />
                        </div>

                        <div id="fechar">
                            <button-fechar-branco v-if="!buttonNewBanner" class="btn-md" data-dismiss="modal" @click="fecharForms" aria-label="Close">Fechar</button-fechar-branco>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal ref="modalConfirm" static centered hide-backdrop hide-footer hide-header>
            <div class="d-block text-center">
                <p>Excluir este banner?</p>
            </div>
            <div class="text-center">
                <b-button @click="hideModalConfirm" size="sm" class="mx-2" >Cancelar</b-button>
                <b-button @click="removerBanner" size="sm" variant="success" class="mx-2">Confirmar</b-button>
            </div>
        </b-modal>

    </div>
</template>

<script>
    import axios from 'axios'
    import ButtonFecharBranco from '@/components/botoes/ButtonFecharBranco.vue'
    import ButtonTxtLaranja from '@/components/botoes/ButtonTxtLaranja.vue'
    import ButtonRemoveSvg from '@/components/botoes/ButtonRemoveSvg.vue'
    import ButtonEditSvg from '@/components/botoes/ButtonEditSvg.vue'
    import FormBlocoBanner from '@/components/admin/modals/ModalBlocoBanner.vue'

    export default {
        name: 'ModalFerramentasBanner',
        components: {
            ButtonFecharBranco,
            ButtonTxtLaranja,
            ButtonRemoveSvg,
            ButtonEditSvg,
            FormBlocoBanner
        },
        data: function () {
            return {
                banners: [],
                bannerId: null,
                buttonNewBanner: false,
                tipoButtonUrl: null,
                editForm: []
            }
        },
        methods: {
            removerBanner: function () {
                const ID = this.bannerId;
                if (ID !== null && ID > 0) {
                    this.hideModalConfirm();
                    axios.delete('/api/banners/' + ID)
                        .then(() => {
                            this.listarBanners();
                        })
                        .catch(err => {
                            console.log(err);
                        });
                }
            },
            showModalConfirm: function (id) {
                this.bannerId = id;
                this.$refs['modalConfirm'].show();
            },
            hideModalConfirm: function () {
                this.bannerId = null;
                this.$refs['modalConfirm'].hide();
            },
            showFormNovoBanner: function () {
                this.editForm = [];
                this.buttonNewBanner = !this.buttonNewBanner;
            },
            fecharForms: function () {
                this.editForm = [];
                this.buttonNewBanner = false;
            },
            showForm: function (id) {
                const index = this.editForm.indexOf(id);
                if (index > -1) {
                    this.editForm.splice(index, 1)
                } else {
                    this.buttonNewBanner = false;
                    this.editForm = [];
                    this.editForm.push(id);
                }
            },
            listarBanners: function () {
                axios.get('/api/banners')
                    .then(res => {
                        this.banners = res.data.banners;
                    }).catch(err => {
                        console.log(err);
                    })
            },
        },
        created() {
            this.listarBanners();
        },
        updated() {
            this.listarBanners();
        }
    }
</script>

<style scoped>
    .column {
        float: left;
        width: 50%;
        padding: 0 2%;
    }

    .row:after {
        content: "";
        display: table;
        clear: both;
    }

    .caixa_modal {
        padding: 7px 14px;
        border: none !important;
        border-radius: unset !important;
    }

    .caixa_body {
        text-align: left;
        margin: 0 5%;
    }

    #logo, #favicon, #fechar {
        margin: 5% 0;
    }

    #fechar {
        text-align: right;
    }

    .btn_salvar {
        margin-left: 10px;
    }

    .modal-open, .modal {
        overflow-x: hidden;
        overflow-y: auto;
    }

    .modal-lista-banners {
        font-weight: 700;
        font-size: 20px;
    }

    div.container-fluid, .col {
        padding: 0px;
    }

    .banner_edit select {
        padding: 5px 0;
    }

    tbody {
        font-size: 100%;
    }

    th {
        text-align: left !important;
    }

    .banner_titulo {
        width: 60%;
    }

    label {
        margin: 0;
    }

    .btn_salvar {
        padding: .25rem 1rem;
    }

    #text_conteudo, #text_conteudo:focus {
        resize: none;
        width: 100%;
        height: 10rem;
        border-color: var(--cinza-omni);
        border-width: 1px;
        padding: 5px;
        outline: none;
    }

    .linha-banner:hover {
        background-color: var(--cinza-claro-omni);
    }

    .input-numerico-ordenacao {
        width: 100%;
        border: none !important;
        border-bottom: 1px solid black !important;
        outline: none;
    }

    .demonstrativo-imagem {
        max-width: 130px;
        max-height: 50px;
    }

    @media (min-width: 576px) {
        .modal_banner {
            max-width: 900px !important;
        }
    }
</style>
<template>
    <b-modal 
        v-model="exibe" 
        body-class="body-modal-setores" 
        header-class="header-modal-setores" 
        footer-class="footer-modal-setores" 
    >
        
        <template v-slot:default="{ cancel, ok }">
            <div class="container">
                <div class="row pb-2">
                    <h3> {{ idSubSetor > 0? 'Editar sub-setor': 'Cadastro de sub-setor'}} </h3>
                </div>

                <div class="row py-2">
                    <label for="nome-input-setores">Nome</label>
                    <input type="text" v-model="nome" name="nome" id="nome-input-setores" class="form-input-setores" >
                </div>

                <div class="row py-2">
                    <label for="url-input-setores"> Url de direcionamento </label>
                    <input type="text" v-model="url" name="url" id="url-input-setores" class="form-input-setores" >
                </div>

                <div class="row py-2">
                    <label for="select-input-setores">Setor mestre</label>
                    <select v-model="setorMestre" name="select" id="select-input-setores" class="select-input-modal-subsetores">
                        <option disabled :value="0">Escolha um item</option>
                        <option v-for="(setor, i) in setores" v-bind:key="i" :value="setor.id">{{ setor.nome }}</option> 
                    </select>
                </div>

                <div class="row row-modal-checkbox">
                    <input type="checkbox" v-model="ehSetorInterno">
                    <label>É um subsetor interno à outro subsetor?</label>
                </div>

                <div class="row" v-if="ehSetorInterno">
                    <label for="select-input-sub-setores">Subsetor Mestre</label>
                    <select v-model="subSetorMestre" name="selectsub" id="select-input-sub-setores" class="select-input-modal-subsetores">
                        <option disabled :value="0">Escolha o subsetor Mestre</option>
                        <option v-for="(subSetor, i) in subSetores.filter(subset => subset.id !== idSubSetor)" v-bind:key="i" :value="subSetor.id">{{ subSetor.nome }}</option> 
                    </select>
                </div>

                <div class="row py-2">
                    <label for="ordenacao-input-setores ">Ordenação</label>
                    <input type="number" v-model="ordenacao" name="ordenacao" id="ordenacao-input-setores" class="form-input-setores" >
                </div>

                <div class="row py-2">
                    <div class="botoes-float-right-container">
                        <botao-verde class="botoes-float-right" @click="salvar(ok)" > Salvar </botao-verde>
                        <botao-branco class="botoes-float-right" @click="cancel()" > Cancelar </botao-branco>
                    </div>
                </div>
                
            </div>
        </template>

        <template v-slot:modal-footer>
            <div class="mr-5">
                
            </div>
        </template>
    </b-modal>
</template>

<script>
import BotaoVerde from '@/components/botoes/BotaoVerde.vue';
import BotaoBranco from '@/components/botoes/BotaoBranco.vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'ModalSetores',
    components: {
        BotaoVerde,
        BotaoBranco
    },
    props: {
       
    },
    data: function() {
        return {
            exibe: false,
            nome: '',
            url: '',
            setorMestre: 0,
            ordenacao: 0,
            idSubSetor: {
                type: Number,
                default: 0
            },
            ehSetorInterno: false,
            subSetorMestre: 0,
        }
    },
    computed: {
        ...mapGetters({
            setores: 'setoresMenu/setores',
            subSetores: 'setoresMenu/subSetores',
        }),
    },
    methods: {
        ...mapActions({
            getSubSetores: 'setoresMenu/getSubSetores'
        }),
        show: function() {
            if(this.subSetores && this.idSubSetor > 0) {
                let subSetor = this.subSetores.filter(item => item.id == this.idSubSetor);
                if(subSetor) {
                    if(subSetor.length > 0) {
                        this.nome = subSetor[0].nome;  
                        this.url = subSetor[0].url;
                        this.ordenacao = subSetor[0].ordenacao;
                        this.setorMestre = subSetor[0].setor_mestre;
                        this.subSetorMestre = subSetor[0].sub_setor_mestre;
                    }
                }
            }
            this.exibe = true;
        },
        hide: function() {
            this.limpa();
            this.exibe = false;
        },
        salvar: function(ok) {
            // Metodo Ok que fecha o modal
            ok();
            let registro = {
				"nome": this.nome,
				"url": this.url,
                "ordenacao": parseInt(this.ordenacao),
                "setor_mestre": parseInt(this.setorMestre),
                "sub_setor_mestre": parseInt(this.subSetorMestre),
            };
            let requisicao = null;
            if(this.idSubSetor > 0) {
                registro.Id = this.idSubSetor;
                requisicao = axios.put("/api/subsetores", registro);
            } else {
                requisicao = axios.post("/api/subsetores", registro);
            }
			requisicao
				.then( (response) => {
					console.log(response);

                    // atualiza a lista
                    this.getSubSetores();
                    // Limpa form
                    this.limpa();
				})
				.catch( err => {
                    if(err.response.status == 401 || err.response.status == 403) {
                        this.$router.push('/admin/paginas').catch(()=>{});
                    }
					console.log("erro cadastro subsetores", err.response.data)
				});
        },
        limpa: function() {
            this.nome = '';
            this.url = '';
            this.ordenacao = 0;
            this.idSubSetor = 0;
            this.setorMestre = 0;
            this.subSetorMestre = 0;
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.header-modal-setores {
    border: white !important;
    padding-bottom: 0 !important;
}

.body-modal-setores {
    padding-right: 50px !important;
    padding-left: 50px !important;
}

.footer-modal-setores {
    border: white !important;
}

.form-input-setores {
    width: 100%;
    border: none !important;
    border-bottom: 1px solid black !important;
    outline: none;
}

.botoes-float-right {
    float: right;
    margin-left: 20px;
}

.botoes-float-right-container {
    width: 100%;
    margin-top: 30px;
}

.select-input-modal-subsetores {
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 1px solid black !important;
}

.row-modal-checkbox input {
    margin: 5px 5px 0px 0px;
}

</style>
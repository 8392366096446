<template>
    <b-modal 
        v-model="exibe" 
        body-class="body-modal-tipo-bloco" 
        header-class="header-modal-tipo-bloco" 
        footer-class="footer-modal-tipo-bloco"
        size="md" 
    >
        
        <template v-slot:default="{ cancel, ok }">
            <div class="container">
                <div class="row pb-2">
                    <h3> Escolha o modelo do bloco para incluir </h3>
                </div>

                <div class="row py-2">
                    <label for="select-input-tipo-bloco">Modelo do Bloco</label>
                    <select v-model="tipoEscolhido" name="select" id="select-input-tipo-bloco" class="select-input-modal-tipo-bloco">
                        <option disabled :value="''">Escolha um modelo</option>
                        <option v-for="(tipo, i) in tiposComponentes" v-bind:key="i" :value="tipo.chave">{{ tipo.valor }}</option> 
                    </select>
                </div>

                <div class="row py-2">
                    <div class="botoes-float-right-container">
                        <botao-verde :disabled="tipoEscolhido.length == 0" class="botoes-float-right" @click="continuar(ok)" > Continuar </botao-verde>
                        <botao-branco class="botoes-float-right" @click="cancel()" > Cancelar </botao-branco>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:modal-footer>
            <div class="mr-5">
                
            </div>
        </template>
    </b-modal>
</template>

<script>
import BotaoVerde from '@/components/botoes/BotaoVerde.vue';
import BotaoBranco from '@/components/botoes/BotaoBranco.vue';
import { TIPOS_COMPONENTES } from '@/assets/js/dicionarioPaginas'

export default {
    name: 'ModalPaginas',
    components: {
        BotaoVerde,
        BotaoBranco,
    },
    props: {
       
    },
    data: function() {
        return {
            exibe: false,
            tiposComponentes: TIPOS_COMPONENTES,
            tipoEscolhido: '',
            id_pagina: 0,
        }
    },
    methods: {
        show: function(pIdPagina) {
            console.log('show pIdPagina', pIdPagina);
            this.id_pagina = pIdPagina;
            this.exibe = true;
        },
        hide: function() {
            this.limpa();
            this.exibe = false;
        },
        continuar: function(ok) {
            ok();
            if(this.tipoEscolhido) {
                this.$emit('escolhido', this.tipoEscolhido, this.id_pagina);
            }
        },
        limpa: function() {
            this.tipoEscolhido = '';
            this.id_pagina = 0;
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.header-modal-tipo-bloco {
    border: white !important;
    padding-bottom: 0 !important;
}

.body-modal-tipo-bloco {
    padding-right: 50px !important;
    padding-left: 50px !important;
}

.footer-modal-tipo-bloco {
    border: white !important;
}

.select-input-modal-tipo-bloco {
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 1px solid black !important;
}

.botoes-float-right {
    float: right;
    margin-left: 20px;
}

.botoes-float-right-container {
    width: 100%;
    margin-top: 30px;

}

</style>
<template>
    <div id="painel-popups">
        <div class="container">

            <div class="row">
                <div class="col-sm-12"> 
                    <h4>Popups Cadastrados</h4>
                    <p>Popup1</p>
                    <p>Popup2</p>
                    <p>Popup3</p>
                    <p>Popup4</p>
                    <p>Popup5</p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'PainelPopups',
        components: {
        }
    }
</script>

<style scoped>

#painel-popups {
    border: 1px solid red;
}

</style>
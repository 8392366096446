<template>
    <div id="painel_ferramentas">
        <div class="container-fluid painel_interno">
            <div class="row" style="font-size:16px;font-weight:500;">{{discoVirtualAberto ? 'Disco virtual' : 'Ferramentas'}}</div>
            <div v-if="!discoVirtualAberto" class="row row-cols-3">
                <div id="caixa_ferramenta" class="col">
                    <button class="align-middle" data-toggle="modal" data-target="#modalFerramentasLogo">
                        <inline-svg :src="require('../../assets/images/svg/alterarlogo-icon.svg')" :width="40" :color="false"></inline-svg><br />
                        Alterar Logo
                    </button>
                    <ModalFerramentasLogo></ModalFerramentasLogo>
                </div>
                <div id="caixa_ferramenta" class="col">
                    <button class="align-middle" data-toggle="modal" data-target="#modalFerramentasBanner">
                        <inline-svg :src="require('../../assets/images/svg/banner-icon.svg')" :width="40" :color="false"></inline-svg><br />
                        Gerenciar Banners da página inicial
                    </button>
                    <modal-ferramentas-banner></modal-ferramentas-banner>
                </div>
                <div id="caixa_ferramenta" class="col">
                    <button class="align-middle" @click="discoVirtualAberto=true">
                        <inline-svg :src="require('../../assets/images/svg/discovirtual-icon.svg')" :width="40" :color="false"></inline-svg><br />
                        Disco Virtual
                    </button>
                </div>
                <div id="caixa_ferramenta" class="col">
                    <button class="align-middle" data-toggle="modal" data-target="#modalFerramentasContato">
                        <inline-svg :src="require('../../assets/images/svg/formulario-icon.svg')" :width="40" :color="false"></inline-svg><br />
                        Formulário de contato
                    </button>
                    <modal-ferramentas-contato />
                </div>
                <div id="caixa_ferramenta" class="col">
                    <button class="align-middle" data-toggle="modal" data-target="#modalFerramentasPopup">
                        <inline-svg :src="require('../../assets/images/svg/popup-icon.svg')" :width="45" :color="false"></inline-svg><br />
                        Popup
                    </button>
                    <modal-ferramentas-popup></modal-ferramentas-popup>
                </div>
            </div>
            <ModalFerramentasDiscoVirtual v-if="discoVirtualAberto" />
        </div>
    </div>
</template>

<script>
    import InlineSvg from 'vue-inline-svg'
    import ModalFerramentasLogo from '@/components/admin/modals/ModalFerramentasLogo.vue'
    import ModalFerramentasBanner from '@/components/admin/modals/ModalFerramentasBanner.vue'
    import ModalFerramentasPopup from '@/components/admin/modals/ModalFerramentasPopup.vue'
    import ModalFerramentasDiscoVirtual from '@/components/admin/modals/ModalFerramentasDiscoVirtual.vue'
    import ModalFerramentasContato from '@/components/admin/modals/ModalFerramentasContato.vue'

    export default {
        name: 'PainelAdmFerramentasPage',
        components: {
            InlineSvg,
            ModalFerramentasLogo,
            ModalFerramentasBanner,
            ModalFerramentasPopup,
            ModalFerramentasDiscoVirtual,
            ModalFerramentasContato
        },
        data: function () {
            return {
                discoVirtualAberto: false
            }
        }
    }
</script>

<style scoped>
    #painel_ferramentas {
        width: 100%;
        height: 100%;
        background-color: var(--cinza-claro-omni);
        font-size: 14px;
    }

    .painel_interno {
        padding: 2% 4%;
    }

    #caixa_ferramenta {
        padding-left: 0px;
        margin: 10px 0;
    }

        #caixa_ferramenta > button {
            background-color: white;
            width: 97%;
            height: 130px;
            margin-right: 2rem;
            border: none;
            color: var(--chumbo-omni);
        }

            #caixa_ferramenta > button:focus {
                outline: none;
            }

            #caixa_ferramenta > button:hover {
                border: 1px solid var(--chumbo-omni);
            }
</style>
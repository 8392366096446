<template>
  <div class="container">
    <div class="row ">
      <div class="col-12 ">
        <tabela-posts />
      </div>
    </div>
  </div>
</template>

<script>
import TabelaPosts from '@/components/admin/tabelas/TabelaPosts.vue';

export default {
  name: 'PainelAdmBlogPage',
  components: {
      TabelaPosts,
  },
  data: function() {
    return {
      
    }
  },
  methods: {

  },
}

</script>


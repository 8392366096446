<template>
    <button class="button_edit" type="button" value="Edit" v-on:click="$emit('click')">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
            <image id="iconmonstr-pencil-10-32" width="16" height="16" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABAUlEQVRYw+3XP26DMBiH4ff3AbJAmTLlFB16jCpzj1OpR+oJosz50ylHCBlyAtqBLkRKESSktUkGW2JBgue1ZQkDAUeSJE+SFmmazkI6vThwBGpgN2pECz9d40T04ONEXMHDRgzEw0TciPuN+CNeA7Wk5d1w4Ng8H/GIRzziEX8gvCiKCXC4C34aZvYKfI+KO+em59/mGyP+P3Mze6F1QBgY4WfZJb3RcUq5EuEHbwI+zl48JMIf3ox9C7gU4Rd3zs16lrgr4uB75pjZnP5N9isiz/OJVxxA0juXd3rQv5kUeO64X0raAitJWzP7ChUgSZ9ACawlbbIsW1dVVYYC2+MHeVppiheHOUAAAAAASUVORK5CYII=" />
        </svg>>
    </button>
</template>

<script>
    export default {
        name: 'ButtonEditSvg',
    }
</script>

<style scoped>
    .button_edit {
        border: none !important;
        color: transparent !important;
        background-color: transparent !important;
        margin: 0 3px;
    }

    .button_edit:focus {
        outline: none !important;
    }
</style>
<template>
  <div class="container-input-buscar">
    <input v-model="value" type="search" @change="emiteBusca" class="input-buscar" :placeholder="vplaceholder">
    <span class="icone-input-buscar" @click="emiteBusca">
        <inline-svg 
            :src="require('../../assets/images/svg/lupa-busca.svg')"        
            :color="false"
        ></inline-svg>
    </span>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
    name: 'InputBuscar',
    props: {
        vplaceholder: {
            type: String,
            default: "Buscar",
        },
    },
    data: function() {
        return {
            value: ''
        }
    },
    components: {
        InlineSvg 
    },
    methods: {
        emiteBusca: function() {
            this.$emit('change', this.value);
        }
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.container-input-buscar {
    color: var(--cinza-escuro-omni);
    position: relative;
}

.input-buscar {
    color: var(--cinza-escuro-omni);
    background: transparent;
    border: none;
    border-bottom: 1.5px solid var(--cinza-escuro-omni);
    padding-right: 2.375rem;
    outline: none;
    height: 2.375rem;
    width: 100%;
    max-width: 100%;
}

    .input-buscar::-webkit-search-cancel-button {
        display: none;
    }


 .icone-input-buscar {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: auto;
    color: #aaa;
    right: 0;
    top: 0;
}

.icone-input-buscar svg {
    fill: var(--cinza-escuro-omni);
}

.icone-input-buscar svg:hover {
    opacity: 0.5;
}

.icone-input-buscar svg:active {
    opacity: 1.5;
}


</style>
<template>
  <b-container fluid>
    <!-- User Interface controls -->
    <b-row>

      <b-col lg="4" class="mt-1 mb-0 pr-0">
        <h5 class="mt-2 mb-0 text-left"><b>Páginas</b></h5>
      </b-col>

      <b-col lg="4" class="my-1 px-0">
        <input-buscar :value="filter" @change="changeFilter"/>
      </b-col>

      <b-col lg="4" class="my-1 pr-1">
        <botao-criar class="float-right" @click="exibeModal()" >+ Criar nova página</botao-criar>
      </b-col>

    </b-row>
    
    <b-row>
      <!-- Main table element -->
      <b-table
        class="bg-white "
        :tbody-tr-class="classTd"
        hover
        show-empty
        small
        stacked="md"
        :items="listaPaginas"
        :fields="fields"
        :filter="filter"
        :filterIncludedFields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      >

        <template v-slot:empty>
          <label>Sem conteúdo cadastrado</label>
        </template>

        <template v-slot:emptyfiltered>
          <label>Sem resultados</label>
        </template>

        <template v-slot:cell(titulo)="row">
          <botao-detalhes-tabela class="mx-1 float-left" @click="row.toggleDetails" />
          <span class="float-left">{{ row.item.titulo }}</span>
        </template>

        <template v-slot:cell(url_setor)="row">
          <span class="float-left">{{ row.item.template!=='HOME'? 'pagina/' + row.item.url_setor: '/' }}</span>
        </template>

        <template v-slot:cell(template)="row">
          <span>{{ traduzTemplate(row.item.template) }}</span>
        </template>
        
        <template v-slot:cell(actions)="row">

          <botao-visualizar-tabela class="mx-1 float-right" @click="visualizar(row.item)" />
          <botao-delete-tabela v-if="row.item.template!=='HOME'" class="mx-1 float-right" @click="excluir(row.item.id)" />
          <botao-edit-tabela class="mx-1 float-right" @click="editar(row.item.id)" />

        </template>

        <template v-slot:row-details="row">
          <b-card>
            
            <div class="container">
              <div class="row pb-1 row-cabecalho-detalhes-paginas">
                <div class="col-2 col-cabecalho-detalhes-paginas">
                  Posição
                </div>
                <div class="col-3 col-cabecalho-detalhes-paginas">
                  Tipo de Componente
                </div>
                <div class="col-1 col-cabecalho-detalhes-paginas">
                  Ações
                </div>
              </div>
              <div class="row row-linhas-componentes-paginas" v-for="(comp, i) in row.item.componentes.slice().sort((c1,c2) => c1.posicao - c2.posicao)" v-bind:key="i">

                <div class="col-2 col-linhas-componentes-paginas">
                  {{ `#parte-${comp.posicao}` }}
                </div>

                <div class="col-3 col-linhas-componentes-paginas">
                  {{ traduzTipoComponente(comp.tipo) }}
                </div>

                <div class="col-2 col-linhas-componentes-paginas">
                  <botao-delete-tabela class="mx-1" @click="excluirComponente(comp.id)" />
                  <botao-edit-tabela class="mx-1" @click="editarComponente(comp.tipo, row.item.id, comp.id)" />
                </div>

              </div>
            </div>
            
            <botao-criar @click="criarComponente(row.item.id)" >+ Criar novo Bloco</botao-criar>

          </b-card>
        </template>
      </b-table>
    </b-row>

    <modal-paginas ref="modal_paginas" v-model="modalpaginasShow"/>
    <modal-escolhe-tipo-bloco ref="modal_escolhe_tipo" v-model="modalEscolheTiposShow" @escolhido="abrirModalBloco" />
    <modal-bloco-padrao ref="modal_bloco_padrao" v-model="modalBlocoPadraoShow" />
    <modal-bloco-lista-icones ref="modal_bloco_lista_icones" v-model="modalBlocoListaIconesShow" />
    <modal-bloco-agentes ref="modal_bloco_agentes" v-model="modalBlocoAgentesShow" />
    <modal-bloco-caixa-preta ref="modal_bloco_caixa_preta" v-model="modalBlocoCaixaPretaShow" />
  </b-container>
</template>

<script>
import BotaoDeleteTabela from '@/components/botoes/BotaoDeleteTabela.vue';
import BotaoEditTabela from '@/components/botoes/BotaoEditTabela.vue';
import BotaoDetalhesTabela from '@/components/botoes/BotaoDetalhesTabela.vue';
import BotaoCriar from '@/components/botoes/BotaoCriar.vue';
import BotaoVisualizarTabela from '@/components/botoes/BotaoVisualizarTabela.vue';
import InputBuscar from '@/components/inputs/InputBuscar.vue';

import ModalPaginas from '@/components/admin/modals/ModalPaginas.vue';
import ModalEscolheTipoBloco from '@/components/admin/modals/ModalEscolheTipoBloco.vue';
import ModalBlocoPadrao from '@/components/admin/modals/ModalBlocoPadrao.vue';
import ModalBlocoListaIcones from '@/components/admin/modals/ModalBlocoListaIcones.vue';
import ModalBlocoAgentes from '@/components/admin/modals/ModalBlocoAgentes.vue';
import ModalBlocoCaixaPreta from '@/components/admin/modals/ModalBlocoCaixaPreta.vue';

import { mapActions, mapGetters  } from 'vuex';
import axios from 'axios';
import { TIPOS_TEMPLATES, TIPOS_COMPONENTES } from '@/assets/js/dicionarioPaginas'
import { abreCaixaConfirmacao } from '@/assets/js/CaixaConfirmacao'

  export default {
    name: 'TabelaPaginas',
    components: {
        BotaoDeleteTabela,
        BotaoEditTabela,
        InputBuscar,
        ModalPaginas,
        BotaoCriar,
        BotaoDetalhesTabela,
        ModalEscolheTipoBloco,
        ModalBlocoPadrao,
        ModalBlocoListaIcones,
        BotaoVisualizarTabela,
        ModalBlocoAgentes,
        ModalBlocoCaixaPreta,
    },
    props: {
      componentesCaixaPreta: {
        type: Array,
        default: () => ['BANNER_HOME', 'VALIDA_BOLETO', 'ULTIMAS_NOTICIAS', 'CONTATO', 'MAPA_GOOGLE'],
      },
    },
    data() {
      return {
        classTd: 'row-sem-borda',
        tiposTemplate: TIPOS_TEMPLATES,
        tiposComponentes: TIPOS_COMPONENTES,
        modalpaginasShow: false,
        modalEscolheTiposShow: false,
        modalBlocoPadraoShow: false,
        modalBlocoListaIconesShow: false,
        modalBlocoAgentesShow: false,
        modalBlocoCaixaPretaShow: false,
        fields: [
          { key: 'titulo', label: 'Título', sortable: true, sortDirection: 'asc' },
          { key: 'url_setor', label: 'Url Relativa', sortable: true, sortDirection: 'asc' },
          { key: 'template', label: 'Template', sortable: true, sortDirection: 'asc' },
          { key: 'actions', label: '' },
        ],
        totalRows: 1,
        currentPage: 1,
        sortBy: 'titulo',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: ['titulo'],
      }
    },
    computed: {
      ...mapGetters({
          listaPaginas: 'paginas/paginas',
      }),
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    mounted() {
      // Set the initial number of items
      this.getListaPaginas().then(()=> this.totalRows = this.listaPaginas?this.listaPaginas.length: 1);
    },
    methods: {
      ...mapActions({
        getListaPaginas: 'paginas/getPaginas'
      }),
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      exibeModal: function() {
        this.$refs.modal_paginas.limpa();
        this.$refs.modal_paginas.show();
        this.modalpaginasShow = true;
      },
      editar: function(id) {
        this.$refs.modal_paginas.limpa(id);
        this.$refs.modal_paginas.show(id);
      },
      excluir: function(id) {
        abreCaixaConfirmacao(this.$bvModal, "Deseja realmente excluir essa página?")
          .then( resposta => {
            if(resposta === true) {
              axios.delete(`/api/paginas/${id}`)
                .then( (response) => {
                  console.log(response);
                  this.getListaPaginas().then(()=> this.totalRows = this.listaPaginas?this.listaPaginas.length: 1);
                })
                .catch( err => {
                  console.log("erro exclusao da página", err.response.data)
                });
            }
          })
          .catch(()=> {
            console.log("erro na exclusão");
          });
      },
      changeFilter: function(valor) {
        this.filter = valor;
      },
      traduzTemplate: function(template) {
        if(template) {
          let valorFormatado = this.tiposTemplate.find(tipo => tipo.chave == template);
          if(valorFormatado)
            return valorFormatado.valor;
        }
        return template;
      },
      traduzTipoComponente: function(pTipo) {
        if(pTipo) {
          let valorFormatado = this.tiposComponentes.find(tipo => tipo.chave == pTipo);
          if(valorFormatado)
            return valorFormatado.valor;
        }
        return pTipo;
      },
      excluirComponente: function(idComp) {
        abreCaixaConfirmacao(this.$bvModal, "Deseja realmente excluir esse bloco?")
          .then( resposta => {
            if(resposta === true) {
              axios.delete(`/api/componentes/${idComp}`)
                .then( () => {
                  this.getListaPaginas();
                })
                .catch( err => {
                  console.log("erro exclusao bloco rodapé", err.response.data)
                });
            }
          })
          .catch(()=> {
            console.log("erro na exclusão");
          });
      },
      editarComponente: function(pTipo, pIdPagina, pIdComp) {
        if(pTipo) {
          
          if(['LISTA_ICONES', 'LISTA_ICONES_ESQ', 'COLUNAS_BOTAO_IMG', 'LINHA_PRODUTOS', 'LISTA_CARROSSEL', 'FAQ'].includes(pTipo)) {
            this.$refs.modal_bloco_lista_icones.limpa();
            this.$refs.modal_bloco_lista_icones.tipo = pTipo;
            this.$refs.modal_bloco_lista_icones.show(pIdPagina, pIdComp);
            return;
          }
          
          if(pTipo === 'AGENTES') {
            this.$refs.modal_bloco_agentes.limpa();
            this.$refs.modal_bloco_agentes.tipo = pTipo;
            this.$refs.modal_bloco_agentes.show(pIdPagina, pIdComp);
            return;
          }

          if(this.componentesCaixaPreta.includes(pTipo)) {
            this.$refs.modal_bloco_caixa_preta.limpa();
            this.$refs.modal_bloco_caixa_preta.tipo = pTipo;
            this.$refs.modal_bloco_caixa_preta.show(pIdPagina, pIdComp);
            return;
          }
        
          this.$refs.modal_bloco_padrao.limpa();
          this.$refs.modal_bloco_padrao.show(pIdPagina, pIdComp);
        }
      },
      criarComponente: function(idPagina) {
        this.$refs.modal_escolhe_tipo.limpa();
        this.$refs.modal_escolhe_tipo.show(idPagina);
      },
      abrirModalBloco: function(pTipo, pIdPagina) {
        console.log('tipo escolhido', pTipo);
        if(pTipo) {

          if(['LISTA_ICONES', 'LISTA_ICONES_ESQ', 'COLUNAS_BOTAO_IMG', 'LINHA_PRODUTOS', 'LISTA_CARROSSEL', 'FAQ'].includes(pTipo)) {
            this.$refs.modal_bloco_lista_icones.limpa();
            this.$refs.modal_bloco_lista_icones.tipo = pTipo;
            this.$refs.modal_bloco_lista_icones.show(pIdPagina);
            return;
          }
          
          if(pTipo === 'AGENTES') {
            this.$refs.modal_bloco_agentes.limpa();
            this.$refs.modal_bloco_agentes.tipo = pTipo;
            this.$refs.modal_bloco_agentes.show(pIdPagina);
            return;
          } 

          if(this.componentesCaixaPreta.includes(pTipo)) {
            this.$refs.modal_bloco_caixa_preta.limpa();
            this.$refs.modal_bloco_caixa_preta.tipo = pTipo;
            this.$refs.modal_bloco_caixa_preta.show(pIdPagina);
            return;
          }

          this.$refs.modal_bloco_padrao.limpa();
          this.$refs.modal_bloco_padrao.tipo = pTipo;
          this.$refs.modal_bloco_padrao.show(pIdPagina);
          return;
        }
      },
      visualizar: function(pPagina) {
        if(pPagina) {

          if(pPagina.template === 'HOME') {
            window.open('/', '_blank');
          }

          if(pPagina.url_setor) {
            let urlRedir = pPagina.url_setor.replace(/\//g, "");
            // this.$router.push({name: 'RenderizadorPage', params: { url: urlRedir }}).catch(()=>{});
            // \/ Modificado para abrir em nova aba \/
            let routeData = this.$router.resolve({name: 'RenderizadorPage', params: { url: urlRedir }});
            window.open(routeData.href, '_blank');
          }
        }
      },
    },
    
  }
</script>

<style>

.row-linhas-componentes-paginas:hover {
  background-color: rgba(0,0,0,.075);
}

.row-cabecalho-detalhes-paginas {
  background-color: var(--cinza-claro-omni);
  margin-bottom: 3px;
  padding-top: 3px;
}
.col-cabecalho-detalhes-paginas {
  word-wrap: normal;
  overflow-wrap: normal;
}

</style>
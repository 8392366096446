<template>
    <button class="btn botao-branco-omni" v-on:click="$emit('click')">
        <slot></slot>
    </button>    
</template>

<script>
export default {
    name: 'BotaoBranco',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

button.botao-branco-omni {
    background-color: #fff;
    color: var(--chumbo-omni);
    border-radius: 0px;
    border-bottom: 1px solid var(--chumbo-omni);
}

</style>
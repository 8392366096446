<template>
    <b-button v-on:click="$emit('click')">
        <b-icon icon="pencil-square" ></b-icon>
        <slot></slot>
    </b-button>    
</template>

<script>
export default {
    name: 'EditButton',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>


</style>
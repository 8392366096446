<template>
    <select @change="onSelected($event)" class="input-select py-1">
        <option v-if="preSelected" :value="preSelected" hidden>{{preSelected}}</option>
        <option v-else value="" hidden selected></option>

        <option class="options" v-for="(value, option) in optionsList" v-bind:value="value" :key="option">{{option}}</option>
    </select>
</template>

<script>
    export default {
        name: 'InputSelect',
        props: {
            optionsList: {
                type: Object,
                default: () => {},
            },
            preSelected: String,
        },
        mounted: function () {
            this.optionPreSelected();
        },
        data: function () {
            return {
                selected: '',
            }
        },
        methods: {
            optionPreSelected() {
                if (this.preSelected) {
                    this.$emit('selected', this.preSelected);
                }
            },
            onSelected: function (value) {
                var option = value.target.value;
                this.$emit('selected', option);
                
            },
        }
    }
</script>

<style scoped>
    /*select {
        -moz-appearance: none !important;
        -webkit-appearance: none !important;
        background: url(../../assets/images/svg/icone-select-arrow.svg) no-repeat;
        padding: 2px 2rem 2px 0;
        display: inline-block;
        background-position: 100% -3px, calc(100% - 15px) calc(1em + 2px), 100% 0;
    }*/

    .input-select {
        width: 100%;
        outline: none;
        border: none;
        border-bottom: 1px solid black !important;
    }

        .input-select:focus {
            width: 100%;
            outline: none;
            border: none;
            border-bottom: 1px solid black !important;
        }

    .options {
        font-size: 14px;
    }

</style>
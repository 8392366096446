<template>
  <b-container fluid>
    <!-- User Interface controls -->
    <b-row>

      <b-col lg="5" class="mt-1 mb-0 pr-0">
        <h5 class="mt-2 mb-0 text-left"><b>Conteúdo do Rodapé</b></h5>
      </b-col>

      <b-col lg="3" class="my-1 pr-0">
        <input-buscar :value="filter" @change="changeFilter"/>
      </b-col>

      <b-col lg="4" class="my-1 pr-1">
        <botao-criar class="float-right" @click="exibeModal()" >+ Criar novo bloco</botao-criar>
      </b-col>

    </b-row>

    <b-row>
      <!-- Main table element -->
      <b-table
        class="bg-white "
        :tbody-tr-class="classTd"
        hover
        show-empty
        small
        stacked="md"
        :items="dadosRodape"
        :fields="fields"
        :filter="filter"
        :filterIncludedFields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      >

        <template v-slot:empty>
          <label>Sem conteúdo cadastrado</label>
        </template>

        <template v-slot:emptyfiltered>
          <label>Sem resultados</label>
        </template>

        <template v-slot:cell(ordem)="row">
          <botao-detalhes-tabela class="mx-1 float-left" @click="row.toggleDetails" />
          <span class="float-left">{{ row.item.ordem }}</span>
        </template>

        <template v-slot:cell(actions)="row">
          <div style="width: 55px;">
            <botao-delete-tabela class="mx-1 float-right"  @click="excluir(row.item.id)" />
            <botao-edit-tabela class="mx-1 float-right" @click="editar(row.item.id)" />
          </div>
        </template>

        <template v-slot:row-details="row">
          <b-card>
            <div v-if="row.item.conteudo" class="mx-auto texto-icone-lista conteudo-html-components" v-html="row.item.conteudo">
            </div>
            <p v-else>---</p>
          </b-card>
        </template>
      </b-table>
    </b-row>

    <modal-blocos-rodape ref="modal_blocos_rodape" v-model="modalBlocosShow"/>
  </b-container>
</template>

<script>
import BotaoDeleteTabela from '@/components/botoes/BotaoDeleteTabela.vue';
import BotaoEditTabela from '@/components/botoes/BotaoEditTabela.vue';
import BotaoDetalhesTabela from '@/components/botoes/BotaoDetalhesTabela.vue';
import BotaoCriar from '@/components/botoes/BotaoCriar.vue';
import InputBuscar from '@/components/inputs/InputBuscar.vue';
import ModalBlocosRodape from '@/components/admin/modals/ModalBlocosRodape.vue';
import { mapActions, mapGetters  } from 'vuex';
import axios from 'axios';
import { abreCaixaConfirmacao } from '@/assets/js/CaixaConfirmacao'


  export default {
    name: 'TabelaBlocosRodape',
    components: {
        BotaoDeleteTabela,
        BotaoEditTabela,
        InputBuscar,
        ModalBlocosRodape,
        BotaoCriar,
        BotaoDetalhesTabela
    },
    data() {
      return {
        classTd: 'row-sem-borda',
        modalBlocosShow: false,
        fields: [
          { key: 'ordem', label: 'Ordem', sortable: true, sortDirection: 'asc' },
          { key: 'titulo', label: 'Título', sortable: true, sortDirection: 'asc' },
          { key: 'actions', label: '', thStyle: {width: '10% !important'} },
        ],
        totalRows: 1,
        currentPage: 1,
        sortBy: 'ordem',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: ['titulo'],
      }
    },
    computed: {
      ...mapGetters({
          dadosRodape: 'dadosRodape/dadosRodape',
      }),
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    mounted() {
      // Set the initial number of items
      this.getDadosRodape().then(()=> this.totalRows = this.dadosRodape?this.dadosRodape.length: 1);
    },
    methods: {
      ...mapActions({
        getDadosRodape: 'dadosRodape/getDadosRodape'
      }),
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      exibeModal: function() {
        this.$refs.modal_blocos_rodape.limpa();
        this.$refs.modal_blocos_rodape.show();
        this.modalBlocosShow = true;
      },
      editar: function(id) {
        this.$refs.modal_blocos_rodape.limpa(id);
        this.$refs.modal_blocos_rodape.show(id);
      },
      excluir: function(id) {
        abreCaixaConfirmacao(this.$bvModal, "Deseja realmente excluir esse bloco?")
          .then( resposta => {
            if(resposta === true) {
              axios.delete(`/api/dados-rodape/${id}`)
                .then( (response) => {
                  console.log(response);
                  this.getDadosRodape().then(()=> this.totalRows = this.dadosRodape?this.dadosRodape.length: 1);
                })
                .catch( err => {
                  console.log("erro exclusao bloco rodapé", err.response.data)
                });
            }
          })
          .catch(()=> {
            console.log("erro na exclusão");
          });
      },
      changeFilter: function(valor) {
        this.filter = valor;
      },
    },
    
  }
</script>

<style>


</style>
<template>
    <b-modal 
        v-model="exibe" 
        body-class="body-modal-setores" 
        header-class="header-modal-setores" 
        footer-class="footer-modal-setores" 
    >
        
        <template v-slot:default="{ cancel, ok }">
            <div class="container">
                <div class="row pb-2">
                    <h3> {{ idSetor > 0? 'Editar setor': 'Cadastro de setor'}} </h3>
                </div>

                <div class="row py-2">
                    <label for="nome-input-setores">Nome</label>
                    <input type="text" v-model="nome" name="nome" id="nome-input-setores" class="form-input-setores" >
                </div>

                <div class="row py-2">
                    <label for="url-input-setores"> Url de direcionamento </label>
                    <input type="text" v-model="url" name="url" id="url-input-setores" class="form-input-setores" >
                </div>

                <div class="row py-2">
                    <label for="ordenacao-input-setores ">Ordenação</label>
                    <input type="number" v-model="ordenacao" name="ordenacao" id="ordenacao-input-setores" class="form-input-setores" >
                </div>

                <div class="row py-2">
                    <div class="botoes-float-right-container">
                        <botao-verde class="botoes-float-right" @click="salvar(ok)" > Salvar </botao-verde>
                        <botao-branco class="botoes-float-right" @click="cancel()" > Cancelar </botao-branco>
                    </div>
                </div>
                
            </div>
        </template>

        <template v-slot:modal-footer>
            <div class="mr-5">
                
            </div>
        </template>
    </b-modal>
</template>

<script>
import BotaoVerde from '@/components/botoes/BotaoVerde.vue';
import BotaoBranco from '@/components/botoes/BotaoBranco.vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'ModalSetores',
    components: {
        BotaoVerde,
        BotaoBranco
    },
    props: {
       
    },
    data: function() {
        return {
            exibe: false,
            nome: '',
            url: '',
            ordenacao: 0,
            idSetor: {
                type: Number,
                default: 0
            }
        }
    },
    computed: {
        ...mapGetters({
            setores: 'setoresMenu/setores',
        })
    },
    methods: {
        ...mapActions({
            getSetores: 'setoresMenu/getSetores'
        }),
        show: function() {
            if(this.setores && this.idSetor > 0) {
                let setor = this.setores.filter(item => item.id == this.idSetor);
                if(setor) {
                    if(setor.length > 0) {
                        this.nome = setor[0].nome;  
                        this.url = setor[0].url;
                        this.ordenacao = setor[0].ordenacao;
                    }
                }
            }
            this.exibe = true;
        },
        hide: function() {
            this.limpa();
            this.exibe = false;
        },
        salvar: function(ok) {
            // Metodo Ok que fecha o modal
            ok();
            let registro = {
				"nome": this.nome,
				"url": this.url,
                "ordenacao": parseInt(this.ordenacao),
            };
            let requisicao = null;
            if(this.idSetor > 0) {
                registro.Id = this.idSetor;
                requisicao = axios.put("/api/setores", registro);
            } else {
                requisicao = axios.post("/api/setores", registro);
            }
			requisicao
				.then( (response) => {
					console.log(response);

                    // atualiza a lista
                    this.getSetores();
                    // Limpa form
                    this.limpa();
				})
				.catch( err => {
                    if(err.response.status == 401 || err.response.status == 403) {
                        this.$router.push('/admin/paginas').catch(()=>{});
                    }
					console.log("erro postagem rapida", err.response.data)
				});
        },
        limpa: function() {
            this.nome = '';
            this.url = '';
            this.ordenacao = 0;
            this.idSetor = 0;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.header-modal-setores {
    border: white !important;
    padding-bottom: 0 !important;
}

.body-modal-setores {
    padding-right: 50px !important;
    padding-left: 50px !important;
}

.footer-modal-setores {
    border: white !important;
}

.form-input-setores {
    width: 100%;
    border: none !important;
    border-bottom: 1px solid black !important;
    outline: none;
}

.botoes-float-right {
    float: right;
    margin-left: 20px;
}

.botoes-float-right-container {
    width: 100%;
    margin-top: 30px;
}

</style>
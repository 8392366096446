<template>
    <b-button class="close botao-fechar-pequeno" aria-label="Close" v-on:click="$emit('click')">
        <span aria-hidden="true">x</span>
        <slot></slot>
    </b-button>    
</template>

<script>
export default {
    name: 'BotaoFechar',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.botao-fechar-pequeno {
    padding: 1px 7px 7px 7px !important;
    border-radius: 60% !important;
}

    .botao-fechar-pequeno > span {
        display: inline-block;
        max-height: 100% !important;
    }

</style>
<template>
  <b-container fluid>
    <!-- User Interface controls -->
    <b-row>

      <b-col lg="3" class="mt-1 mb-0">
        <h5 class="mt-2 mb-0 text-left"><b>Postagens do Blog</b></h5>
      </b-col>

      <b-col lg="3" class="my-1">
        <input-buscar
            @change="changeFilterTitulo"
            vplaceholder="Buscar por título"
        />
      </b-col>

      <b-col lg="3" class="my-1">
        <input-buscar
            @change="changeFilterTag"
            vplaceholder="Buscar por tag"
        />
      </b-col>

      <b-col lg="3" class="my-1 pr-1">
        <botao-criar class=" float-right" @click="exibeModal()" >+ Criar nova postagem</botao-criar>
      </b-col>

    </b-row>
    <b-row>
      <!-- Main table element -->
      <b-table
        class="bg-white "
        :tbody-tr-class="classTd"
        hover
        show-empty
        small
        stacked="md"
        :items="lista_posts"
        :fields="fields"
        :filter="filter"
        :filterIncludedFields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      >

          <template v-slot:empty>
              <h4>Sem postagens cadastradas</h4>
          </template>

          <template v-slot:emptyfiltered>
              <h4>Sem resultados</h4>
          </template>
        
        <template v-slot:cell(actions)="row">

          <botao-visualizar-tabela class="mx-1 float-right" @click="visualizar(row.item)" />
          <botao-delete-tabela class="mx-1 float-right" @click="excluir(row.item.id)" />
          <botao-edit-tabela class="mx-1 float-right" @click="editar(row.item.id)" />

        </template>

        <template v-slot:row-details="row">
          <b-card>
            <ul>
              <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
            </ul>
          </b-card>
        </template>
      </b-table>

      </b-row>

    <modal-posts ref="modal_posts" v-model="modalPostsShow"/>
  </b-container>
</template>

<script>
import BotaoDeleteTabela from '@/components/botoes/BotaoDeleteTabela.vue';
import BotaoEditTabela from '@/components/botoes/BotaoEditTabela.vue';
import BotaoCriar from '@/components/botoes/BotaoCriar.vue';
import BotaoVisualizarTabela from '@/components/botoes/BotaoVisualizarTabela.vue';
import InputBuscar from '@/components/inputs/InputBuscar.vue';
import ModalPosts from '@/components/admin/modals/ModalPosts.vue';
import { mapActions, mapGetters  } from 'vuex';
import axios from 'axios';
import { abreCaixaConfirmacao } from '@/assets/js/CaixaConfirmacao'


  export default {
    name: 'TabelaPosts',
    components: {
        BotaoDeleteTabela,
        BotaoEditTabela,
        InputBuscar,
        ModalPosts,
        BotaoCriar,
        BotaoVisualizarTabela,
    },
    data() {
      return {
        classTd: 'row-sem-borda',
        modalPostsShow: false,
        fields: [
          { key: 'titulo', label: 'Título', sortable: true, sortDirection: 'asc' },
          { key: 'tags', label: 'Tag', sortable: true, sortDirection: 'asc' },
          { key: 'actions', label: '' }
        ],
        totalRows: 1,
        currentPage: 1,
        sortBy: 'titulo',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: ['titulo', 'tags'],
      }
    },
    computed: {
      ...mapGetters({
            lista_posts: 'posts/posts',
      }),
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    mounted() {
      // Set the initial number of items
      this.getPosts().then(()=> this.totalRows = this.lista_posts?this.lista_posts.length: 1);
    },
    methods: {
      ...mapActions({
        getPosts: 'posts/getPosts'
      }),
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      exibeModal: function() {
        this.$refs.modal_posts.limpa();
        this.$refs.modal_posts.show();
        this.modalPostsShow = true;
      },
      editar: function(id) {
        this.$refs.modal_posts.limpa();
        this.$refs.modal_posts.show(id);
        this.modalPostsShow = true;
      },
      excluir: function(id) {
        abreCaixaConfirmacao(this.$bvModal, "Deseja realmente excluir esse post?")
          .then( resposta => {
            if(resposta === true) {
              axios.delete(`/api/posts/${id}`)
                .then( (response) => {
                  console.log(response);
                  this.getPosts().then(()=> this.totalRows = this.lista_posts?this.lista_posts.length: 1);
                })
                .catch( err => {
                  console.log("erro exclusao post", err.response.data)
                });
            }
          })
          .catch(()=> {
            console.log("erro na exclusão");
          });
      },
      visualizar: function(pItem) {
        if(pItem) {
          if(pItem.id) {
            let routeData = this.$router.resolve({name: 'BlogPostPage', params: { id: pItem.id }});
            window.open(routeData.href, '_blank');
          }
        }
      },
      changeFilterTag: function(valor) {
        this.filterOn = ['tags']
        this.filter = valor;
      },
      changeFilterTitulo: function(valor) {
        this.filterOn = ['titulo']
        this.filter = valor;
      },
    },
    
  }
</script>

<style>


</style>